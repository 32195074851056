import { persist } from 'zustand/middleware';
import {create} from 'zustand';
import { BlockHour } from '../interfaces';
import { TeacherProfileTotalHours } from '../services';
// import { Campus, Pagination } from '../interfaces';

type mode = 'MONDAY'|'TUESDAY'|'WEDNESDAY'|'THURSDAY'|'FRIDAY'|'SATURDAY'

interface BookHoursModule {
    teacherId?: string;
    dataBlockHour: BlockHour[];
    monday: BlockHour[] | [],
    tuesday: BlockHour[] | [],
    wednesday: BlockHour[] | [],
    thursday: BlockHour[] | [],
    friday: BlockHour[] | [],
    saturday: BlockHour[] | [],
    mode:mode,
    modal:boolean,
    isActive:boolean,
    where:string
    currentPage:number
    limit:number
    totalHours:number,
    setInitialHours:(value: number, id: string)=>void;
    setTotalHours:(id: string)=>void;
    setMonday:(value: BlockHour[] | [])=>void,
    setTuesday:(value: BlockHour[] | [])=>void,
    setWednesday:(value: BlockHour[] | [])=>void,
    setThursday:(value: BlockHour[] | [])=>void,
    setFriday:(value: BlockHour[] | [])=>void,
    setSaturday:(value: BlockHour[] | [])=>void,
    setIsActive:(mode:boolean)=>void,
    setModal:(modal:boolean, day:mode)=>void,
    resetData:()=>void,
    setCurrentPage:(value:number)=>void,
    setTeacherId:(value:string)=>void,
    setWhere:(value:string)=>void,
    setDataBlockHour:(value: BlockHour[])=>void,
    // setToAdd:()=>void,
    // itemSelected?: Campus 
    // paginator:Pagination
    // setSelectedToEdit:(item:Campus)=>void;
    // setSelectedToView:(item:Campus)=>void;
    // setPaginator:(paginator:Pagination)=>void,
}

export const UseBlockHourModule = create<BookHoursModule>( (set, get) => ({
    dataBlockHour: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    mode:'MONDAY',
    modal:false,
    currentPage:1,
    where:'',
    limit:10,
    isActive:true,
    totalHours:0,
    paginator:{
        result:[],
        total:0,
        limit:0,
        current_page:1,
        total_page:0
    },
    setTotalHours:async(id)=>{
        const value = get().monday.length + 
            get().tuesday.length + 
            get().wednesday.length + 
            get().thursday.length + 
            get().friday.length + 
            get().saturday.length;
        set({totalHours: value});
        
        await TeacherProfileTotalHours({
            hours:value,
            user:id
        });
          
    },
    setInitialHours:async(value, id)=>{
        set({totalHours: value})
        await TeacherProfileTotalHours({
            hours:value,
            user:id
        });
    },
    setMonday:(value: BlockHour[] | [])=>{
        set({monday:value,mode:'MONDAY'})
    },
    setTuesday:(value: BlockHour[] | [])=>{
        set({tuesday:value,mode:'TUESDAY'})
    },
    setWednesday:(value: BlockHour[] | [])=>{
        set({wednesday:value,mode:'WEDNESDAY'})
    },
    setThursday:(value: BlockHour[] | [])=>{
        set({thursday:value,mode:'THURSDAY'})
    },
    setFriday:(value: BlockHour[] | [])=>{
        set({friday:value,mode:'FRIDAY'})
    },
    setSaturday:(value: BlockHour[] | [])=>{
        set({saturday:value,mode:'SATURDAY'})
    },
    setIsActive:(value:boolean)=>{
        set({isActive:value})
    },
    setModal:(modal, mode)=>{
        set({modal:modal,mode:mode})
    },
    setDataBlockHour:(value)=>{
        set({dataBlockHour:value})
    },
    // setToAdd:()=>{
    //    // set({mode:'ADD',modal:true,itemSelected:undefined})
    // },
    // setSelectedToEdit:(item)=>{
    //    // set({itemSelected:item,mode:'EDIT',modal:true})
    // },
    // setSelectedToView:(item)=>{
    //     //set({itemSelected:item,mode:'VIEW',modal:true})
    // },
    resetData:()=>{
      set({ 
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: []
       })
    },
    resetDataState:()=>{
        set({
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            teacherId: '',
            mode:'MONDAY'
        })
    },
    setPaginator:(pagination)=>{
        // //console.log(pagination)
        // set({paginator:{
        //     result:pagination.result,
        //     total:pagination.total,
        //     limit:pagination.limit,
        //     current_page:pagination.current_page,
        //     total_page:pagination.total_page
        // }})
    },
    setCurrentPage:(value)=>{
        set({currentPage:value})
    },
    setTeacherId:(value)=>{
        set({teacherId:value})
    },
    setWhere:(value)=>{
        set({where:value})
    },
}) 
);