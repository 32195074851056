
import { useState, useRef } from 'react'
import { UseExecutiveSearch } from '../services'
import moment from 'moment';
import { t } from 'i18next';
import { CountrySelect } from '../../../components/common/CountrySelect/CountrySelect';
export interface Root {
    result: Result
    tasks: Task[]
    trailClass: TrailClass[]
}

export interface Result {
    user: User
    student_setting: StudentSetting
}

export interface User {
    fullName: string
    phoneNumber: string
    email: string
}

export interface StudentSetting {
    placementTest: string
    welcomeDay: string
}

export interface Task {
    type: string
    createdAt: string
    status: string
}

export interface TrailClass {
    assistantStudent: boolean
    assistantTeacher: boolean
    isSuspense: boolean
    lateArrival: boolean
    createdAt: string
    updatedAt: string
    type: string
    appointment: Appointment
}

export interface Appointment {
    start: string
    status_appointment: string
    activity: Activity
    blockHour: BlockHour[]
}

export interface Activity {
    name: string
}

export interface BlockHour {
    id: string
    order: number
    start: string
    end: string
    isActive: boolean
    createdAt: string
    updatedAt: string
}



type Props = {}
const ExecutiveSearchView = ({ }: Props) => {
    let [loading, setLoading] = useState<boolean>(false)
    let [where, setWhere] = useState<string>('');
    let [type, setType] = useState<string>('PHONE_NUMBER');
    let [user, setUser] = useState<Root>();
    const [countryPhone, setCountryPhone] = useState<string>('(+57)');
    let findUserState = async () => {
        setLoading(true)
        let search = type === 'PHONE_NUMBER' ? (countryPhone + where) : where;
        let user = await UseExecutiveSearch(type, where);
        setLoading(false)
        setUser(user)
    }
    const handleWhere = (value: string) => {
        setWhere(value)
    }

    return (<div>
        <div className="input-group mb-3">
            <h3 className='champ-bold' style={{ color: '#3e94ff' }}>Estatus de estudiantes </h3>

            <div className="input-group">
                <select onChange={(e) => setType(e.target.value)} className="form-select" id="inputGroupSelect04" aria-label="Example select with button addon">
                    <option value="PHONE_NUMBER">Teléfono</option>
                    <option value="PHONE_NUMBER">Teléfono</option>
                    <option value="DOCUMENT_NUMBER">Documento de identificación</option>
                    <option value="CONTRACT">Contrato</option>
                    <option value="EMAIL">Correo</option>
                </select>

                {type === "PHONE_NUMBER" && <CountrySelect
                    initialValue={countryPhone}
                    onChangeValue={(e) => setCountryPhone(e)}
                    className='w-100px poppins-bold input-group-text border-0'
                    style={{

                        border: '1px',
                        color: '#2B22E5',
                        padding: 0,
                        margin: 0,
                        fontSize: '12px'
                    }}
                    renderOption={(item) => (
                        <option key={`${item.code}-${item.country}`} value={item.code}
                            style={{
                                background: 'black',
                                color: 'white',
                            }}>
                            {item.code} {item.country}
                        </option>
                    )}
                />}
                <input type="text" onChange={(e) => handleWhere(e.target.value)} className="form-control" aria-label="Text input with dropdown button" />

                {loading ?
                    <div className="spinner-border text-success" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    :
                    <button className="btn btn-primary" type="button"
                        onClick={() => findUserState()}
                    >
                        Buscar
                    </button>}
            </div>
        </div>
        {user ? <div className="card mb-3">
            <div className="row g-0">
                <div className="col-md-12">
                    <div className="card-body" style={
                        {
                            height: '490px', // Alto fijo
                            overflowY: 'scroll',
                        }
                    }>
                        <h5 className="card-title">Nombre: {user.result.user.fullName}</h5>
                        <h5 className="card-title">Teléfono: {user.result.user.phoneNumber}</h5>
                        <h5 className="card-title">Email: {user.result.user.email}</h5>
                        <hr></hr>
                        <div className='row'>
                            {user && user.result && user.result.student_setting && user.result.student_setting.welcomeDay &&
                                <div className='col-4'>
                                    <h6>Welcome Day {t(user.result.student_setting.welcomeDay)}</h6>
                                </div>}
                            {user && user.result && user.result.student_setting && user.result.student_setting.placementTest &&
                                <div className='col-4'>
                                    <h6>Placement Test {t(user.result.student_setting.placementTest)}</h6>
                                </div>}
                            {/* {user && user.result && user.result.firstClass && user.result.firstClass.assistantStudent && 
                                <div className='col-4'>
                                    <h6>Primera clase {t(user.result.firstClass.assistantStudent ? "asistida" : "no asistida")}</h6>
                                </div>
                                } */}
                        </div>
                        <hr></hr>
                        {user.trailClass.length > 0 ?
                            <table className="table" >
                                <thead>
                                    <tr>
                                        <th scope="col" className='champ-bold'>Actividad</th>
                                        <th scope="col" className='champ-bold'>Estado</th>
                                        <th scope="col" className='champ-bold'>Hora</th>
                                        <th scope="col" className='champ-bold'>Fecha</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {user.trailClass.map((x) => (<tr>
                                        <th className='poppins-bold  text-muted' scope="row">{t(x.appointment.activity.name)}</th>
                                        <th className='poppins-bold  text-muted' scope="row">{t(x.appointment.status_appointment)}</th>
                                        <td className='poppins-bold  text-muted'>{x.assistantTeacher ? 'Asistió' : 'No asistió'}</td>
                                        <td className='poppins-bold  text-muted'>{moment(x.appointment.start).format('DD-MM-YYYY')}</td>
                                    </tr>))}
                                </tbody>
                            </table>
                            :
                            <h3 className='poppins-light'>No tiene ninguna clase programada</h3>
                        }
                        <hr></hr>
                        {user.tasks.length > 0 ?
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className='champ-bold'>Evaluación</th>
                                        <th scope="col" className='champ-bold'>Estado</th>
                                        <th scope="col" className='champ-bold'>Fecha</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {user.tasks.map((x) => (<tr>
                                        <th className='poppins-bold  text-muted' scope="row">{t(x.type)}</th>
                                        <td className='poppins-bold  text-muted'>{t(x.status)}</td>
                                        <td className='poppins-bold  text-muted'>{moment(x.createdAt).format('DD-MM-YYYY')}</td>
                                    </tr>))}
                                </tbody>
                            </table>
                            :
                            <h3 className='poppins-light'>No tiene tareas en su lista</h3>
                        }
                    </div>
                </div>
            </div>
        </div> :
            <h3 className='poppins-light'>No hay resultados que mostrar</h3>
        }
        <br />
        <div className="alert alert-secondary poppins-light  text-black" role="alert">
            ¡¡¡Recuerda que los datos suministrados deben ser exactos para mostrar coincidencias!!!
        </div>
    </div>)
}

export { ExecutiveSearchView }
