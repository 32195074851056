import moment from 'moment'
import {UseQualifyPlacementModule} from '../../placement-test/store/main'

type Props = {
  data: any
}

const QualifyFinalPresentationForm: React.FC<Props> = ({data}: Props) => {
  const {setSelectedToView} = UseQualifyPlacementModule()
  return (
    <>
      <table className='table'>
        <thead className=''>
          <tr className={`fs-5 fw-bold bg-light poppins-bold`}>
            <th className='text-center align-middle'>Student</th>
            <th className='text-center align-middle'>Document</th>
            <th className='text-center align-middle'>Test code</th>
            <th className='text-center align-middle'>Status</th>
            <th className='text-center align-middle'>Created</th>
            <th className='text-center align-middle'>Last update</th>
            {/* <th className='text-center align-middle'>Qualified?</th> */}
          </tr>
        </thead>
        <tbody className=''>
          {data?.result?.map((x: any) => (
            <tr
              key={x.id}
              className={`mt-3 cursor-pointer `}
              onClick={() => setSelectedToView(x)}
            >
              <td className='text-center align-middle'>{x.student_name}</td>
              <td className='text-center align-middle'>{x.student_setting.user.document_id}</td>
              <td className='text-center align-middle'>{x.student_setting.code}</td>
              <td
                className={`text-center align-middle 
                  text-${
                      x?.status === 'PENDING'
                    ? 'primary'
                    : (x?.status === 'IN_REVIEW' || x?.status === 'REPROVED')
                    ? 'danger'
                    : x?.status === 'QUALIFIED'
                    ? 'warning'
                    : x?.status === 'APPROVED'
                    ? 'success'
                    : 'dark'
                  }`
                }
              >
                {x?.status === 'PENDING'
                    ? 'Asignado'
                    : x?.status === 'IN_REVIEW' 
                    ? 'Pendiente por revisar'
                    : x?.status === 'QUALIFIED'
                    ? 'Calificado'
                    : x?.status === 'APPROVED'
                    ? 'Aprobado'
                    : x?.status === 'REPROVED'
                    ? 'Reprobado'
                    : 'Cancelada'}
              </td>
              <td className='text-center align-middle'><span className='poppins-light fw-bold'>{moment(x?.createdAt).format('DD/MM/YYYY')}</span></td>
              <td className='text-center align-middle'><span className='poppins-light fw-bold'>{moment(x?.updatedAt).format('DD/MM/YYYY')}</span></td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default QualifyFinalPresentationForm
