import { create } from 'zustand';
import { User, Pagination, StudentSettingsData, FilterStudentSetting } from '../interfaces';
import { UserType } from '../../../../api/main';


interface UserModules {
    usersToActions: any[]
    itemSelected?: User
    itemSelectedStudent?: StudentSettingsData;
    filterStudent: FilterStudentSetting;
    modeStudent: 'ADD' | 'EDIT' | 'VIEW';
    modeActions: 'CHAT' | 'INACTIVE';
    type: UserType;
    mode: 'ADD' | 'EDIT' | 'VIEW'
    modal: boolean,
    modalCredential: boolean,
    actionsModal: boolean,
    before?: Date,
    after?: Date,
    isActive: boolean,
    paginator: Pagination
    where: string
    currentPage: number
    limit: number
    total: number
    setIsActive: (mode: boolean) => void;
    setModal: (mode: boolean) => void;
    setModalCredentials: (mode: boolean) => void;
    setActionsModal: (mode: boolean) => void;
    setSelectedToEdit: (item: User) => void;
    setSelectedToView: (item: User) => void;
    setSelectedToShow: (item: User) => void;
    resetData: () => void,
    setPaginator: (paginator: Pagination) => void,
    setCurrentPage: (value: number) => void,
    setLimit: (value: number) => void,
    setWhere: (value: string) => void,
    setType: (value: string) => void,
    setToAdd: () => void,
    resetUsers: () => void,
    setSelectedStudent: (value: any, mode: 'ADD' | 'EDIT' | 'VIEW') => void;
    setFilterStudent: (value: FilterStudentSetting) => void;
    addUser: (user: User) => void,
    removeUser: (id: string) => void,
    setActionsMode: (mode: 'CHAT' | 'INACTIVE') => void;
    resetValues: () => void;
    setSelectedToCredentials:(value: any)=>void;
    setBefore:(value: Date)=>void;
    setAfter:(value: Date) => void;
}

export const UseUserModule = create<UserModules>((set) => ({
    usersToActions: [],
    itemSelected: undefined,
    mode: 'ADD',
    modalCredential:false,
    modeActions: 'CHAT',
    filterStudent: FilterStudentSetting.ID,
    modeStudent: 'ADD',
    type: 'TEACHER',
    modal: false,
    actionsModal: false,
    currentPage: 1,
    where: '',
    limit: 10,
    isActive: true,
    total: 0,
    paginator: {
        result: [],
        total: 0,
        limit: 0,
        current_page: 1,
        total_page: 0
    },
    setAfter:(value)=>{
        set({after: value})
    },
    setBefore:(value)=>{
        set({before: value});
    },
    setFilterStudent: (value) => {
        if (value !== FilterStudentSetting.DATE_CREATED &&
            value !== FilterStudentSetting.DATE_FINISH &&
            value !== FilterStudentSetting.DATE_START) {
            set({before: undefined, after: undefined})
        }
        set({ filterStudent: value });
    },
    setIsActive: (value: boolean) => {
        set({ isActive: value })
    },
    setModal: (mode) => {
        set({ modal: mode })
    },
    setActionsModal: (mode) => {
        set({ actionsModal: mode })
    },
    setToAdd: () => {
        set({ mode: 'ADD', modal: true, itemSelected: undefined })
    },
    setSelectedToEdit: (item) => {
        set({ itemSelected: item, mode: 'EDIT', modal: true })
    },
    setSelectedToView: (item) => {
        set({ itemSelected: item, mode: 'VIEW', modal: true })
    },
    setModalCredentials: (item) => {
        set({ modalCredential: item})
    },
    resetData: () => {
        set({ itemSelected: undefined, mode: 'ADD', modal: false, itemSelectedStudent: undefined, modeStudent: 'ADD' })
    },
    setPaginator: (pagination) => {
        set({
            paginator: {
                result: pagination.result,
                total: pagination.total,
                limit: pagination.limit,
                current_page: pagination.current_page,
                total_page: pagination.total_page
            }
        })
    },
    setCurrentPage: (value) => {
        set({ currentPage: value })
    },
    setLimit: (value) => {
        set({ limit: value })
    },
    setWhere: (value) => {
        set({ where: value })
    },
    setType: (value: any) => {
        set({ type: value })
    },
    setSelectedStudent: async (value, mode) => {
        set({
            modeStudent: mode,
            itemSelectedStudent: value,
        });
    },
    setSelectedToCredentials: async (value) => {
        set({
            modalCredential:true,
            modal:false,
            itemSelected: value,
        });
    },
    setSelectedToShow: (value) => {
        set({ itemSelected: value })
    },
    addUser: (user: User) => {

        set((state) => {

            const userExists = state.usersToActions.some((u) => u.id === user.id);
            if (!userExists) {
                return { usersToActions: [...state.usersToActions, user] };
            }
            return state;
        })

    },
    resetUsers: () => {
        set({ usersToActions: [] })
    },
    removeUser: (id: string) =>
        set((state) => ({
            usersToActions: state.usersToActions.filter((user) => user.id !== id),
        })),
    setActionsMode: (value: 'CHAT' | 'INACTIVE') => {
        set({ modeActions: value })
    },
    resetValues: () => { 
        set({limit: 10, where: '', currentPage: 1, isActive: true})
    }
}));