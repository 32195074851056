import React, {useEffect} from 'react'
import {useQueryClient} from '@tanstack/react-query'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import GenericPaginator from '../../../../components/common/Paginator'
import moment from 'moment'
import {UseFindPaginatedTrailClass} from '../../../me-booking/services/trail-class'
import {Booking, relatedTrailClasses, TypeTrailClass} from '../../appointment/interfaces'
import AllExcel from '../../../../components/common/AllExcel'
import {CardHeaderModule} from '../../../../components/common/widgets/statistics/cardHeaderModule'
import CustomDatePicker from '../../../../components/common/DayPicker/DayPickerInputWithImage'
import {useThemeMode} from '../../../../../_metronic/partials'
import {UseTrailStrikeMe} from '../../trail-suspense/services'
import {UseBookingModule} from '../../../me-booking/store'
import '../../../me-report/components/list/index.css'
import {UseMeReportModule} from '../../../me-report/store/main'
import {TableItem} from '../../../me-report/components/list/tableItem'
import {CreateModal} from '../../country/components/modal'
import {useParams} from 'react-router-dom'
import { StudentSetting } from '../../../profile/components/my-road-step/interfaces'
import { StudentSettingsOneByUser } from '../../user/services/student/StudentSettingsService'


type Props = {}

const StudentActionReportTableList: React.FC<Props> = ({}) => {
  const {id} = useParams()
  const {mode} = useThemeMode()
  const queryClient = useQueryClient()
  const {
    suspense,
    after,
    before,
    paginator,
    modal,
    limit,
    currentPage,
    isActive,
    where,
    setCurrentPage,
    setLimit,
    setPaginator,
    setBefore,
    setAfter,
  } = UseMeReportModule()
  const {level, setStrikes, strikes} = UseBookingModule()
  const [loading, setLoading] = React.useState<boolean>(true)
  const [bookingAvailable, setBookingAvailable] = React.useState<Booking[]>([])
  const [studentSettings, setStudentSettings] = React.useState<StudentSetting>()

  const theadName = [
    'FECHA',
    'BLOQUE HORARIO',
    'ASISTENCIA',
    'ACTIVIDAD',
    'SALON',
    'PROFESOR',
    'ASISTENCIA PROFESOR',
    'ESTRELLAS',
    '',
    'OBSERVACION ESTUDIANTE',
    'OBSERVACION PROFESOR',
  ]
  // nota que le da al estudiante el profe

  const changePage = (number: number) => {
    setCurrentPage(number)
  }
  const changeLimit = (number: number) => {
    setLimit(number)
  }

  useEffect(() => {
    queryClient.invalidateQueries({queryKey: ['me-report']})
  }, [isActive, where, currentPage, limit])
  useEffect(()=>{
    setBefore(moment().startOf('year').toDate())
    setAfter(moment().endOf('year').toDate())
  },[])

  const findBookingAvailable = async () => {
    if (studentSettings) {
      setLoading(true)
      const starterList = await UseFindPaginatedTrailClass({
        id: studentSettings?.id,
        before: moment(before).format('YYYY-MM-DD').toString(),
        after: moment(after).format('YYYY-MM-DD').toString(),
        limit: limit,
        currentPage: currentPage,
      })
      console.log(starterList?.result);
      
      setLoading(false)
      setPaginator(starterList)
      setBookingAvailable(starterList?.result)
    }
  }
  
  const findTrailStrikes = async () => {
    try {
      let response = await UseTrailStrikeMe()
      setStrikes(response)
    } catch (e) {
      console.error(e)
    }
  }
  
  React.useEffect(() => {
    findTrailStrikes()
  }, [level])
  
  React.useEffect(() => {
    if (after && before && studentSettings) {
      findBookingAvailable()
    }
  }, [after, before, suspense, currentPage, limit, studentSettings])
  
  const handleBeforeSelectDate = (date: Date) => {
    setBefore(date)
  }
  
  const handleAfterSelectDate = (date: Date) => {
    setAfter(date)
  }
  
  const responseStudentSettings = async () => {
    const response = await StudentSettingsOneByUser(id??"")
    setStudentSettings(response)
  }
  
  useEffect(()=>{
    responseStudentSettings()
  },[id])

  const getAllLessonsText = (firstLessonText : string, relatedTrailClasses: relatedTrailClasses[])=>{
    const myTrailClasses = relatedTrailClasses?.map((x)=>({
      text: x.lesson.name ?? "Lección no encontrada"
    })) ?? [{text: "Lección no encontrada"}]
    
    const textToShow = myTrailClasses.sort((a,b)=>a.text .localeCompare(b.text)).map(lesson => lesson.text).join(', ');
    return firstLessonText + ", "+textToShow
  }
  
  return (
    <>
      <CardHeaderModule
        title={'Historial de Reservas'}
        leftIcon={`/media/svg/michigan-icons/header/${
          mode === 'light' ? 'white/back_white' : 'dark-modules/back_orange'
        }.svg`}
        svgIcon={`/media/svg/michigan-icons/header/${
          mode === 'light' ? 'white/history_white' : 'dark-modules/history_orange'
        }.svg`}
        background={'#ff7500'}
        colorTitle={'#FFFFFF'}
        routeBack='/user/student-setting'
      />
      
      <div className='row mt-3'>
        <div className='col-md-8'>
          <div
            className='card mb-5 w-100'
            style={{
              backgroundColor: mode === 'light' ? '#E6E6FA' : '',
              borderRadius: 30,
              border: mode === 'dark' ? '1px solid #E6E6FA' : '',
            }}
          >
            <div className='row'>
              <div className='col-md-6 d-flex'>
                <span
                  className='poppins-bold fs-bold ps-10 d-flex align-items-center'
                  style={{color: mode === 'light' ? '#2d30e1' : '#E6E6FA', fontSize: 15}}
                >
                  {`Desde: ${moment(before).format('DD-MM-YYYY')}`}
                </span>
                <div className='ps-5'>
                  <CustomDatePicker
                    calendarPosition='right'
                    valueCalendarPosition={-100}
                    symbolWidth='25px'
                    onSelectDate={handleBeforeSelectDate}
                    calendarIconSrc='/media/svg/michigan-icons/elements/calendar_blue.svg'
                    buttonStyle={{marginLeft: '5px'}}
                  />
                </div>
              </div>
              <div className='col-md-6 d-flex'>
                <span
                  className='poppins-bold ps-10 d-flex align-items-center'
                  style={{color: mode === 'light' ? '#2d30e1' : '#E6E6FA', fontSize: 15}}
                >
                  {`Hasta: ${moment(after).format('DD-MM-YYYY')}`}
                </span>
                <div className='ps-5'>
                  <CustomDatePicker
                    symbolWidth='25px'
                    onSelectDate={handleAfterSelectDate}
                    calendarIconSrc='/media/svg/michigan-icons/elements/calendar_blue.svg'
                    buttonStyle={{marginLeft: '5px'}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='d-flex flex-column flex-lg-row'>
            <div className='flex-grow-1 mb-5'>
              <AllExcel
                dataExcel={bookingAvailable}
                filename={`MeResume`}
                sheetName='Resume'
                theadName={theadName}
                type='MeReport'
                backgroundColor={mode === 'light' ? '#ff7500' : ''}
                titleColor={mode === 'light' ? '#FFFFFF' : '#ff7500'}
              />
            </div>
            <div style={{flex: '0 0 2%'}}></div>
            {/* <div className='flex-grow-1 mb-5'>
              <a
                href='/strikes'
                className={`btn ${mode === 'light' && 'btn-danger'} w-100`}
                style={{borderRadius: 30, border: mode === 'dark' ? '1px solid #ff0e0e' : ''}}
              >
                <span
                  className='d-flex justify-content-center align-items-center champ-light'
                  style={{color: mode === 'light' ? 'white' : '#ff0e0e'}}
                >
                  Strikes
                  <span className='champ-light ms-5'>{strikes?.total ?? 0}</span>
                </span>
              </a>
            </div> */}
          </div>
        </div>
      </div>
      
      <div
        className={`card content-report`}
        style={{border: `1px solid ${mode === 'light' ? '#2d30e1' : '#FFFFFF'}`}}
      >
        
        <div className='card-body pt-2'>
        {studentSettings && (
            <div className='row d-flex align-middle' style={{color: mode === 'light' ? '#2d30e1' : '#FFFFFF'}}>
              <div className='col mt-2 d-flex'>
                <div className=' pt-5'>
                  <span className='poppins-bold fs-3'>Estudiante:</span>
                </div>
                <div className='pt-5 ps-3'>
                  <span className='poppins-light fw-bold fs-3'>
                    {studentSettings.user.fullName}
                  </span>
                </div>
              </div>
              <div className='col mt-2 d-flex'>
                <div className='pt-5'>
                  <span className='poppins-bold fs-3'>Email:</span>
                </div>
                <div className='pt-5 ps-3'>
                  <span className='poppins-light fw-bold fs-3'>
                    {studentSettings.user.email}
                  </span>
                </div>
              </div>
              <div className='col mt-2 d-flex'>
                <div className='pt-5'>
                  <span className='poppins-bold fs-3'>Telefono:</span>
                </div>
                <div className='pt-5 ps-3'>
                  <span className='poppins-light fw-bold fs-3'>
                    {studentSettings.user.phoneNumber}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div
            className='card mt-8 content-student'
            style={{border: mode === 'light' ? '2px solid #2d30e1' : '2px solid #FFFFFF'}}
          ></div>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr
                  className='fw-bold poppins-bold fs-5'
                  style={{
                    color: mode === 'light' ? '#2d30e1' : 'white',
                    borderBottom: `1px solid ${mode === 'light' ? '#2d30e1' : '#FFFFFF'}`,
                  }}
                >
                  <th className='min-w-150px ps-0 pb-5'> Fecha </th>
                  <th className='min-w-130px ps-0 pb-5'> Inicio </th>
                  <th className='min-w-130px ps-0 pb-5'> Final </th>
                  <th className='min-w-150px ps-0 pb-5'> Tipo </th>
                  <th className='min-w-150px ps-0 pb-5'> Sesion </th>
                  <th className='min-w-150px ps-0 pb-5'> Salon </th>
                  <th className='min-w-150px ps-0 pb-5'> Profesor </th>
                  <th className='min-w-150px ps-0 pb-5'> Estado </th>
                </tr>
              </thead>
              <tbody className='mt-2'>
                {loading && (
              <tr>
              <td colSpan={9} className='text-center'>
                <div
                  className='d-flex justify-content-center align-items-center'
                >  
                  <img
                    className='rounded mx-auto'
                    width={'30%'}
                    src={toAbsoluteUrl('/media/svg/icons/loading.svg')}
                    alt='Loading'
                  />
                </div>
              </td>
            </tr>
                )}
                {!loading &&
                  bookingAvailable &&
                  bookingAvailable?.map((x) => (
                    <>
                      {x.type !== TypeTrailClass.ADJUSTMENT &&
                        <TableItem item={x} lessonsText={x.relatedTrailClasses.length> 0 ? getAllLessonsText(x.lessonName, x.relatedTrailClasses) : x.lessonName}/>
                      }   
                    </>
                  ))}
              </tbody>
            </table>
            <GenericPaginator
              limit={limit}
              currentPage={paginator.current_page}
              items={paginator.result.length}
              total={paginator.total}
              totalPage={paginator.total_page}
              changePage={changePage}
              changeLimit={changeLimit}
            />
          </div>
        </div>
      </div>
      <CreateModal show={modal} handleClose={() => alert()} />
    </>
  )
}

export {StudentActionReportTableList}
