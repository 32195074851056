import React, { FC } from 'react'
import { KTIcon } from '../../../../_metronic/helpers'



const ReportDashboardList: FC = () => {
  // const [showAll, setShowAll] = React.useState(false); 

  return (
    <div
      className='card '
    >
      {/* <div
      className='d-flex flex-column flex-center bgi-no-repeat rounded-top px-9 py-10'
      style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
    >
      <h3 className='text-white fw-bold mb-3'>Quick Links</h3>

      <span className='badge bg-primary py-2 px-3'>25 pending tasks</span>
    </div> */}

      <div className='row g-0'>
        <div className='col-3'>
          <a
            href='/report/student-filter'
            className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
          >
            <i className="bi bi-person-down" style={{ fontSize: 68, color: 'blue' }}></i>
            <span className='fs-5 fw-bold text-gray-800 mb-0 champ-light'>Estudiantes</span>
            <span className='fs-7 text-gray-400 poppins-light'>Reporte por nivel</span>
          </a>
        </div>
        
        <div className='col-3'>
          <a
            href='/report/appointment-filter'
            className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
          >
            <i className="bi bi-journal-bookmark" style={{ fontSize: 68, color: 'blue' }}></i>
            {/* <KTIcon iconName='user' className='fs-3x text-primary mb-2' /> */}
            <span className='fs-5 fw-bold text-gray-800 mb-0 champ-light'>Clases</span>
            <span className='fs-7 text-gray-400 poppins-light'>Reportes por clases</span>
          </a>
        </div>
        <div className='col-3'>
          <a
            href='/report/resume-by-workshop'
            className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
          >
            <i className="fa-solid fa-lines-leaning" style={{ fontSize: 68, color: 'blue' }}></i>
            <span className='fs-5 fw-bold text-gray-800 mb-0 champ-light'>Tipo de clases</span>
            <span className='fs-7 text-gray-400 poppins-light'>Workshop o lecciones</span>
          </a>
        </div>
        <div className='col-3'>
          <a
            href='/report/survey-section-filter'
            className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
          >
            <i className="fa-solid fa-square-poll-vertical" style={{ fontSize: 68, color: 'blue' }}></i>
            <span className='fs-5 fw-bold text-gray-800 mb-0 champ-light'>Encuesta</span>
            <span className='fs-7 text-gray-400 poppins-light'>Reporte por encuestas</span>
          </a>
        </div>
        
        <div className='col-3'>
          <a
            href='/report/trail-class-filter'
            className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
          >
            <i className="fa-regular fa-calendar-check" style={{ fontSize: 68, color: 'blue' }}></i>
            <span className='fs-5 fw-bold text-gray-800 mb-0 champ-light'>Reservas</span>
            <span className='fs-7 text-gray-400 poppins-light'>Reporte por reservas</span>
          </a>
        </div>
        
        <div className='col-3'>
          <a
            href='/report/costs-per-class-filter'
            className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
          >
            <i className="fa-solid fa-money-bill-wave" style={{ fontSize: 68, color: 'blue' }}></i>
            <span className='fs-5 fw-bold text-gray-800 mb-0 champ-light'>Costos</span>
            <span className='fs-7 text-gray-400 poppins-light'>Costos por clase</span>
          </a>
        </div>
        {/* <div className='col-3'>
          <a
            href='/user'
            className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
          >
            <KTIcon iconName='user' className='fs-3x text-primary mb-2' />
            <span className='fs-5 fw-bold text-gray-800 mb-0'>Users</span>
            <span className='fs-7 text-gray-400'>Users</span>
          </a>
        </div>
        <div className='col-3'>
          <a
            href='/user'
            className='d-flex flex-column flex-center h-100 p-6 bg-hover-light border-end border-bottom'
          >
            <KTIcon iconName='user' className='fs-3x text-primary mb-2' />
            <span className='fs-5 fw-bold text-gray-800 mb-0'>Users</span>
            <span className='fs-7 text-gray-400'>Users</span>
          </a>
        </div> */}



        {/* </>} */}
      </div>
      {/* <div className='py-2 text-center border-top'>
      <a href='#' className='btn btn-color-gray-600 btn-active-color-primary' onClick={() => setShowAll(!showAll)}>
        {showAll ? 'Show Less' : 'View All'}{' '}
        <KTIcon iconName={showAll ? 'arrow-up' : 'arrow-down'} className='fs-5' />
      </a>
    </div> */}
    </div>
  )
}

export { ReportDashboardList }
