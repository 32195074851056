import React from 'react'
import { Booking } from '../../../admin/appointment/interfaces'
import moment from 'moment'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import { UpdateStarsValueStudent } from '../../../abroad/trail/services'
import { useThemeMode } from '../../../../../_metronic/partials'
import './index.css'
import { ModalSurveySectionResponse } from '../../../admin/survey-section/modules/survey-section-response/components/modal'
import { UseSurveySectionResponseModule } from '../../../admin/survey-section/modules/survey-section-response/store/main'
import { GetOneSurveySectionResponse } from '../../../admin/survey-section/modules/survey-section-response/services'
import { UseMyRoadmapModule } from '../../../profile/components/my-road-step/store'
import UseTranslateTimeZone from '../../../../hooks/useTranslateTimeZone'

type Props = {
  item: Booking
  lessonsText: string
}

const TableItem: React.FC<Props> = ({ item, lessonsText}) => {
  const { mode } = useThemeMode()
  const { modal, setToAdd, setSelectedToView } = UseSurveySectionResponseModule();
  const { settings } = UseMyRoadmapModule();
  const [showRating, setShowRating] = React.useState(false);
  const timeStart = UseTranslateTimeZone(item.time.start);
  const timeEnd = UseTranslateTimeZone(item.time.end);

  React.useEffect(() => {
    const endDate = moment(timeEnd.format('YYYY-MM-DD HH:mm').split(' ')[0]);
    const currentDateTime = moment();

    if (currentDateTime.diff(endDate, 'days') > 1) {
      setShowRating(false);
    } else if (currentDateTime.isSameOrAfter(endDate)) {
      setShowRating(true);
    } else {
      const interval = setInterval(() => {
        const now = moment();
        if (now.isSameOrAfter(endDate)) {
          setShowRating(true);
          clearInterval(interval);
        }
      }, 60000);

      return () => clearInterval(interval);
    }
  }, [item.time.end]);

  return (
    <>
      <tr key={item.id} className={'fs-5 ' + `${item.isSuspense && 'text-muted'}`} style={{ color: mode === 'light' ? '#2d30e1' : '#FFFFFF' }}>
        <td className='p-0'>
          <span className='d-block poppins-light'>
            {timeStart.format('YYYY-MM-DD')}
          </span>
        </td>
        <td className='p-0'>
          <span className='d-block poppins-bold'>{timeStart.format('HH:mm')}</span>
        </td>
        <td className='p-0'>
          <span className='d-block poppins-light'>{timeEnd.format('HH:mm')}</span>
        </td>
        <td className='p-0'>
          <span className='d-block poppins-light'>{item.title}</span>
        </td>
        <td className='p-0'>
          <span className='d-block poppins-light'>{lessonsText}</span>
        </td>
        <td className='p-0'>
          <span className='d-block poppins-light'>{item.room}</span>
        </td>
        <td className='p-0'>
          <span className='d-block poppins-light'>{item.teacher}</span>
        </td>
        <td className='p-0'>
          <span className='fw-semibold d-block poppins-light'>
            {item.isSuspended
              ? 'Sancionado'
              : item.isSuspense
                ? 'Cancelada'
                : timeStart.isAfter(moment())
                  ? 'Activa'
                  : (item.assistantTeacher || item.assistantStudent)
                    ? 'Asistida'
                    : 'No asistida'}
          </span>
        </td>
        <td className='p-0'>
          <div className=''>
            <div className='row'>
              <div className='d-flex'>
                {(settings) &&
                  <button
                    onClick={async () => {
                      if (timeEnd.isAfter(moment())) {
                        return showSimpleAlert({ message: 'Debes esperar que acabe la clase para poder responder la encuesta', icon: 'info' });
                      } else if (item.isSuspended || item.isSuspense || !item.assistantTeacher) {
                        return showSimpleAlert({message: 'No puedes responder a la encuestra porque no asististe a la clase', icon: 'info'});
                      }

                      const response = await GetOneSurveySectionResponse(
                        settings?.id ?? '', item.appointment
                      );
                      console.log('response',response )
                      if (showRating && (!response || response === 'empty')) {
                        setToAdd(item.id, item.appointment);
                        return;
                      };

                      if (!response || response === 'empty') return showSimpleAlert({message: 'No tienes respuestas de esta encuesta.', icon: 'info'});

                      setSelectedToView(response);
                    }}
                    // disabled={moment(item.time.start).isAfter(moment()) || item.isSuspended || item.isSuspense}
                    className='btn btn-icon btn-active-light btn-sm ms-3'
                  >
                    {(showRating) ?
                      <i style={{ color: 'green', fontSize: 17 }} className="fa-solid fa-star"></i>
                    : timeEnd.isBefore(moment()) && 
                    <i style={{ color: 'grey', fontSize: 17 }} className="fa-solid fa-eye"></i>
                    }
                  </button>
                }
              </div>
            </div>
          </div>
        </td>
        {/* <td className='p-0'>
          <span className='fw-semibold d-block poppins-light'>{item.updateUser}</span>
        </td> */}
      </tr>
      <ModalSurveySectionResponse show={modal} handleClose={() => alert()} />
    </>
  )
}

export { TableItem }
