import { LessonAdjustment, DataAllLessonAdjustmentResponse, DataPaginateLessonAdjustmentResponse, LessonAdjustmentType } from '../interfaces/index'
import useAuth from '../../../../api/main'
import { catchServerError } from '../../../../hooks/useCatchError'
import { UseLessonAdjustmentModule } from '../store/main'
import { api } from '../../../auth/services/auth.services'
import { showSimpleAlert } from '../../../../commonHooks/alert'

const { token, logout, authChecked } = useAuth.getState()
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}
export const useLessonAdjustmentAll = async (): Promise<any> => {
  try {
    const response = await api.get(`/lesson-adjustment/simple?type_category=all`,
    {
      headers: {
        Authorization: 'Bearer YOUR_ACCESS_TOKEN_HERE',
      },
    })
    return response.data
  } catch (e: any) {
    catchServerError(e.response)
  }
}
export const useLessonAdjustmentAllPaginate = async (): Promise<any> => {
  try {
    // await authChecked()
    const { setPaginator, limit, currentPage, where, isActive } = await UseLessonAdjustmentModule.getState()

    const response: any = await api.get(
      `/lesson-adjustment/paginated?isActive=${isActive ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    catchServerError(e.response);
  }
}
export const LessonAdjustmentAdd = async (data: LessonAdjustment): Promise<any> => {
  try {
    const response: any = await api.post(
      '/lesson-adjustment',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
 
    if(response.data && response.data.response && response.data.response.statusCode ===400){
      showSimpleAlert({ message: response.data.response.message, icon: 'warning' })
    }
    else{
      showSimpleAlert({ message: 'Ok!', icon: 'success' })
    }
  
    return response.data
  } catch (e:any) {
    catchServerError(e.response);
  }
}
export const LessonAdjustmentUpdate = async (data: LessonAdjustment): Promise<any> => {
  try {
    const { itemSelected } = UseLessonAdjustmentModule.getState()
    const response: any = await api.patch(
      `/lesson-adjustment/${itemSelected?.id}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    catchServerError(e.response);
  }
}
export const LessonAdjustmentDelete = async (data: string): Promise<any> => {
  try {
    const response: any = await api.delete(`/lesson-adjustment/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    catchServerError(e.response);
  }
}

export const LessonAdjustmentUpdateByStudent = async (id: string, status: LessonAdjustmentType): Promise<any> => {
  try {
    const response: any = await api.patch(
      `/lesson-adjustment/${id}`,
      {
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    catchServerError(e.response);
  }
}