/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import CountdownTimer from '../count-down-timer'
import moment from 'moment'
import { Form, InputGroup, FormControl } from 'react-bootstrap';
import { ButtonStartRating } from '../button-start-rating';
import { useThemeMode } from '../../../../../_metronic/partials';
import './style.css'
import { favIconAppSelector } from '../../../../hooks/useAppSelectorAssetsMode';
import { UseMyRoadmapModule } from '../../../profile/components/my-road-step/store';

type Props = {
  className: string
  teacherName: string;
  targetDate: {
    start: string;
    end: string;
  };
  showButtonThreshold: number;
  starsRating: number;
  teacherEmail: string;
  teacherPhoto: string;
  horizontalBanner: string;
}

const CardLobbyClass: React.FC<Props> = ({ teacherEmail, className, teacherName, targetDate, showButtonThreshold, starsRating, teacherPhoto, horizontalBanner }) => {

  const { mode } = useThemeMode()
  const [showRating, setShowRating] = React.useState(false);
  const { settings } = UseMyRoadmapModule();

  React.useEffect(() => {
    const interval = setInterval(() => {
      const currentDateTime = moment();

      if (currentDateTime.isSameOrAfter(targetDate.end)) {
        setShowRating(true);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate.end]);

  return (
    <div className={`${className}`} style={{ padding: 0 }} >
      <div className='card-body'>
        <div className='d-flex align-items-center top-heder-teacher-margin'>
          <div className='d-flex align-items-center flex-grow-1'>
            <div className='symbol symbol-45px me-5'>
              <img className='rounded-circle img-fluid' src={toAbsoluteUrl(teacherPhoto !== "" ? teacherPhoto : favIconAppSelector())} alt='' style={{ color: '#2d30e1' }} />
            </div>
            <div className='d-flex flex-column'>
              <h4 className=' mb-0 champ-bold' style={mode === 'dark' ? { color: 'white' } : { color: '#2d30e1' }}>
                {teacherName}
              </h4>
              <span className='poppins-light' style={mode != 'dark' ? { color: '#2d30e1' } : { color: 'white' }}>Teacher</span>
              {/* <span className='poppins-light' style={mode!='dark'?{color:'#2d30e1'}:{color:'white'}}>{teacherEmail}</span> */}
            </div>
          </div>
        </div>
        <div className='d-flex align-items-end pb-0'>
          <img
            src={
              settings?.level === 'A1' ? `/media/courses-level/ABROAD_h-1.jpg` :
                settings?.level === 'A2' ? `/media/courses-level/ABROAD_h-2.jpg` :
                  settings?.level === 'B1' ? `/media/courses-level/ABROAD_h-3.jpg` :
                    settings?.level === 'B2' ? `/media/courses-level/ABROAD_h-4.jpg` :
                      settings?.level === 'PRE_A1_1' ? `/media/courses-level/EXPLORERS_h.jpg` :
                        settings?.level === 'PRISMATIC' ? `/media/courses-level/PRISMATIC_h.jpg` :
                          settings?.level === 'C1' ? `/media/courses-level/PRUEBAS_h.jpg` : '/media/svg/illustrations/class_splash.svg'
            }
            alt='Horizontal banner'
            className='bgi-no-repeat bgi-size-cover rounded min-h-250px mb-5 image-class-lobby'
          />
        </div>
      </div>
    </div>
  )
}

export { CardLobbyClass }
