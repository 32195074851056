import React, { useEffect, useState } from 'react';
import './index.css';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import HeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/HeaderCard';
import { MultipleSelectionQuestionIE, MultipleSelectionQuestionAnswerIE } from '../interfaces';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import {
    AddMultipleSelectionResponse
} from '../services';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { ButtonTryAgain } from '../../common/button-try-again/buttonTryAgain';
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise';
import { UseMultipleSelectionModule } from '../store';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
import { UseBookContainerModule } from '../../../../book/store/main';


type Props = {
    userMode?: ModeTypeExercise
    question: MultipleSelectionQuestionIE[]
    responses?: MultipleSelectionQuestionAnswerIE[];
};
const divStyle = {
    backgroundColor: '#3f8bff',
    width: '525px',
    height: 'auto',
    borderRadius: '30px',
    borderColor: 'rgb(255, 255, 255)',
    borderStyle: 'solid',
    padding: '27px 10px 40px 20px'
};

const MultipleSelectionExItemTwoOptions: React.FC<Props> = ({
    userMode,
    question,
    responses,
}: Props) => {

    const {
        lessonData,
        stepBookItem,
        currentTaskId,
        setStepBookItem,
        addCompletedExerciseId,
        onChangeTaskByAnswer,
        setIsLoadingSubmit
    } = UseBookStudentModule();

    const {modeViewAnswer} = UseModeCourseModule();

    const {exerciseId, instructions} = UseMultipleSelectionModule();
    const [allAnswered, setAllAnswered] = useState<boolean>(false);
    const [answers, setAnswers] = useState<MultipleSelectionQuestionAnswerIE[]>([]);
    const [score, setScore] = useState<number>(0);
    const {showButtons} = UseBookContainerModule()
    const tryAgain = () => {
        setAnswers([])
        setAllAnswered(false)
        setScore(0)
        setAnswers(question.map(q => ({
            title: q.title,
            text: '',
            order: q.order,
            isTrue: false
        })));
    }
    useEffect(() => {
        if (responses && responses.length > 0) {
            setAnswers(responses);
        } else {
            setAnswers(question.map(q => ({
                title: q.title,
                text: modeViewAnswer ?
                    (q.options.find(op => op.isTrue)?.title??'') 
                    : '',
                order: q.order,
                isTrue: false
            })));
        }
    }, [question, responses, modeViewAnswer]);

    useEffect(() => {
        // Verifica si todas las preguntas han sido respondidas
        const allAnsweredCheck = answers.every(answer => answer.text !== '');
        setAllAnswered(allAnsweredCheck);
        if (allAnsweredCheck) {
            calculateScore();
        }
    }, [answers]);

    const handleInputChange = (title: string, value: string, isTrue: boolean) => {
        if (!allAnswered) {
            const updatedAnswers = answers.map((answer) =>
                answer.title === title ? { ...answer, text: value, isTrue } : answer
            );
            setAnswers(updatedAnswers);
        }
    };

    const calculateScore = () => {
        const correctAnswers = answers.filter(answer => answer.isTrue).length;
        const percentage = (correctAnswers / question.length) * 100;
        setScore(percentage);
        // Aquí puedes pasar el porcentaje a otro estado o función si lo necesitas
    };

    const handleSubmit = async () => {
        if (userMode === 'STUDENT' && currentTaskId && exerciseId) {
            try {
                setIsLoadingSubmit(true)
                const response = await AddMultipleSelectionResponse({
                    isActive: true,
                    answers: answers,
                    task: currentTaskId,
                    exercises: exerciseId ?? '',
                    percentage: score,
                });
                onChangeTaskByAnswer(response, 'multiple_selection');
            } catch (e) {
                showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' });
            } finally {
                setIsLoadingSubmit(false)
            }
        }
        if (lessonData && lessonData.evaluation &&
            lessonData.evaluation.evaluation_item) {
            addCompletedExerciseId(exerciseId ?? "")
            if (lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
                setStepBookItem(exerciseId??'')
            }
            else {
                setStepBookItem('FINISH_LESSON')
            }
        }
    };

    const resetExercise = () => {
        setAllAnswered(false);
        setAnswers([]);
        setScore(0);
    }

    return (
        <>


            <div className="container-fluid " style={{ display: 'flex' }}>
                <div style={divStyle}>
                    <HeaderCard />
                    <div className="scroll-y overflow-y-auto mb-2" style={{maxHeight:'70px', maxWidth:'435px'}}>
                        <span className="text-white p-2 fs-8 mb-0 poppins-light">
                            {instructions}
                        </span>
                    </div>
                    <div className="container">
                        <div className="row py-2">
                            {question.map((q, questionIndex) => (
                                <div className="container text-white">
                                    <div className='col-12 '>
                                        <div className='row p-2 '>
                                            <div className="col-6 scroll-y overflow-y-auto mb-2" style={{maxHeight:'60px', maxWidth:'435px'}}>
                                                <li className="fs-8 poppins-light mb-0 "><strong>{q.title}</strong></li>
                                            </div>
                                            {q.options.map((option, optionIndex) => (
                                                <div className="col-3 scroll-y overflow-y-auto mb-2" style={{ maxHeight:'60px', maxWidth:'435px' }}>
                                                    <div
                                                        onClick={() => handleInputChange(q.title, option.title, option.isTrue)}
                                                        className={`fs-8 poppins-light mb-0  cursor-hover cursor-pointer ${answers.find(a => a.text === option.title && a.order === questionIndex + 1) ? 'select-simple-book-fourteen poppins-bold' : 'unselect-simple-book-fourteen'}`}
                                                    >
                                                        <span className='span-select text-left'>
                                                            {allAnswered && option.isTrue ? <i className="bi bi-check  text-success"  ></i> : null}
                                                            {allAnswered && !option.isTrue && answers[questionIndex]?.isTrue === option.isTrue ? <i className="bi bi-x text-danger  "  ></i> : null}
                                                            {option.title}
                                                        </span>
                                                    </div>
                                                </div>))}
                                            <div className="col-1">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                {allAnswered && showButtons && (
                    <div className='d-flex justify-content-between'>
                                <div style={{ top: '12px' }} /*onClick={handleCheck}*/>
                                    <ButtonTryAgain onAction={tryAgain} />
                                </div>
                                <div>
                                    <ButtonSaveExercise
                                        isChecked={allAnswered}
                                        onNext={() => { }}
                                        onSubmit={handleSubmit}
                                        exerciseId={exerciseId}
                                    />
                                </div>
                    </div>
                )}
                </div>
            </div>
        </>
    );
};

export { MultipleSelectionExItemTwoOptions };