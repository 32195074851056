import { useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import useAuth from '../../../api/main';
import { showSimpleAlert } from '../../../commonHooks/alert';
import { useTranslation } from 'react-i18next'; 
import { actionsFromWs } from './actions-from.ws';

const API_URL =  process.env.REACT_APP_BOOTSTRAP_DOCS_LINK ?? 'http://localhost:3001'

export enum TypeSocketEvent {
  APPOINTMENT = 'APPOINTMENT' 
}

const UseSocket = (onEvent) => {
 
  const {t} = useTranslation();
  let {token,setNotification} =useAuth()
  useEffect(() => {
    if(!token)return ;
    const socket = io(API_URL, 
    {
      query: {
        token: token
      }
    });
    socket.on('connect', () => {
      console.log('Conectado a Socket.IO');
    });

    socket.on('disconnect', () => {
      console.log('Desconectado de Socket.IO');
    });

    socket.on('message', (e) => {
      showSimpleAlert({ message: t(e.type), icon: 'success' })
      actionsFromWs(e)
      setNotification(e)
    });

    const handleAppointment = (e) => {
      if (onEvent) onEvent({
        type:TypeSocketEvent.APPOINTMENT,
        body:e
      }); // Llama al callback con el mensaje recibido
    };
    socket.on('refresh_appointment', (e) => {
      handleAppointment(e)
    });
 
    return () => {
      socket.disconnect();
    };
  }, [token]);
};

export default UseSocket;