import React, { useCallback, useEffect, useState } from 'react';
import './index.css';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import HeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/HeaderCard';
import { WritingCheckListResponse, WritingCheckQuestions, WritingCheckQuestionsAnswer } from '../interfaces';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import { AddWritingCheckListResponse } from '../services';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { ButtonTryAgain } from '../../common/button-try-again/buttonTryAgain';
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise';
import { UseWritingCheckListModule } from '../store';
import { UseBookContainerModule } from '../../../../book/store/main';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import useAuth from '../../../../../api/main';

type Props = {
    userMode?: ModeTypeExercise
    instruction: string
    question: WritingCheckQuestions[]
    responses?: WritingCheckQuestionsAnswer[];
    mode: 'WRITING_CHECK_LIST' | 'TIME_TO_SPEAK' | 'DID_YOU_UNDERSTAND';
};

const divStyle = {
    backgroundColor: '#3f8bff',
    maxWidth: '600px',
    minWidth: '600px',
    minHeight: '400px',
    borderRadius: '30px',
    borderColor: 'rgb(255, 255, 255)', borderStyle: 'solid',
    padding: '27px 20px 20px 20px'
};

const WritingCheckListItem: React.FC<Props> = ({ userMode, question, instruction, responses, mode, }: Props) => {
    const {
        task,
        lessonData,
        stepBookItem,
        currentTaskId,
        setStepBookItem,
        addCompletedExerciseId,
        onChangeTaskByAnswer,
        setIsLoadingSubmit
    } = UseBookStudentModule();

    const { exerciseId } = UseWritingCheckListModule();
    const {typeUser} = useAuth();
    const [allAnswered, setAllAnswered] = useState<boolean>(false);
    const [selected, setSelected] = useState<number | null>(null);
    const [answers, setAnswers] = useState<WritingCheckQuestionsAnswer[]>([]);
    const [hasFeedback, setHasFeedback] = useState<boolean>(false)
    const [showFeedback, setShowFeedback] = useState<boolean>(false)
    const [selectedExercise, setSelectedExercise] = useState<WritingCheckListResponse>()

    const tryAgain = () => {
        setAnswers([])
        setAllAnswered(false)
        setAnswers(question.map((x) => ({
            title: x.title,
            text: '',
            order: x.order
        })));
    }
    
    const handleChangeAnswers = useCallback(()=>{
        if (responses && responses.length > 0) {
            setAnswers(responses);
            setSelected(responses.length > 0 ? 0 : null);
        } else {
            setAnswers(question.map((x) => ({
                title: x.title,
                text: '',
                order: x.order
            })));
            setSelected(question.length > 0 ? 0 : null);
        }
    },[question])
    
    useEffect(() => {
        handleChangeAnswers()
    }, [responses]);
    
    useEffect(() => {
        const allAnsweredCheck = answers.every((x) => x.text !== '');
        setAllAnswered(allAnsweredCheck);
    }, [answers]);
    
    useEffect(()=>{
        if (typeUser === 'STUDENT' && task && task.writing_check.length > 0) {
            const currentExercise = task.writing_check.find(x=>x.exercises.id === exerciseId)
            if(currentExercise){
                setHasFeedback(currentExercise.teacherObservation)
                setSelectedExercise(currentExercise)
            }
        }
    },[task, exerciseId])
    
    const handleChangeText = (text: string) => {
        if (selected !== null) {
            let newAnswers = [...answers];
            newAnswers[selected] = { ...newAnswers[selected], text };
            setAnswers(newAnswers);
        }
    };
    const selectQuestion = (index: number) => {
        setSelected(index);
    };
    const finderText = (index: number): boolean => {
        return answers.some((x) => x.order == index && (x.text && x.text?.length > 0))
    }
    const handleSubmit = async () => {
        if (userMode === 'STUDENT' && currentTaskId && exerciseId) {
            try {
                setIsLoadingSubmit(true)
                const response = await AddWritingCheckListResponse({
                    description: "",
                    isActive: true,
                    isReviewed: false,
                    isPending: true,
                    exercises: exerciseId ?? '',
                    task: currentTaskId,
                    answer: answers,
                    // se manda para que no afecte la tarea del estudiante por si el profesor no lo califica
                    percentage: 100
                });
                onChangeTaskByAnswer(response, 'writing_check');
            } catch (e) {
                showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' });
            } finally {
                setIsLoadingSubmit(false)
            }
        }
        if (lessonData && lessonData.evaluation &&
            lessonData.evaluation.evaluation_item) {
            addCompletedExerciseId(exerciseId ?? "")
            if (lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
                setStepBookItem(exerciseId ?? '')
            } else {
                setStepBookItem('FINISH_LESSON')
            }
        }
    };
    const { setOffDraggable, showButtons } = UseBookContainerModule()
    const handleShowFeedBack = ()=>{
        setShowFeedback(!showFeedback)
    }
    return (
        <>
            <div className="container-fluid" style={{ display: 'flex' }}>
                <div style={divStyle}>
                    <div className='d-flex justify-content-between'>
                        {(mode === 'TIME_TO_SPEAK' || mode === 'DID_YOU_UNDERSTAND') ?
                            <div className="p-1 d-flex ">
                                <img className="" src={
                                    toAbsoluteUrl(`/media/svg/michigan-icons/header/white/time_speak.png`)
                                } style={{ width: '40px', height: '40px' }} />
                                <h5 className="mb-0 p-2 text-white champ-light" style={{ fontSize: "20px" }}>
                                    {mode === 'TIME_TO_SPEAK' ? 'Time to speak!' : 'Did you understand?'}
                                </h5>
                            </div>
                            : <HeaderCard />
                        }
                        {showButtons &&
                            <OverlayTrigger
                                overlay={
                                    <Tooltip className='tooltip-inverse'> 
                                        <div className='fs-8 champ-bold d-flex justify-content-start text-primary'>{hasFeedback?"feedback from teacher":"No ha sido calificado"}</div>
                                    </Tooltip>
                                    } 
                                placement='top'
                                offset={[0, 10]}
                            >
                                <button 
                                    type='button'
                                    className={`border border-0 bg-transparent ${hasFeedback? "cursor-pointer": "cursor-"}`}
                                    disabled={!hasFeedback}
                                >
                                    <img alt="transcript"
                                        onClick={handleShowFeedBack}
                                        src={toAbsoluteUrl('/media/svg/michigan-icons/elements/white/text_audio.svg')}
                                        style={{ width: '20px' }} 
                                    />
                                </button>
                            </OverlayTrigger>
                        }
                    </div>
                    <div className='scroll-y overflow-y-auto mb-6' style={{ maxHeight: '70px', maxWidth: '435px' }}>
                        <span
                            className="text-white cuestion mb-0 poppins-light"
                            style={{
                                marginLeft: '10px',
                                fontSize: '80%',
                                color: '#fffff',
                                marginBottom: '20px'
                            }}>
                            {(mode === 'TIME_TO_SPEAK' || mode === 'DID_YOU_UNDERSTAND') 
                                ? mode === 'TIME_TO_SPEAK' 
                                    ? 'Answer the questions below and interact with your teacher and classmates.' 
                                    : 'Interact wth your teacher to answer the questions below.' 
                                : instruction
                            }
                        </span>
                    </div>
                    <div className="row p-2">
                        <div className="col-6 row align-items-start  scroll-y overflow-y-auto mb-2" style={{ maxHeight: '350px', maxWidth: '350px' }} >
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                {responses ?
                                    responses.map((x, index) => (
                                        <p key={index} className="text-white mb-2 p-0 poppins-light" style={{ padding: 0, fontSize: 12, cursor: 'pointer' }} onClick={() => selectQuestion(index)}>
                                            {finderText(x.order)
                                                ?
                                                <i style={{
                                                    color: (selected !== undefined && index == selected) ? '#D0FF00' : 'white',
                                                    fontSize: 15, paddingRight: 5
                                                    }} className="fa fa-check"
                                                />
                                                :
                                                <i style={{
                                                    color: (selected !== undefined && index == selected) ? '#D0FF00' : 'white',
                                                    fontSize: 15, paddingRight: 5
                                                }} className="fa fa-circle"/>
                                            }
                                            {x.title}
                                        </p>
                                    ))
                                    : question.map((x, index) => (
                                        <p key={index} className="text-white mb-2 p-0 poppins-light" style={{ padding: 0, fontSize: 12, cursor: 'pointer' }} onClick={() => selectQuestion(index)}>
                                            {finderText(x.order)
                                                ?
                                                <i style={{
                                                    color: (selected !== undefined && index == selected) ? '#D0FF00' : 'white',
                                                    fontSize: 15, paddingRight: 5
                                                }} className="fa fa-check"></i>
                                                :
                                                <i style={{
                                                    color: (selected !== undefined && index == selected) ? '#D0FF00' : 'white',
                                                    fontSize: 15, paddingRight: 5
                                                }} className="fa fa-circle"></i>
                                            }
                                            {x.title}
                                        </p>
                                    ))}
                            </div>
                        </div>
                        <div className="col-6 mt-3">
                            <textarea
                                value={selected !== null ? answers[selected].text : ''}
                                onChange={(e) => handleChangeText(e.target.value)}
                                onMouseEnter={() => setOffDraggable(true)}
                                onMouseLeave={() => setOffDraggable(false)}
                                className="form-control rounded-4 text-white" placeholder="Type here.."
                                style={{ background: "#3f8bff", height: "160px" }} id="exampleFormControlTextarea1"
                            />
                        </div>
                    </div>
                    {(allAnswered && showButtons)&& 
                        <div className='d-flex justify-content-between align-items-center'
                        style={{
                            width: '100%',
                            marginTop: 'auto',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                        <div style={{ marginLeft: '280', display: 'flex', minWidth: '100px', maxWidth: '200px', height: 'auto' }}>
                            <ButtonTryAgain onAction={tryAgain} />
                        </div>
                        <div style={{ marginRight:'280', display: 'flex',  minWidth: '100px', maxWidth: '200px',  height: 'auto', alignItems: 'center' }}>
                            <ButtonSaveExercise
                                isChecked={allAnswered}
                                onNext={() => { }}
                                onSubmit={handleSubmit}
                                exerciseId={exerciseId}
                            />
                        </div>
                    </div>
                    
                    }
                </div>
            </div>
            {(showFeedback && selectedExercise) &&
                <div 
                    className='rounded-top-9 container-fluid d-flex mx-auto px-0'
                    style={{ 
                        marginTop: '-40px', 
                        zIndex:-1,
                        borderRadius: '30px',
                        border: 'solid rgb(255, 255, 255)',
                        width: '100%',
                        maxWidth: '600PX',
                        height: '185px',
                        backgroundColor: '#3f8bff',
                        paddingTop: '50px'
                }}>
                    <div className='container champ-bold mb-0 text-white'>
                        <div className='row'>
                            <div className="col-12 mb-3 ">
                                <div className="input-group">
                                    <span className="input-group-text pe-5 ts-1" id="basic-addon1">Feedback</span>
                                    <textarea  className="form-control poppins-light" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" disabled value={selectedExercise.teacherObservation}/>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-4 mb-3">
                                <div className="input-group">
                                    <span className="input-group-text pe-5" id="basic-addon2">Score</span>
                                    <input type="text" className="form-control poppins-light" style={{ maxWidth: '55px' }} placeholder="Nota" aria-label="Nota" aria-describedby="basic-addon2" disabled value={selectedExercise.percentage}/>
                                </div>
                            </div>
                            <div className="col-6 mb-3 ms-auto">
                                <div className="input-group">
                                    <span className="input-group-text pe-5" id="basic-addon3">Teacher</span>
                                    <input type="text" className="form-control poppins-light" placeholder="Nota" aria-label="Nota" aria-describedby="basic-addon3" disabled value={selectedExercise.teacher}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export { WritingCheckListItem };