export interface DataPaginateLessonAdjustmentResponse {
    status:  string;
    message: string;
    data:    DataLessonAdjustment;
}

export interface DataAllLessonAdjustmentResponse {
    status:  string;
    message: string;
    data:    LessonAdjustment[];
}

export interface DataLessonAdjustment {
    id?: string;
    appointment?:any;
    trailClass?:any;
    status: LessonAdjustmentType;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface LessonAdjustment  {
    id?: string;
    appointment?:any;
    lesson?:any;
    studentSetting?:any;
    status?: LessonAdjustmentType;
    isActive?: boolean;
    createdAt?:string;
    updatedAt?:string;

}

export enum LessonAdjustmentType {
    ACCEPTED = 'ACCEPTED',
    REFUSED = 'REFUSED',
    PENDING = 'PENDING',
}