import React, { useEffect, useState } from 'react';

import './index.css';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { ResetStepByExercises } from '../../utils';
// import { AddMovieWritingResponse } from '../services';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import HeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/HeaderCard';
import { MultipleSelectionQuestionIE, MultipleSelectionQuestionAnswerIE, MultipleSelectionType } from '../interfaces';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import { AddMultipleSelectionResponse } from '../services';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { ButtonTryAgain } from '../../common/button-try-again/buttonTryAgain';
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise';
import { UseMultipleSelectionModule } from '../store';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
import { UseBookContainerModule } from '../../../../book/store/main';


type Props = {
    userMode?: ModeTypeExercise
    responses?: MultipleSelectionQuestionAnswerIE[];
    question: MultipleSelectionQuestionIE[]
};
const divStyle = {
    backgroundColor: '#3f8bff',
    width: '525px',
    height: 'auto',
    borderRadius: '30px',
    borderColor: 'rgb(255, 255, 255)',
    borderStyle: 'solid',
    padding: '27px 10px 40px 20px'
};

const MultipleSelectionExItem: React.FC<Props> = ({
    userMode,
    question,
    responses,
}: Props) => {

    const {
        lessonData,
        stepBookItem,
        currentTaskId,
        setStepBookItem,
        addCompletedExerciseId,
        onChangeTaskByAnswer,
        setIsLoadingSubmit
    } = UseBookStudentModule();

    const { exerciseId, instructions } = UseMultipleSelectionModule();
    const { modeViewAnswer } = UseModeCourseModule();
    const {showButtons} = UseBookContainerModule()
    const [allAnswered, setAllAnswered] = useState<boolean>(false);
    const [answers, setAnswers] = useState<MultipleSelectionQuestionAnswerIE[]>([]);
    const [score, setScore] = useState<number>(0);
    const tryAgain = () => {
        setAnswers([])
        setAllAnswered(false)
        setScore(0)
        setAnswers(question.map(q => ({
            title: q.title,
            text: '',
            order: q.order,
            isTrue: false
        })));
    }
    useEffect(() => {
        if (responses && responses.length > 0) {
            setAnswers(responses);
        } else {
            setAnswers(question.map(q => ({
                title: q.title,
                text: modeViewAnswer ?
                    (q.options.find(op => op.isTrue)?.title ?? '')
                    : '',
                order: q.order,
                isTrue: false
            })));
        }
    }, [question, responses, modeViewAnswer]);

    useEffect(() => {
        // Verifica si todas las preguntas han sido respondidas
        const allAnsweredCheck = answers.every(answer => answer.text !== '');
        setAllAnswered(allAnsweredCheck);

        if (allAnsweredCheck) {
            calculateScore();
        }
    }, [answers]);

    const handleInputChange = (title: string, value: string, isTrue: boolean) => {
        if (!allAnswered) {
            const updatedAnswers = answers.map((answer) =>
                answer.title === title ? { ...answer, text: value, isTrue } : answer
            );
            setAnswers(updatedAnswers);
        }
    };

    const calculateScore = () => {
        const correctAnswers = answers.filter(answer => answer.isTrue).length;
        const percentage = (correctAnswers / question.length) * 100;
        setScore(percentage);
        // Aquí puedes pasar el porcentaje a otro estado o función si lo necesitas
    };


    const handleSubmit = async () => {
        if (userMode === 'STUDENT' && currentTaskId && exerciseId) {
            try {
                setIsLoadingSubmit(true)
                const response = await AddMultipleSelectionResponse({
                    isActive: true,
                    answers: answers,
                    task: currentTaskId,
                    exercises: exerciseId ?? '',
                    percentage: score,
                });
                onChangeTaskByAnswer(response, 'multiple_selection');
            } catch (e) {
                showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' });
            } finally {
                setIsLoadingSubmit(false)
            }
        }     
        if (lessonData && lessonData.evaluation &&
            lessonData.evaluation.evaluation_item) {
            addCompletedExerciseId(exerciseId ?? "")
            if (lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
                setStepBookItem(exerciseId ?? '')
            } else {
                setStepBookItem('FINISH_LESSON')
            }
        }
    };

    return (
        <>
            <div className="container-fluid " style={{ display: 'flex' }}>
                <div style={divStyle}>
                    <HeaderCard />
                    <div className="scroll-y overflow-y-auto " style={{maxHeight:'50px'}}>
                        <span className="text-white p-2 fs-8 mb-0 poppins-light">
                            {instructions}
                        </span>
                    </div>
                    <div className="container">
                        <div className="row py-2">

                            {question.map((q, questionIndex) => (
                                <div className='col-6 ' key={q.title}>
                                    <div>
                                        <li className="fs-8 poppins-bold mb-0 text-white" style={{ fontSize: 20 }}>{q.title}</li>
                                        {q.options.map((option) => (
                                            <>
                                                <span key={option.title}
                                                    className={`fs-8 poppins-light mb-0 cursor-hover cursor-pointer ${answers.find(a => a.text === option.title && a.order === questionIndex + 1) ? 'select-simple-book' : 'text-white'}`}
                                                    style={{ fontSize: 20 }}
                                                    onClick={() => handleInputChange(q.title, option.title, option.isTrue)}
                                                >
                                                    {allAnswered && option.isTrue ? <i className="bi bi-check  text-success"  ></i> : null}
                                                    {allAnswered && !option.isTrue && answers[questionIndex]?.isTrue === option.isTrue ? <i className="bi bi-x text-danger  "  ></i> : null}
                                                    {option.title}
                                                </span><br />
                                            </>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {allAnswered && showButtons && (
                        <div className='d-flex justify-content-between'>
                                <div style={{ top: '12px' }} >
                                <ButtonTryAgain onAction={tryAgain} />
                            </div>
                            <div
                            >
                                <ButtonSaveExercise
                                    isChecked={allAnswered}
                                    onNext={() => { }}
                                    onSubmit={handleSubmit}
                                    exerciseId={exerciseId}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>

        </>
    );
};

export { MultipleSelectionExItem };