import React from "react";

const countryPrefixes = [
  { country: "United States", code: "(+1)" },
  { country: "Canada", code: "(+1)" },
  { country: "Mexico", code: "(+52)" },
  { country: "Brazil", code: "(+55)" },
  { country: "Argentina", code: "(+54)" },
  { country: "United Kingdom", code: "(+44)" },
  { country: "Germany", code: "(+49)" },
  { country: "France", code: "(+33)" },
  { country: "Spain", code: "(+34)" },
  { country: "Italy", code: "(+39)" },
  { country: "China", code: "(+86)" },
  { country: "Japan", code: "(+81)" },
  { country: "Australia", code: "(+61)" },
  { country: "India", code: "(+91)" },
  { country: "Russia", code: "(+7)" },
  { country: "South Korea", code: "(+82)" },
  { country: "South Africa", code: "(+27)" },
  { country: "New Zealand", code: "(+64)" },
  { country: "Egypt", code: "(+20)" },
  { country: "Saudi Arabia", code: "(+966)" },
  { country: "United Arab Emirates", code: "(+971)" },
  { country: "Turkey", code: "(+90)" },
  { country: "Sweden", code: "(+46)" },
  { country: "Norway", code: "(+47)" },
  { country: "Finland", code: "(+358)" },
  { country: "Denmark", code: "(+45)" },
  { country: "Greece", code: "(+30)" },
  { country: "Netherlands", code: "(+31)" },
  { country: "Belgium", code: "(+32)" },
  { country: "Switzerland", code: "(+41)" },
  { country: "Austria", code: "(+43)" },
  { country: "Poland", code: "(+48)" },
  { country: "Portugal", code: "(+351)" },
  { country: "Czech Republic", code: "(+420)" },
  { country: "Hungary", code: "(+36)" },
  { country: "Romania", code: "(+40)" },
  { country: "Israel", code: "(+972)" },
  { country: "Pakistan", code: "(+92)" },
  { country: "Bangladesh", code: "(+880)" },
  { country: "Thailand", code: "(+66)" },
  { country: "Indonesia", code: "(+62)" },
  { country: "Vietnam", code: "(+84)" },
  { country: "Malaysia", code: "(+60)" },
  { country: "Philippines", code: "(+63)" },
  { country: "Singapore", code: "(+65)" },
  { country: "Nigeria", code: "(+234)" },
  { country: "Kenya", code: "(+254)" },
  { country: "Chile", code: "(+56)" },
  { country: "Colombia", code: "(+57)" },
  { country: "Peru", code: "(+51)" },
  { country: "Venezuela", code: "(+58)" },
  { country: "Cuba", code: "(+53)" },
  { country: "Dominican Republic", code: "(+1-809)" },
  { country: "Honduras", code: "(+504)" },
  { country: "Panama", code: "(+507)" },
  { country: "Uruguay", code: "(+598)" },
  { country: "Paraguay", code: "(+595)" },
  { country: "Bolivia", code: "(+591)" },
  { country: "Ecuador", code: "(+593)" },
  { country: "Afghanistan", code: "(+93)" },
  { country: "Albania", code: "(+355)" },
  { country: "Algeria", code: "(+213)" },
  { country: "Andorra", code: "(+376)" },
  { country: "Angola", code: "(+244)" },
  { country: "Antigua and Barbuda", code: "(+1-268)" },
  { country: "Armenia", code: "(+374)" },
  { country: "Azerbaijan", code: "(+994)" },
  { country: "Bahrain", code: "(+973)" },
  { country: "Barbados", code: "(+1-246)" },
  { country: "Belarus", code: "(+375)" },
  { country: "Belize", code: "(+501)" },
  { country: "Benin", code: "(+229)" },
  { country: "Bhutan", code: "(+975)" },
  { country: "Botswana", code: "(+267)" },
  { country: "Burkina Faso", code: "(+226)" },
  { country: "Burundi", code: "(+257)" },
  { country: "Cambodia", code: "(+855)" },
  { country: "Cameroon", code: "(+237)" },
  { country: "Cape Verde", code: "(+238)" },
  { country: "Central African Republic", code: "(+236)" },
  { country: "Chad", code: "(+235)" },
  { country: "Comoros", code: "(+269)" },
  { country: "Congo (DRC)", code: "(+243)" },
  { country: "Costa Rica", code: "(+506)" },
  { country: "Croatia", code: "(+385)" },
  { country: "Cyprus", code: "(+357)" },
  { country: "Djibouti", code: "(+253)" },
  { country: "East Timor", code: "(+670)" },
  { country: "El Salvador", code: "(+503)" },
  { country: "Estonia", code: "(+372)" },
  { country: "Fiji", code: "(+679)" },
  { country: "Georgia", code: "(+995)" },
  { country: "Ghana", code: "(+233)" },
  { country: "Guatemala", code: "(+502)" },
  { country: "Iceland", code: "(+354)" },
  { country: "Iraq", code: "(+964)" },
  { country: "Jamaica", code: "(+1-876)" },
  { country: "Jordan", code: "(+962)" },
  { country: "Kazakhstan", code: "(+7)" },
  { country: "Kuwait", code: "(+965)" },
  { country: "Kyrgyzstan", code: "(+996)" },
  { country: "Lebanon", code: "(+961)" },
  { country: "Libya", code: "(+218)" },
  { country: "Luxembourg", code: "(+352)" },
  { country: "Madagascar", code: "(+261)" },
  { country: "Malawi", code: "(+265)" },
  { country: "Malta", code: "(+356)" },
  { country: "Mauritius", code: "(+230)" },
  { country: "Moldova", code: "(+373)" },
  { country: "Mongolia", code: "(+976)" },
  { country: "Montenegro", code: "(+382)" },
  { country: "Mozambique", code: "(+258)" },
  { country: "Namibia", code: "(+264)" },
  { country: "Nepal", code: "(+977)" },
  { country: "Oman", code: "(+968)" },
  { country: "Qatar", code: "(+974)" },
  { country: "Rwanda", code: "(+250)" },
  { country: "Senegal", code: "(+221)" },
  { country: "Serbia", code: "(+381)" },
  { country: "Seychelles", code: "(+248)" },
  { country: "Sierra Leone", code: "(+232)" },
  { country: "Slovakia", code: "(+421)" },
  { country: "Slovenia", code: "(+386)" },
  { country: "Sudan", code: "(+249)" },
  { country: "Syria", code: "(+963)" },
  { country: "Tanzania", code: "(+255)" },
  { country: "Togo", code: "(+228)" },
  { country: "Trinidad and Tobago", code: "(+1-868)" },
  { country: "Tunisia", code: "(+216)" },
  { country: "Uganda", code: "(+256)" },
  { country: "Uzbekistan", code: "(+998)" },
  { country: "Zambia", code: "(+260)" },
  { country: "Zimbabwe", code: "(+263)" },
];

type Props = React.SelectHTMLAttributes<HTMLSelectElement> & {
  onChangeValue: (value: string) => void;
  renderOption?: (item: { country: string, code: string }) => React.ReactNode;
  initialValue?: string
};

const CountrySelect: React.FC<Props> = ({ initialValue, onChangeValue, renderOption, ...props }) => {
  return (
    <select
      value={initialValue}
      onChange={(e) => onChangeValue(e.target.value)}
      {...props}
    >
      {countryPrefixes.sort((a, b) => {
        if (a.country === "Colombia") return -1;
        if (b.country === "Colombia") return 1;
        return a.country.localeCompare(b.country);
      }).map((item, i) => (
        renderOption ? renderOption(item) :
          <option key={`${item.code}-${item.country}-${i}`} value={item.code} style={{ backgroundColor: 'bla' }}>
            {item.code} {item.country}
          </option>
      ))}
    </select>
  );
};

export { CountrySelect };