import React, { useEffect, useState } from 'react'
import { Modal, OverlayTrigger } from 'react-bootstrap'
import ExerciseInfo from './ExerciseInfo'
import { RenderBookPage } from './renderBookPage'
import clsx from 'clsx'
import { TraditionalLevelEnum } from '../../../abroad/road-map-step/interfaces'
import { Exercises, ExercisesType } from '../../../admin/exercises/interfaces'
import { useTranslation } from 'react-i18next'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { StudentEbook, UseBookTeacherModule } from '../../modules/class/store/main'
import { FindOneTaskByLessonAndStudent } from '../../modules/class/services'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import { EvaluationItem } from '../../../admin/evaluation/interfaces'
import { setAnswersEbook } from '../../utils/setAnswersEBook'
import { setQuestionsEbook } from '../../utils/setQuestionsEBook'
import { BookPage } from '../../../admin/book/modules/book-page/interfaces'
import { Lessons } from '../../../abroad/learning-path/modules/lessons/interfaces'
import { OneTaskByFindOneTask } from '../../../courses/exercises/basic-vocabulary/services'
import useTrailTask from '../../../dashboard/components/student/store'
import { NexLessonModal } from '../../../dashboard/components/next-lesssons/modal'
import { UseFindPaginatedTrailClass } from '../../../me-booking/services/trail-class'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import UseSocket, { TypeSocketEvent } from '../../../auth/services/SocketServices'
import Swal from 'sweetalert2'
import { UseGetOneAppointment } from '../../../admin/appointment/services'
import { UseBookContainerModule } from '../../store/main'
import { UseModuleExerciseCrossword } from '../../../courses/exercises/Crossword/store/main'

export type Student = {
  name: string
  id: string
  level: TraditionalLevelEnum
  photo?: any
  color?: string
}

type Props = {
}

const LoggedStudentsModal: React.FC<Props> = ({ }) => {
  const { t } = useTranslation();
  const { studentsList, currentPage, setStudentList } = UseBookTeacherModule();
  const { modal,
    currentLesson,
    setCurrentAppointment,
    setModal,
    setNextLessonData,
    setModeModal,
    setCurrentLesson,
    setStudentSetting
  } = useTrailTask();
  const {setShowButtons} = UseBookContainerModule()
  const {resetData} = UseModuleExerciseCrossword()
  const [selectedExercise, setSelectedExercise] = React.useState<Exercises>();
  const [evaluationItems, setEvaluationItems] = React.useState<EvaluationItem[]>([]);
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const [lesson, setLesson] = React.useState<Lessons>()
  const [student, setStudent] = React.useState<StudentEbook>()
  const [tab, setTab] = React.useState<'Exercises' | 'Book'>('Book');
  const [bookPages, setBookPages] = React.useState<BookPage[]>();
  const [currentTask, setCurrentTask] = React.useState<any>(); // tipar tarea
  const [isScrollActive, setIsScrollActive] = React.useState(false)
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const [realStudentsList, setRealStudentsList] = useState<StudentEbook[]>(studentsList ?? [])
  const [showLessonBubbles, setShowLessonBubbles] = useState<boolean>(false)
  const [lessonBubbles, setLessonBubbles] = useState<{ lesson: string, lessonName: string }[]>([])

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const appointment = searchParams.get('appointment') ?? ''



  const handleSelectItem = (item: EvaluationItem) => {
    if (currentTask) {
      setSelectedExercise(item.exercise);
      setQuestionsEbook(item);
      setAnswersEbook(currentTask, item.exercise.type, item.exercise.id);
    }
  };

  const handleOpenModal = (selectedStudent: StudentEbook, lesson?: string) => {
    setShowModal(true)
    setStudent(selectedStudent);
    if (selectedStudent.lesson !== 'empty' && lesson !== 'empty') {
      getTaskByLessonInStudent(selectedStudent.studentSetting, lesson ? lesson : selectedStudent.lesson);
    }
    setShowLessonBubbles(false)
    setLessonBubbles([])
  }
  
  const handleCloseModal = async () => {
    setShowButtons(true)
    setShowModal(false);
    setTab('Book');
    setSelectedExercise(undefined);
    setEvaluationItems([]);
    setStudent(undefined);
    setLesson(undefined);
    setBookPages(undefined);
    setCurrentTask(undefined);
    resetData()
    if (currentTask && currentTask?.id) {
      await OneTaskByFindOneTask(currentTask?.id);
    }
  };

  const handleCloseNextLessonModal = () => {
    setModal(!modal);
    setShowModal(!showModal);
  };

  const handleOpenNextLessonModal = async () => {
    if (!student || !student.studentSetting) return;
    if (!student.assistantTeacher) return showSimpleAlert({ message: 'Debe estar presente en la asistencia para poder avanzar lecciones.', icon: 'warning' });
    const result = await UseFindPaginatedTrailClass({
      id: student.studentSetting,
      after: moment().add(1, 'week').endOf('week').toISOString(),
      before: moment().toISOString(),
      limit: 1,
      isSuspense: false,
    });
    if (!result.nextLessons || result.nextLessons?.lessons.length < 1) return;
    setNextLessonData(result.nextLessons);
    setModeModal('TEACHER')
    setStudentSetting(student.studentSetting)
    setCurrentAppointment(appointment)
    const nextLesson = result.nextLessons.lessons[0];
    if (nextLesson?.lesson.id !== student.lesson && nextLesson.status === 'IN_REVIEW') {
      setCurrentLesson(nextLesson);
      // return showSimpleAlert({message: `El estudiante no ha asistido a la leccion: ${nextLesson.lesson.name}`, icon: 'warning'});
    };
    handleCloseNextLessonModal();
  };

  const getTaskByLessonInStudent = React.useCallback(async (setting: string, lesson: string) => {
    try {
      const response = await FindOneTaskByLessonAndStudent(setting, lesson);
      if (response && response.lesson && response.lesson !== 'empty' && response.lesson.evaluation &&
        (response.lesson.evaluation.evaluation_item || response.lesson.evaluation.evaluation_item.length > 0)) {
        setLesson(response.lesson);
        setBookPages(response.lesson?.book_pages ? response.lesson.book_pages : []);
        setEvaluationItems(response.lesson.evaluation.evaluation_item);
        // setSelectedExercise(response.lesson.evaluation.evaluation_item[0].exercise);
        if (response?.task) {
          setCurrentTask(response.task);
        }
      }
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar tarea', icon: 'error' });
    }
  }, [realStudentsList]);

  React.useEffect(() => {
    const containerElement = containerRef.current
    if (containerElement) {
      // Verifica si el contenido ha desbordado el contenedor verticalmente
      setIsScrollActive(containerElement.scrollHeight > containerElement.clientHeight)
    }
  }, [realStudentsList]);

  UseSocket((e) => {
    if (e.type === TypeSocketEvent.APPOINTMENT && e.body.id === appointment) {
      Swal.fire({
        title: `${e.body.name} aceptó`,
        html: "<p class='poppins-light'>Desear actualizar la clase?</p>",
        icon: 'warning',
        showCancelButton: true,
        iconColor: '#2d30e1',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "<span class='poppins-light fs-4'>Sí!</span>",
        cancelButtonText: "<span class='poppins-light fs-4'>No</span>",
        customClass: {
          title: 'champ-light',
          confirmButton: 'confirm-button-sweet-alert',
          cancelButton: 'cancel-button-sweet-alert',
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await UseGetOneAppointment(appointment);
          setStudentList(response.students);
        } else {

        }
      })

    }
  })

  //EFECTO A REFREZCAR CON SOCKET 
  useEffect(() => {

    if (studentsList) {
      const studentCount = new Map();  // Para contar cuántos estudiantes tienen el mismo settingId
      // Primera pasada: contar cuántos estudiantes tienen cada studentSetting
      studentsList.forEach(student => {
        const settingId = student.studentSetting;
        studentCount.set(settingId, (studentCount.get(settingId) || 0) + 1);
      });
      const uniqueStudents = new Map();
      studentsList.forEach(student => {
        const settingId = student.studentSetting;
        if (studentCount.get(settingId) > 1) {
          student = { ...student, hasSecondLesson: true };
        }
        if (!uniqueStudents.has(settingId)) {
          uniqueStudents.set(settingId, student);
        }
      });
      // Convertir el Map de vuelta a una lista de estudiantes
      setRealStudentsList(Array.from(uniqueStudents.values()));
    }
  }, [studentsList]);

  const handleGetLessons = (student: StudentEbook) => {
    // Filtrar los estudiantes que tienen el mismo studentSetting que el estudiante actual
    setShowLessonBubbles(!showLessonBubbles);
    if (showLessonBubbles) return
    setStudent(student)
    const matchingLessons = studentsList
      ?.filter(s => s.studentSetting === student.studentSetting)
      ?.map(s => ({
        lesson: s.lesson,
        lessonName: s.lessonName
      })) || [];
    matchingLessons.sort((a, b) => a.lessonName.localeCompare(b.lessonName))
    setLessonBubbles(matchingLessons);
  }

  const formatLessonName = (lessonName: string) => {
    const [unitPart, lessonPart] = lessonName.split(' - '); // Divide en "Unit #" y "Lesson #"
    const unitNumber = unitPart.replace('Unit ', 'U'); // Reemplaza "Unit " por "U"
    const lessonNumber = lessonPart.replace('Lesson ', 'L'); // Reemplaza "Lesson " por "L"
    return `${unitNumber}${lessonNumber}`; // Devuelve el formato U#-L#
  };

  return (
    <>
      <div
        ref={containerRef}
        style={{ ...styles.studentListContainer, zIndex: 2, minWidth: 'fit-content' }}
        className={`d-flex flex-column justify-content-${isScrollActive ? 'start' : 'center'} align-items-center flex-nowrap`}
      >
        {realStudentsList && realStudentsList.map((s, index) => (
          <div className='d-flex flex-column align-items-center position-relative' key={index}>
            <div
              className={`symbol symbol-${realStudentsList.length > 7 ? '40px' : 'symbol-75px'} symbol-circle mt-2 cursor-pointer breathing-blue-effect`}
            >
              <span
                className={`symbol-label bg-secondary text-${s.assistantStudent}`}
                onClick={() => {
                  setShowModal(false)
                  if (s.hasSecondLesson) {
                    handleGetLessons(s)
                  } else {
                    handleOpenModal(s)
                  }
                }}
              >
                {s.studentName.charAt(0)}
              </span>
              <div className={`symbol-badge bg-${s.assistantStudent ? 'success' : 'danger'} start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2`}></div>
            </div>

            <a
              className='poppins-light text-gray-700 text-hover-primary mt-1 cursor-pointer fs-8 text-center'
              onClick={() => {
                if (s.hasSecondLesson) {
                  setShowLessonBubbles(true)
                } else {
                  handleOpenModal(s)
                }
              }}
            >
              {s.studentName}
            </a>

            {/* Burbujas de lecciones */}
            {(student === s) && showLessonBubbles && lessonBubbles.length > 0 && (
              <div
                className='position-absolute d-flex flex-column'
                style={{
                  left: '100%',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                {lessonBubbles.map(lesson => (
                  <div
                    className={`symbol symbol-40px symbol-circle mt-2 cursor-pointer`}
                    key={lesson?.lesson ?? "fklasd"}
                  >
                    <span
                      className={`symbol-label bg-success bg-opacity-25 text-center`}
                      onClick={() => handleOpenModal(s, lesson?.lesson)}
                    >
                      {(lesson && lesson.lesson !== 'empty') ? formatLessonName(lesson.lessonName) : "Not found"}
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName='modal-dialog modal-dialog-centered modal-xl'
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title className='poppins-bold'>Detalles del Estudiante</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Contenido principal del modal */}
          <div className='d-flex flex-row justify-content-between align-items-stretch'>
            {/* Contenedor de la zona izquierda del modal*/}
            <div className='d-flex flex-column flex-grow-1 border border-1 rounded me-1'>
              {/* Contenedor con la info del estudiante */}
              <div className='d-flex flex-row justify-content-center align-items-center mx-10 pt-10'>
                <div className={`symbol 'symbol-55px' symbol-circle m-auto d-flex `}>
                  {student?.avatar ? (
                    <img alt='Pic' src={toAbsoluteUrl(student.avatar.url)} />
                  ) : (
                    <span
                      className={`symbol-label bg-grey text-grey  fs-5 fw-bolder`}
                    >
                      {student?.studentName.charAt(0)}
                    </span>
                  )}
                </div>
                <div className=' w-100 justify-content-between'>
                  <div className='flex-fill'>
                    <p className='list-unstyled text-gray-700 poppins-bold fs-6 ps-10 m-0'>
                      {student?.studentName}
                    </p>
                    <p className='list-unstyled text-gray-400 poppins-light fs-6 ps-10 m-0'>
                      {student?.studentName}
                    </p>
                  </div>
                </div>
                <p className='px-10 me-5 poppins-bold '>Topic: {lesson && lesson.name}</p>
              </div>
              {/* Tabs con el resto del contenido */}
              <div className='card card-custom'>
                <div className='card-header card-header-stretch overflow-hidden'>
                  <ul
                    className='nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap'
                  >
                    <li className='nav-item'>
                      <a
                        className={clsx(`nav-link poppins-bold text-${tab === 'Exercises' ? "primary" : ""} cursor-pointer`, {
                          active: tab === 'Exercises',
                        })}
                        onClick={() => setTab('Exercises')}
                      >
                        Exercises
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={clsx(`nav-link poppins-bold text-${tab === 'Book' ? "primary" : ""} cursor-pointer`, {
                          active: tab === 'Book',
                        })}
                        onClick={() => setTab('Book')}
                        role='tab'
                      >
                        Book Page
                      </a>
                    </li>
                  </ul>
                  <img 
                    src={toAbsoluteUrl('/media/svg/michigan-icons/header/blue/roadmap_blue.svg')} 
                    alt="Mapa del estudiante" 
                    className='breathing-blue-effect p-2 mt-3 cursor-pointer'
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '100px',
                      backgroundColor: '#1cebcc',
                    }}
                    onClick={() => handleOpenNextLessonModal()}
                  />
                  {/* <button
                    type='button'
                    className='btn poppins-light d-flex align-items-center breathing-blue-effect'
                    onClick={() => handleOpenNextLessonModal()}
                  >
                    Mapa del estudiante
                  </button> */}
                </div>
                {tab === 'Exercises' && selectedExercise && currentTask &&
                  <ExerciseInfo exercise={selectedExercise} task={currentTask} />}
                {tab === 'Book' && (
                  <RenderBookPage
                    numberPage={currentPage}
                    pagesBook={bookPages ?? []}
                  />
                )}
              </div>
            </div>
            {/* Contenedor de la lista de ejercicios */}
            <div className='col rounded' style={{
              maxHeight: '700px',
              overflowY: 'auto'
            }}>
              <div className='list-group' style={{
              }}>
                {
                  evaluationItems &&
                  evaluationItems.filter(x => x.exercise.type !== ExercisesType.AUDIO_INTERACTION)
                    .sort((a, b) => a.order - b.order).map((item) => (
                      <a
                        href='#'
                        className={`list-group-item list-group-item-action ${selectedExercise === item.exercise ? 'active' : ''
                          } `}
                        aria-current='true'
                        onClick={() => handleSelectItem(item)}
                      >
                        <div className='d-flex w-100 justify-content-between'>
                          <h5 className='mb-1 poppins-bold'>{item.name}</h5>
                          <small className='poppins-light'>{item.exercise.level}</small>
                        </div>
                        <p className={`mb-1 poppins-light text-${selectedExercise === item.exercise ? "white" : "secondary-700"}`}>{t(`common:${item.exercise.type}`)}.</p>
                        <div className='d-flex w-100 justify-content-between'>
                          <small className={`poppins-light text-${selectedExercise === item.exercise ? "white" : "secondary-700"}`}>{item.description}</small>
                          <small>
                            {item.exercise.isActive ? (
                              <i className='bi bi-check-circle-fill text-success' />
                            ) : (
                              <i className='bi bi-x-circle-fill text-danger' />
                            )}
                          </small>
                        </div>
                      </a>
                    ))}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <NexLessonModal show={modal} handleClose={handleCloseNextLessonModal} />

    </>
  )
}

export default LoggedStudentsModal

const styles: Record<string, React.CSSProperties> = {
  studentListContainer: {
    position: 'absolute',
    top: 120,
    left: 0,
    right: `calc(100vw - 120px)`,
    bottom: 20,
    height: `calc(90vh - 120px)`,
  },
}
