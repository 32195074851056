import {RefObject, useEffect, useState} from 'react'

export interface ScrollAndZoomHandlers {
  handleScroll: (event: WheelEvent) => void
  handleMouseDown: React.MouseEventHandler<HTMLDivElement>
  handleMouseMove: React.MouseEventHandler<HTMLDivElement>
  handleMouseUp: React.MouseEventHandler<HTMLDivElement>
  handleMouseEnter: React.MouseEventHandler<HTMLDivElement>
  handleMouseLeave: React.MouseEventHandler<HTMLDivElement>
  handleTouchStart: React.TouchEventHandler<HTMLDivElement>
  handleTouchMove: React.TouchEventHandler<HTMLDivElement>
  handleTouchEnd: React.TouchEventHandler<HTMLDivElement>
  zoom: number
  isDragging: boolean
}

const useScrollAndZoom = (
  containerRef: RefObject<HTMLDivElement>,
  activeContainerRef: React.MutableRefObject<HTMLDivElement | null>
): ScrollAndZoomHandlers => {
  const [zoom, setZoom] = useState<number>(100)
  const minZoom: number = 100
  const maxZoom: number = 500
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [startY, setStartY] = useState(0)
  const [startScrollLeft, setStartScrollLeft] = useState(0)
  const [startScrollTop, setStartScrollTop] = useState(0)
  const [touchStartDistance, setTouchStartDistance] = useState<number | null>(null);
  
  const handleScroll = (event: WheelEvent) => {
    if (containerRef.current && activeContainerRef.current === containerRef.current) {
      if (event.ctrlKey || event.metaKey) {
        const containerRect = containerRef.current.getBoundingClientRect()
        const offsetX = event.clientX - containerRect.left
        const offsetY = event.clientY - containerRect.top
        const newZoom = event.deltaY < 0 ? Math.min(zoom + 10, maxZoom) : Math.max(zoom - 10, minZoom)
        setZoom(newZoom)
        
        const scaleFactor = newZoom / zoom
        containerRef.current.scrollLeft =
          offsetX * scaleFactor - (offsetX - containerRef.current.scrollLeft)
        containerRef.current.scrollTop =
          offsetY * scaleFactor - (offsetY - containerRef.current.scrollTop)
      }
    }
  }

  const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (containerRef.current && activeContainerRef.current === containerRef.current) {
      setIsDragging(true)
      setStartX(event.clientX)
      setStartY(event.clientY)
      setStartScrollLeft(containerRef.current.scrollLeft)
      setStartScrollTop(containerRef.current.scrollTop)
      event.preventDefault()
    }
  }

  const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (isDragging && containerRef.current && activeContainerRef.current === containerRef.current) {
      const deltaX = event.clientX - startX
      const deltaY = event.clientY - startY
      
      containerRef.current.scrollLeft = startScrollLeft - deltaX
      containerRef.current.scrollTop = startScrollTop - deltaY
    }
  }

  const handleMouseUp: React.MouseEventHandler<HTMLDivElement> = () => {
    setIsDragging(false)
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    const step = 30 // Amount to move in pixels
    if (activeContainerRef.current && activeContainerRef.current === containerRef.current) {
      if (event.ctrlKey ||event.metaKey) {
        if (event.key === '+' || event.key === '=') {
          setZoom((prevZoom) => {
            const newZoom = Math.min(prevZoom + 10, maxZoom)
            const containerRect = activeContainerRef.current!.getBoundingClientRect()
            const offsetX = containerRect.width / 2
            const offsetY = containerRect.height / 2
            const scaleFactor = newZoom / prevZoom
            activeContainerRef.current!.scrollLeft =
              offsetX * scaleFactor - (offsetX - activeContainerRef.current!.scrollLeft)
            activeContainerRef.current!.scrollTop =
              offsetY * scaleFactor - (offsetY - activeContainerRef.current!.scrollTop)
            return newZoom
          })
        } else if (event.key === '-') {
          setZoom((prevZoom) => {
            const newZoom = Math.max(prevZoom - 10, minZoom)
            const containerRect = activeContainerRef.current!.getBoundingClientRect()
            const offsetX = containerRect.width / 2
            const offsetY = containerRect.height / 2
            
            const scaleFactor = newZoom / prevZoom
            activeContainerRef.current!.scrollLeft =
              offsetX * scaleFactor - (offsetX - activeContainerRef.current!.scrollLeft)
            activeContainerRef.current!.scrollTop =
              offsetY * scaleFactor - (offsetY - activeContainerRef.current!.scrollTop)
            
            return newZoom
          })
        }
      } else {
        switch (event.key) {
          case 'ArrowUp':
            activeContainerRef.current.scrollTop -= step
            break
          case 'ArrowDown':
            activeContainerRef.current.scrollTop += step
            break
          case 'ArrowLeft':
            activeContainerRef.current.scrollLeft -= step
            break
          case 'ArrowRight':
            activeContainerRef.current.scrollLeft += step
            break
          default:
            break
        }
      }
    }
  }

  const handleMouseEnter: React.MouseEventHandler<HTMLDivElement> = () => {
    activeContainerRef.current = containerRef.current
  }

  const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = () => {
    if (activeContainerRef.current === containerRef.current) {
      activeContainerRef.current = null
    }
  }

  const handleTouchStart: React.TouchEventHandler<HTMLDivElement> = (event) => {
    if (event.touches.length === 2) {
      const x1 = event.touches[0].clientX;
      const y1 = event.touches[0].clientY;
      const x2 = event.touches[1].clientX;
      const y2 = event.touches[1].clientY;

      setTouchStartDistance(Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2));
    }
  };

  const handleTouchMove: React.TouchEventHandler<HTMLDivElement> = (event) => {
    if (event.touches.length === 2 && touchStartDistance !== null) {
      const x1 = event.touches[0].clientX;
      const y1 = event.touches[0].clientY;
      const x2 = event.touches[1].clientX;
      const y2 = event.touches[1].clientY;

      const touchCurrentDistance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
      const scaleFactor = touchCurrentDistance / touchStartDistance;

      setZoom((prevZoom) => {
        const newZoom = Math.min(Math.max(prevZoom * scaleFactor, minZoom), maxZoom);
        setTouchStartDistance(touchCurrentDistance); // Update touch distance for continuous zooming
        return newZoom;
      });

      if (containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const centerX = (event.touches[0].clientX + event.touches[1].clientX) / 2;
        const centerY = (event.touches[0].clientY + event.touches[1].clientY) / 2;

        const offsetX = centerX - containerRect.left;
        const offsetY = centerY - containerRect.top;

        containerRef.current.scrollLeft =
          offsetX * scaleFactor - (offsetX - containerRef.current.scrollLeft);
        containerRef.current.scrollTop =
          offsetY * scaleFactor - (offsetY - containerRef.current.scrollTop);
      }
    }
  };

  const handleTouchEnd: React.TouchEventHandler<HTMLDivElement> = () => {
    setTouchStartDistance(null);
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [zoom])

  return {
    handleScroll,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleMouseEnter,
    handleMouseLeave,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    zoom,
    isDragging,
  }
}

export default useScrollAndZoom
