import React from 'react'
import './index.css'
import {useThemeMode} from '../../../../../../_metronic/partials'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {UseEvaluationCourseModule} from '../../../screen/store/main'
import {AddAudioTestResponse} from '../services'
import {showSimpleAlert} from '../../../../../commonHooks/alert'
import {QuestionAndAnswer} from '../interfaces'
import useAuth from '../../../../../api/main'
import {ButtonNextFullScreen} from '../../../utils/buttonNextFullScreen'
import useIsImportantEvaluation from '../../../hooks/useIsImportantEvaluation'
import { ButtonTryAgain } from '../../common/button-try-again/buttonTryAgain'

type Props = {
  total: number
  correct: number
  incorrect: number
  setStatus: (string) => void
  correctAnswer: QuestionAndAnswer[]
  falseAnswer: QuestionAndAnswer[]
}

const BasicVocabularyExerciseResume: React.FC<Props> = ({
  correctAnswer,
  total,
  correct,
  setStatus,
}: Props) => {
  const {mode} = useThemeMode()
  const {typeUser} = useAuth()
  const [url, setUrl] = React.useState<string>(`/media/svg/michigan-icons/face-resume/blue/100.svg`)
  const [message, setMessage] = React.useState<string>('')
  const {
    evaluationLesson,
    questionsEvaluation,
    currentTaskId,
    isLoading,
  } = UseEvaluationCourseModule()
  const isImportantQuiz = useIsImportantEvaluation()

  const handleSubmit = async () => {
    // ResetStepByExercises()
    if (typeUser === 'STUDENT' || typeUser === 'COURTESY' || typeUser === 'PLACEMENT_TEST') {
      try {
        await AddAudioTestResponse({
          exercises: questionsEvaluation?.exercise?.id ?? 'xx',
          tasks: currentTaskId ?? 'xx',
          description: '',
          isActive: true,
          // percentage: percentageExercise,
          answers: correctAnswer,
        })
      } catch (e) {
        showSimpleAlert({message: 'Error al cargar respuesta', icon: 'error'})
      }
    }
  }

  return (
    <>
      <div
        className=' card-basic-vocabulary'
        style={
          mode !== 'dark'
            ? {
                height: 420,
                background: 'white',
                position: 'relative',
              }
            : {
                height: 420,
                background: '#1e1e2d',
                border: `1px solid`,
                borderColor: '#3e94ff',
                position: 'relative',
              }
        }
      >
        
            <img
              className='image-basic-vocabulary-resume'
              src={toAbsoluteUrl(`/media/svg/michigan-icons/face-resume/blue/100.svg`)}
              alt=''
            />
            <h2
              className='champ-light title-basic-vocabulary-resume text mb-5D'
              style={{
                color: evaluationLesson?.colorPrimary ?? '#3e94ff',
              }}
            >
              Good job, keep going
            </h2>
        <div style={{
          display:'flex',
          justifyContent: isImportantQuiz? 'end' : 'space-between',
          alignItems:'center',
          marginTop:'auto',
        }}>
        {!isImportantQuiz &&
        <ButtonTryAgain onAction={()=> {setStatus('ANSWER')}}/>
          // <button
          //   type='button'
          //   disabled={isLoading}
          //   className='arrow-left-basic-vocabulary cursor-pointer'
          //   style={{position: 'absolute', border: 'none', backgroundColor: 'transparent'}}
          //   onClick={() => setStatus('ANSWER')}
          // >
          //   <img
          //     className=''
          //     height={30}
          //     src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/try_again.svg`)}
          //     alt=''
          //   />
          // </button>
        }
        <ButtonNextFullScreen onAction={handleSubmit}/>
        </div>
      </div>
    </>
  )
}

export {BasicVocabularyExerciseResume}
