/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { useThemeMode } from '../../../../../../_metronic/partials'
import { getCSSVariableValue } from '../../../../../../_metronic/assets/ts/_utils'
import ExcelDocument from '../../../../../components/common/ExcelExport/indext'
import moment, { Moment } from 'moment'
import { UseBookingDashboard } from '../../../store'
import { AppointmentTeacherUpdateStatus } from '../../../../admin/appointment/services'
import { t } from 'i18next'
import UseTranslateToBogotaTimeZone from '../../../../../hooks/useBogotaTranslateTz'
import Swal from 'sweetalert2'
import useAuth from '../../../../../api/main'

type Props = {
  startData: string;
  endData: string;
  showButtonThreshold: number;
  className: string
  chartColor: string
  chartHeight: string
  weight: number,
  leads?: any[]
  dateMasterClass?: string
  status?: string
  changeStatus: (value: string, lessonId?: string) => void
}

const DashboardResume: React.FC<Props> = ({
  leads,
  status,
  showButtonThreshold,
  startData,
  endData,
  chartColor,
  chartHeight,
  weight,
  dateMasterClass,
  changeStatus }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()
  const { itemSelected, setShowButton: setShow } = UseBookingDashboard();
  const { typeUser } = useAuth();
  const classRandom = itemSelected?.trail_class?.find(x => x.lesson?.sequence === 'RANDOM' || !x.lesson);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [topicSelected, setTopicsSelected] = useState<string>();
  
  useEffect(() => {
    const interval = setInterval(() => {
      const now: Moment = moment(UseTranslateToBogotaTimeZone().yearMonthDayHour);
      const startDate: Moment = moment(startData).subtract(showButtonThreshold, 'minutes');
      const endDate: Moment = moment(endData);
      if (now.isBetween(startDate, endDate)) {
        setShowButton(true);
        setShow(true);
      } else {
        setShowButton(false);
        setShow(false);
      }
    }, 1000);
    
    return () => clearInterval(interval);
  }, [startData, endData, showButtonThreshold]);
  
  useEffect(() => {
    if (itemSelected && itemSelected?.trail_class.length > 0) {
      const findLesson = itemSelected.trail_class.find((x) => x.lesson)
      if (findLesson && classRandom) {
        setTopicsSelected(findLesson.lesson?.id ?? undefined);
      }
    }
  }, [itemSelected, classRandom]);
  
  const handleChangeSelect = (lessonId: string) => {
    const findLesson = itemSelected?.trail_class.find((x) => x.lesson && x.lesson.id !== lessonId);
    if (findLesson) {
      Swal.fire({
        title: '¿Estas seguro?',
        html: "<p class='poppins-light'>Estos cambios son irreversibles</p>",
        icon: 'warning',
        iconColor: '#2d30e1',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        confirmButtonText: "<span class='poppins-light fs-4'>Sí!</span>",
        cancelButtonText: "<span class='poppins-light fs-4'>No</span>",
        customClass: {
          title: 'champ-light',
          confirmButton: 'confirm-button-sweet-alert',
          cancelButton: 'cancel-button-sweet-alert',
        }
      }).then((res) => {
        if (res.isConfirmed) {
          setTopicsSelected(lessonId);
        }
      })
      return
    }
    
    setTopicsSelected(lessonId);
  };
  
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }
    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, weight))
    if (chart) {
      chart.render()
    }
    
    return chart
  }
  
  const setTeacherAssistant = async () => {
    if (itemSelected && itemSelected.id) {
      await AppointmentTeacherUpdateStatus(itemSelected.id);
    }
  }
  
  useEffect(() => {
    const chart = refreshChart()
    
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode, weight])
  const theadName = [
    "ID",
    "NOMBRE",
    "EMAIL",
    "TELEFONO",
    "DESCRIPCION",
    "CODIGO",
    "CONTRATO",
    "REFERIDO",
    "ACTIVO",
    "ASISTIO",
    "MASTRICULADO",
    "ORIGEN",
    "NIVEL",
    "INTERES",
    "CREADO",
    "EDITADO"
  ]

  return (
    <div className={`card`} style={{ height: '95%' }}>
      
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='champ-light fs-3 mb-1'>Porcentaje de asistencia </span>
        </h3>
      </div>
      
      <div className='card-body d-flex flex-column'>
        <div className='flex-grow-1'>
          <div ref={chartRef} className='mixed-widget-4-chart'></div>
        </div>
        {status && <h2 className='text-center champ-light'>{t(status)}</h2>}
        <div className="d-grid gap-2">
          {/* {(showButton || typeUser === 'ADMIN') &&  */}
          <div className='row'>
            <div className='col-8'>
              <button
                className={`btn btn-${status === 'PENDING' ? "success" : "secondary"} champ-bold`}
                type="button" disabled={status === 'PENDING' ? false : true}
                onClick={() => changeStatus('IN_PROCESS')}>Start class</button>
            </div>
            <div className='col-2'>
              {itemSelected &&
                // itemSelected.status_appointment === 'IN_PROCESS' &&
                itemSelected?.activity &&
                itemSelected.activity.roadMap &&
                itemSelected.activity.toWorkshop === false &&
                itemSelected.activity.roadMap.sequence === 'STANDARD' && itemSelected?.trail_class.length > 0 &&
                <a
                  href={'/book/class?lesson=' + itemSelected.trail_class[0]?.lesson?.id + '&appointment=' + itemSelected.id + '&mode=TEACHER'}
                  target='blank'
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                >
                  <i style={{ color: 'green', fontSize: 17 }} className="fa-solid fa-book-atlas" />
                </a>
              }
            </div>
            <div className='col-2'>
              {itemSelected && itemSelected.classroom && itemSelected.classroom.type === 'VIRTUAL' &&
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    setTeacherAssistant();
                    window.open(itemSelected.classroom.url, '_blank');
                  }}
                  href={itemSelected.classroom.url}
                  target="_blank"
                  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                >
                  <i style={{ color: 'green', fontSize: 17 }} className="fas fa-link"></i>
                </a>
              }
            </div>
          </div>
          {/* } */}
          {itemSelected && itemSelected.activity && itemSelected.activity.toWorkshop === false && classRandom &&
            <select
              className={`btn btn-${(status === 'IN_PROCESS' || status === 'TO_BE_CLOSED') ? "success" : "secondary"} champ-bold`}
              // disabled={(status === 'IN_PROCESS' || status === 'TO_BE_CLOSED') ? false : true}
              onChange={(e) => handleChangeSelect(e.target.value)}
              value={topicSelected}
            >
              <option value={undefined}></option>
              {itemSelected?.activity && itemSelected?.activity?.lessons.map((x) => (
                <option value={x.id} key={x.id}>{x.name}</option>
              ))}
            </select>
          }
          <button
            className={`btn btn-${(status === 'IN_PROCESS' || status === 'TO_BE_CLOSED') ? "success" : "secondary"} champ-bold`}
            type="button"
            disabled={(status === 'IN_PROCESS' || status === 'TO_BE_CLOSED') ? false : true}
            onClick={() => changeStatus('FINISHED', topicSelected)}>Finish class</button>
        </div>
        {leads && leads?.length > 0 &&
          <ExcelDocument
            dataExcel={leads}
            filename={`Master Class Resume :${moment(dateMasterClass ?? moment()).format('DD-MM-YYYY')}-${moment()}`}
            sheetName='Leads'
            theadName={theadName}
          />
        }
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, weight: number): ApexOptions => {
  const baseColor = getCSSVariableValue('--bs-' + chartColor)
  const lightColor = getCSSVariableValue('--bs-' + chartColor + '-light')
  const labelColor = getCSSVariableValue('--bs-gray-700')
  
  return {
    series: [weight],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '65%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '30px',
            fontWeight: '700',
            offsetY: 12,
            show: true,
            formatter: function (val: number | string) {
              if (isNaN(Number(val))) {
                return '0%'
              } else {
                return `${val}%`
              }
            }
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}

export { DashboardResume }
