import React, { useEffect, useRef, useState } from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { UseBookContainerModule } from '../../store/main';
import './ColorPicker.css'
import { TopicsEbookDrawer } from '../../../../../_metronic/partials';
import { UseBookStudentModule } from '../../modules/screen/store/main';
import { CurrentPage } from '../../modules/test/store/main';
import useAuth from '../../../../api/main';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface CoordinatesType {
  color: string;
  type: "marker" | "pencil" | "text"
  content: { x1: number; y1: number; x2: number; y2: number; text?: string }[]
}

type Props = {
  onChangeStatePage: (value: boolean) => void;
  statePage: boolean;
  currentPage: 1 | 2;
  mode: "STUDENT" | "ADMIN" | "TEACHER" | "PUBLIC"
  setCurrentPage: (value: CurrentPage) => void;
};

type AddRest = 'ADD' | 'REST'

const ToolbarBook: React.FC<Props> = ({ mode, onChangeStatePage, statePage, currentPage, setCurrentPage }) => {
  const {typeUser} = useAuth()
  const transitionDelay = 150
  const [isMobile,setIsMobile] = useState<boolean>(window.innerWidth <= 990); //768 son los pixeles que usa Bootstrap para determinar que una pantalla es md o menos
  const [ctrlPressed, setCtrlPressed] = useState(false);
  const contextRef = useRef<CanvasRenderingContext2D | null>(null);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 990);
      if(window.innerWidth <= 990){
        onChangeStatePage(false)
      }
    };
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Control') {
        setCtrlPressed(true);
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Control') {
        setCtrlPressed(false);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const {
    toolsZoom,
    toolsPencil,
    toolsExercise,
    toolsTimer,
    toolsText,
    toolsFullscreen,
    setToolsZoom,
    setToolsPencil,
    setToolsExercise,
    setToolsTimer,
    setToolsText,
    setToolsFullscreen
  } = UseBookContainerModule();
  
  const { allAnswered } = UseBookStudentModule()
  
  //general settings
  const [currentColor, setCurrentColor] = React.useState('#00ffa2'); 
  //Pencil and Erasing
  const [isDrawing, setIsDrawing] = React.useState({
    pencil: false, drawing: false, open: false, erasing: false, marker: false
  });
  const [prevPosition, setPrevPosition] = React.useState({ x: 0, y: 0 });
  const [lines, setLines] = React.useState<CoordinatesType[]>([]);
  const [historyLines, setHistoryLines] = React.useState<CoordinatesType[]>([]);
  //text input states
  const [textInput, setTextInput] = React.useState('');
  const [clickPosition, setClickPosition] = React.useState<{ x: number, y: number }>();
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);

  const [isTransitioning, setIsTransitioning] = React.useState({
    zoom: false, draw: false, exercise: false, docs: false, pencil: false, open: false, eraser: false, marker: false, text: false, rotator_left: false, rotator_right: false, timer: false, fullScreen: false
  })
  const [otherButtons, setOtherButtons] = React.useState({
    rotator_left: false, rotator_right: false
  })

  function enterFullscreen() {
    const element = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if ((element as any).webkitRequestFullscreen) {
      (element as any).webkitRequestFullscreen();
    } else if ((element as any).msRequestFullscreen) {
      (element as any).msRequestFullscreen();
    } else if ((element as any).mozRequestFullScreen) {
      (element as any).mozRequestFullScreen();
    }
}

  function exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if ((document as any).webkitExitFullscreen) {
      (document as any).webkitExitFullscreen();
    } else if ((document as any).msExitFullscreen) {
      (document as any).msExitFullscreen();
    } else if ((document as any).mozCancelFullScreen) {
      (document as any).mozCancelFullScreen();
    }
  }

  function hexToRgba(hex: string, alpha: number): string {
    // Quitar el carácter '#' si esta presente
    hex = hex.replace(/^#/, '');

    if (hex.length === 3) {
      hex = hex.split('').map(x => x + x).join('');
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  
  const doesIntersect = (x1: number, y1: number, x2: number, y2: number, cx: number, cy: number, radius: number) => {
    // Simplified intersection check
    // Check if either end of the segment is within the eraser radius
    const dist1 = Math.hypot(x1 - cx, y1 - cy);
    const dist2 = Math.hypot(x2 - cx, y2 - cy);
    return dist1 <= radius || dist2 <= radius;
  };

  const handleCanvasClick = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (toolsText) {
      setClickPosition({
        x: event.clientX,
        y: event.clientY
      });
    }
  };

  const handleTextPrint = () => {
    if (clickPosition && canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      if (context) {
        const rect = canvas.getBoundingClientRect();
        const x = clickPosition.x - rect.left;
        const y = clickPosition.y - rect.top;
        context.font = '16px Arial';
        context.fillStyle = currentColor;
        context.fillText(textInput, x, y);
        
        const currentLines = lines;
        setLines((prevLines) => [
          ...prevLines,
          {
            content: [
              {
                x1: clickPosition.x - rect.left,
                y1: clickPosition.y - rect.top,
                x2: 0,
                y2: 0,
                text: textInput
              }
            ],
            color: isDrawing.marker ? hexToRgba(currentColor, 0.2) : currentColor,
            type: "text",
          },
        ]);
        setHistoryLines([
          ...currentLines,
          {
            content: [
              {
                x1: clickPosition.x - rect.left,
                y1: clickPosition.y - rect.top,
                x2: 0,
                y2: 0,
                text: textInput
              }
            ],
            color: currentColor,
            type: "text",
          },
        ]);
      }
      setTextInput('');
      setClickPosition(undefined);
      setToolsText(false);
    }
  };

  const handleToggleZoom = () => {
    setIsTransitioning(prevState => ({
      ...prevState,
      zoom: true,
    }));
    setToolsPencil(false);
    setToolsText(false);
    setToolsExercise(false);
    setTimeout(() => {
      setToolsZoom(!toolsZoom);
      setIsTransitioning(prevState => ({
        ...prevState,
        zoom: false
      }));
    }, transitionDelay);
  }

  const handleTogglePencil = () => {
    setIsTransitioning(prevState => ({
      ...prevState,
      draw: true,
    }));
    setIsDrawing({ pencil: false, drawing: false, open: false, erasing: false, marker: false });
    setToolsZoom(false)
    setToolsExercise(false);
    setToolsText(false)
    setTimeout(() => {
      setToolsPencil(!toolsPencil)
      setIsTransitioning(prevState => ({
        ...prevState,
        draw: false,
      }));
    }, transitionDelay);
  }

  const handleToggleDocs = () => {
    setIsTransitioning(prevState => ({
      ...prevState,
      docs: true,
    }));
    setTimeout(() => {
      onChangeStatePage(!statePage)
      setIsTransitioning(prevState => ({
        ...prevState,
        docs: false
      }));
    }, transitionDelay);
  }

  const handleToggleExercise = () => {
    setIsTransitioning(prevState => ({
      ...prevState,
      exercise: true,
    }));
    setToolsZoom(false);
    setToolsText(false);
    setToolsPencil(false);
    setIsDrawing(prev => ({ ...prev, open: false }));
    setTimeout(() => {
      setToolsExercise(!toolsExercise);
      setIsTransitioning(prevState => ({
        ...prevState,
        exercise: false
      }));
    }, transitionDelay);
  }

  const handleToggleText = () => {
    setIsTransitioning(prevState => ({
      ...prevState,
      text: !prevState.text,
    }));
    setTextInput("")
    setIsDrawing({ drawing: false, open: false, erasing: false, marker: false, pencil: false })
    setTimeout(() => {
      setToolsText(!toolsText)
      setIsTransitioning(prevState => ({
        ...prevState,
        text: !prevState.text,
      }));
    }, transitionDelay);
  }

  const handleToggleDrawing = () => {
    setIsTransitioning(prevState => ({
      ...prevState,
      pencil: true,
    }));
    setToolsZoom(false);
    setToolsText(false);
    setTimeout(() => {
      setIsDrawing(prevState => ({
        ...prevState,
        pencil: true,
        erasing: false,
        open: true,
        marker: false,
      }));
      setIsTransitioning(prevState => ({
        ...prevState,
        pencil: false,
      }));
    }, transitionDelay);
  };

  const handleToggleMarker = () => {
    setIsTransitioning(prevState => ({
      ...prevState,
      marker: true,
    }));
    setToolsText(false)
    setToolsZoom(false)
    setTimeout(() => {
      setIsDrawing(prev => ({
        ...prev,
        pencil: false,
        open: true,
        marker: true,
        erasing: false
      }))
      setIsTransitioning(prevState => ({
        ...prevState,
        marker: false
      }));
    }, transitionDelay);
  }

  const handleToggleErasing = () => {
    setIsTransitioning(prevState => ({
      ...prevState,
      eraser: true,
    }));
    setToolsZoom(false)
    setToolsText(false)
    setTimeout(() => {
      setIsDrawing(prev => ({
        ...prev,
        pencil: false,
        open: true,
        erasing: true,
        marker: false,
      }))
      setIsTransitioning(prevState => ({
        ...prevState,
        eraser: false
      }));
    }, transitionDelay);
  }

  const handleToggleTimer = () => {
    setIsTransitioning(prevState => ({
      ...prevState,
      timer: true,
    }));
    setTimeout(() => {
      setToolsTimer(!toolsTimer)
      setIsTransitioning(prevState => ({
        ...prevState,
        timer: false
      }));
    }, transitionDelay);
  }

  const handleMouseEnter = (buttonName: 'rotator_left' | 'rotator_right') => {
    setIsTransitioning((prevState) => ({ ...prevState, [buttonName]: true }));
    setTimeout(() => {
      setOtherButtons(prevState => ({ ...prevState, [buttonName]: true }))
      setIsTransitioning(prevState => ({
        ...prevState,
        [buttonName]: false
      }));
    }, transitionDelay);
  }

  const handleMouseLeave = (buttonName: 'rotator_left' | 'rotator_right') => {
    setIsTransitioning((prevState) => ({ ...prevState, [buttonName]: true }));
    setTimeout(() => {
      setOtherButtons(prevState => ({ ...prevState, [buttonName]: false }))
      setIsTransitioning(prevState => ({
        ...prevState,
        [buttonName]: false
      }));
    }, transitionDelay);
  }

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    setIsDrawing(prev => ({ ...prev, drawing: true }));
    setPrevPosition({ x: event.clientX, y: event.clientY });
    if (!isDrawing.drawing || !isDrawing.erasing) {
      const currentLines = lines
      setLines(prevLines => [
        ...prevLines,
        { content: [{ x1: event.clientX, y1: event.clientY, x2: event.clientX, y2: event.clientY }], color: isDrawing.marker ? hexToRgba(currentColor, 0.2) : currentColor, type: isDrawing.marker ? "marker" : "pencil", },

      ]);
      setHistoryLines([
        ...currentLines,
        { content: [{ x1: event.clientX, y1: event.clientY, x2: event.clientX, y2: event.clientY }], color: isDrawing.marker ? hexToRgba(currentColor, 0.2) : currentColor, type: isDrawing.marker ? "marker" : "pencil", },
      ]);
    }
  };

  const handleMouseUp = () => {
    setIsDrawing(prev => ({ ...prev, drawing: false }));
    
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!isDrawing.drawing || !isDrawing.open) return;
    const newColor = isDrawing.marker ? hexToRgba(currentColor, 0.2) : currentColor;
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    if (ctx && canvas) {
      const rect = canvas.getBoundingClientRect();
      const offsetX = rect.left;
      const offsetY = rect.top;
      if (isDrawing.drawing && !isDrawing.erasing) {
        ctx.globalCompositeOperation = 'source-over';
        ctx.beginPath();
        ctx.moveTo(prevPosition.x - offsetX, prevPosition.y - offsetY);
        ctx.lineTo(event.clientX - offsetX, event.clientY - offsetY);
        ctx.strokeStyle = newColor;
        ctx.lineWidth = isDrawing.marker ? 10 : 2;
        ctx.stroke();
        setLines((prevLines) => [
          ...prevLines.slice(0, -1),
          {
            content: [
              ...prevLines[prevLines.length - 1].content,
              {
                x1: prevPosition.x - offsetX,
                y1: prevPosition.y - offsetY,
                x2: event.clientX - offsetX,
                y2: event.clientY - offsetY,
              }
            ],
            color: newColor,
            type: isDrawing.marker ? "marker" : "pencil",
          },
        ]);
        setHistoryLines((prevLines) => [
          ...prevLines.slice(0, -1),
          {
            content: [
              ...prevLines[prevLines.length - 1].content,
              {
                x1: prevPosition.x - offsetX,
                y1: prevPosition.y - offsetY,
                x2: event.clientX - offsetX,
                y2: event.clientY - offsetY,
              }
            ],
            color: isDrawing.marker ? hexToRgba(currentColor, 0.2) : currentColor,
            type: isDrawing.marker ? "marker" : "pencil",
          },
        ]);
      } else if (isDrawing.erasing) {
        ctx.globalCompositeOperation = 'destination-out';
        ctx.lineWidth = 20;
        ctx.beginPath();
        ctx.arc(event.clientX - offsetX, event.clientY - offsetY, 10, 0, Math.PI * 2);
        ctx.stroke();
        setLines((prevLines) => {
          return prevLines.map(line => {
            const filteredContent = line.content.filter(segment => {
              const intersects = doesIntersect(
                segment.x1, segment.y1,
                segment.x2, segment.y2,
                event.clientX - offsetX,
                event.clientY - offsetY,
                10
              );
              return !intersects;
            });
            return { ...line, content: filteredContent };
          }).filter(line => line.content.length > 0);
        });
      }
    }
    setPrevPosition({ x: event.clientX, y: event.clientY });
  };

  const handleEraseLastLine = () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (ctx && lines.length > 0 && canvas) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      lines.slice(0, -1).forEach(line => {
        if (line.type === "text") {
          ctx.font = '16px Arial';
          ctx.fillStyle = line.color;
          ctx.fillText(line.content[0].text ?? "", line.content[0].x1, line.content[0].y1);
        } else {
          ctx.strokeStyle = line.color;
          ctx.lineWidth = line.type === 'marker' ? 10 : 2;
          line.content.forEach(item => {
            ctx.beginPath();
            ctx.moveTo(item.x1, item.y1);
            ctx.lineTo(item.x2, item.y2);
            ctx.stroke();
          });
        }
      });
      setLines(prevLines => prevLines.slice(0, -1));
    }
  };

  const handleRenewCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (ctx && historyLines.length > 0 && canvas) {
      ctx.globalCompositeOperation = 'source-over';
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      historyLines.slice(0, lines.length + 1).forEach(line => {
        if (line.type === "text") {
          ctx.font = '16px Arial';
          ctx.fillStyle = line.color;
          ctx.fillText(line.content[0].text ?? "", line.content[0].x1, line.content[0].y1);
        } else {
          ctx.beginPath();
          ctx.lineWidth = line.type === 'marker' ? 10 : 2;
          ctx.strokeStyle = line.color;
          line.content.forEach(item => {
            ctx.beginPath();
            ctx.moveTo(item.x1, item.y1);
            ctx.lineTo(item.x2, item.y2);
            ctx.stroke();
          });
        }
      });
      setLines(historyLines.slice(0, lines.length + 1));
    }
  };


  const renewCanvasAfterZoom = React.useCallback(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const parent = canvas.parentElement;
      if (parent) {
        canvas.width = parent.clientWidth;
        canvas.height = parent.clientHeight;
      }
      const ctx = canvas.getContext('2d');
      if (ctx && lines.length > 0) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        lines.forEach(line => {
          if (line.type === "text") {
            ctx.font = '16px Arial';
            ctx.fillStyle = line.color;
            ctx.fillText(line.content[0].text ?? "", line.content[0].x1, line.content[0].y1);
          } else {
            ctx.beginPath();
            ctx.strokeStyle = line.color;
            ctx.lineWidth = line.type === 'marker' ? 10 : 2;
            line.content.forEach(item => {
              ctx.beginPath();
              ctx.moveTo(item.x1, item.y1);
              ctx.lineTo(item.x2, item.y2);
              ctx.stroke();
            });
          }
        });
      }
    }
  }, [lines]);

  const getPage = (addRest: AddRest) => {
    let newPage: CurrentPage
    if (addRest === 'ADD') {
      newPage = currentPage === 2 ? 3 : 2
    } else {
      newPage = currentPage === 2 ? 1 : 0
    }
    return newPage
  }

  React.useEffect(() => {
    if (!toolsZoom) {
      renewCanvasAfterZoom()
    }
  }, [toolsZoom])

  React.useEffect(() => {
    if (statePage) {
      setCurrentPage(2)
    } else {
      setCurrentPage(1)
    }
  }, [statePage])

  React.useEffect(() => {
    const handleKey = (event: KeyboardEvent) => {
      if ((event.ctrlKey ||event.metaKey) && event.key === 'z') {
        handleEraseLastLine()
      } else if ((event.ctrlKey ||event.metaKey) && event.key === 'y') {
        handleRenewCanvas()
      }
    };
    document.addEventListener('keydown', handleKey)
    return () => {
      document.removeEventListener('keydown', handleKey)
    }
  },)

  const adjustCanvasSize = () => {
    const canvas = canvasRef.current;
    if (canvas && canvas.parentElement) {
      canvas.width = canvas.parentElement.clientWidth;
      canvas.height = canvas.parentElement.clientHeight;
    }
  };
    // Ajustar el tamaño del canvas al montarse y cuando cambie el tamaño de la ventana
    useEffect(() => {
      const canvas = canvasRef.current;
      if (canvas && canvas.parentElement) {
        canvas.width = canvas.parentElement.clientWidth;
        canvas.height = canvas.parentElement.clientHeight;
    
        const context = canvas.getContext('2d');
        if (context) {
          context.lineCap = 'round'; // Otras configuraciones de contexto
          context.strokeStyle = 'black'; // O el color que prefieras
          context.lineWidth = 5;
          contextRef.current = context;
        }
      }
    }, []);

  const white_pencil_options = {
    width: '50px',
    transition: 'opacity 0.5s',
    background :'#d9d9d9',
    border: '1px solid' , 
    borderColor:'#d9d9d9',
    padding: '0px 2px 0px 2px', 
  };   
  
  return (
  <>
    {!toolsZoom &&
      <div 
      style={
        isMobile ? {
          position: 'absolute',
          top: toolsFullscreen? 50 :105,
          left:  0,
          right:  0,
          bottom: 50,
          cursor: isDrawing.erasing ?
          'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'20\' height=\'20\'><circle cx=\'10\' cy=\'10\' r=\'8\' fill=\'none\' stroke=\'black\' stroke-width=\'2\'/></svg>") 10 10, auto'
          : toolsText? 'text' :'default'
        } : {
        position: 'absolute',
        top: toolsFullscreen? 50:120,
        left: toolsFullscreen? 0:160,
        right: toolsFullscreen? 135:296,
        bottom: toolsFullscreen? 0:20,
        cursor: isDrawing.erasing ?
        'url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width=\'20\' height=\'20\'><circle cx=\'10\' cy=\'10\' r=\'8\' fill=\'none\' stroke=\'black\' stroke-width=\'2\'/></svg>") 10 10, auto'
        : toolsText? 'text' :'default'
      }}
        onMouseDown={isDrawing.open ? handleMouseDown : undefined}
        onMouseUp={isDrawing.open ? handleMouseUp : undefined}
        onMouseMove={handleMouseMove}
        onWheel={(e)=>{
          if (ctrlPressed) {
            setToolsZoom(true)
          }
        }}
      >
        <canvas id="drawing-canvas"
          ref={canvasRef} 
          onClick={handleCanvasClick}
        />
      </div>
    }

    {clickPosition && toolsText && (
      <input
        type="text"
        value={textInput}
        onChange={(e) => setTextInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleTextPrint();
          }
        }}
        style={{
          position: 'fixed',
          top: clickPosition.y,
          left: clickPosition.x,
          fontSize: '16px',
        }}
        autoFocus
      />
    )}

    {toolsPencil && 
      <>
        {/* PC */}
        <div className={`d-none d-lg-flex ${isTransitioning.draw ? 'fade-out' : 'fade-in'}`}
          style={{
            position: 'fixed',
            top: 65,
            right: 150,
            bottom: 0,
            display: 'grid',
            gridTemplateRows: 'auto 1fr auto',
            transition: 'opacity 0.5s'
          }}
        > 
          <div className='d-flex justify-content-center align-items-center flex-column'>
          <OverlayTrigger 
            overlay={<Tooltip className='tooltip-inverse shadow'style={{background:'#C6F711'}}> 
                    <div className='fs-7 champ-bold d-flex justify-content-start  text-primary'>Editar Color</div>
                  </Tooltip>} placement='left'>
                    <div className="color-circle-container-for-drawing d-flex justify-content-center" style={{ backgroundColor: currentColor }}>
                      <input
                        type="color"
                        value={currentColor}
                        onChange={(e)=>setCurrentColor(e.target.value)}
                        className="custom-drawing-color-input"
                      />
                    </div>
            </OverlayTrigger>

            <OverlayTrigger 
            overlay={<Tooltip className='tooltip-inverse shadow'style={{background:'#C6F711'}}> 
                    <div className='fs-8 champ-bold d-flex justify-content-start text-decoration-underline text-primary'>Agregar texto</div>
                    <div className='fst-italic text-primary lh-1 text-start'>Agrega un campo de texto en cualquier parte del libro</div>
                  </Tooltip>} placement='left'>
                  
            <div className='d-flex justify-content-center'
                    onClick={() => {
                      handleToggleText()
                    }}
                  >
                    <img src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${toolsText ? "blue/text_blue" : "white/text_white"}.svg`)}
                      alt="Text"
                      style={{ width: '65px', transition: 'opacity 0.5s' }}
                      className={`cursor-pointer ${isTransitioning.text ? 'fade-out' : 'fade-in'}`}
                    />
                  </div>
            </OverlayTrigger>

            <OverlayTrigger 
            overlay={<Tooltip className='tooltip-inverse shadow'style={{background:'#C6F711'}}> 
                    <div className='fs-8 champ-bold d-flex justify-content-start text-decoration-underline text-primary'>Lapiz</div>
                    <div className='fst-italic text-primary lh-1 text-start'>Raya sobre el libro</div>
                  </Tooltip>} placement='left'>
                    <div className='d-flex justify-content-center'>
                      <img
                        src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${isDrawing.pencil ? "blue/pencil_left_blue" : "white/pencil_left_white"}.svg`)}
                        alt="Pencil"
                        style={{ width: '65px', transition: 'opacity 0.5s' }}
                        className={`cursor-pointer ${isTransitioning.pencil ? 'fade-out' : 'fade-in'}`}
                        onClick={handleToggleDrawing}
                      />
                    </div>
            </OverlayTrigger>

            <OverlayTrigger 
            overlay={<Tooltip className='tooltip-inverse shadow'style={{background:'#C6F711'}}> 
                    <div className='fs-7 champ-bold d-flex justify-content-start  text-primary'>Marcador</div>
                  </Tooltip>} placement='left'>
                    <div className='d-flex justify-content-center'>
                      <img src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${isDrawing.marker ? "blue/marker_blue" : "white/marker_white"}.svg`)}
                        alt="Marker"
                        style={{ width: '65px', transition: 'opacity 0.5s' }}
                        className={`cursor-pointer ${isTransitioning.marker ? 'fade-out' : 'fade-in'}`}
                        onClick={handleToggleMarker}
                      />
                    </div>
            </OverlayTrigger>

            <OverlayTrigger 
            overlay={<Tooltip className='tooltip-inverse shadow'style={{background:'#C6F711'}}> 
                    <div className='fs-7 champ-bold d-flex justify-content-start  text-primary'>Borrador</div>
                  </Tooltip>} placement='left'>
            <div className='d-flex justify-content-center'>
              <img src={toAbsoluteUrl(`/media/svg/michigan-icons/${isDrawing.erasing ? "tools/blue/eraser_blue" : "elements/blue/draft"}.svg`)}
                alt="Draft"
                style={{ width: '65px', transition: 'opacity 0.5s' }}
                className={`cursor-pointer ${isTransitioning.eraser ? 'fade-out' : 'fade-in'}`}
                onClick={handleToggleErasing}
              />
            </div>
                  
            </OverlayTrigger>

            <OverlayTrigger 
            overlay={<Tooltip className='tooltip-inverse shadow'style={{background:'#C6F711'}}> 
                    <div className='fs-7 champ-bold d-flex justify-content-start  text-primary'>Deshacer (CTRL+Z)</div>
                  </Tooltip>} placement='left'>
                      <div className='d-flex justify-content-center'>
                        <img src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${otherButtons.rotator_left ? "blue/rotator_left_blue" : "white/rotator_left"}.svg`)}
                          alt="Rotator left"
                          style={{ width: '65px', transition: 'opacity 0.5s' }}
                          className={`cursor-pointer ${isTransitioning.rotator_left ? 'fade-out' : 'fade-in'}`}
                          onClick={handleEraseLastLine}
                          onMouseEnter={() => handleMouseEnter('rotator_left')}
                          onMouseLeave={() => handleMouseLeave('rotator_left')}
                        />
                      </div>
            </OverlayTrigger>
            
            <OverlayTrigger 
              overlay={<Tooltip className='tooltip-inverse shadow'style={{background:'#C6F711'}}> 
                          <div className='fs-7 champ-bold d-flex justify-content-start  text-primary'>Rehacer (CTRL+Y)</div>
                      </Tooltip>} placement='left'>
                        <div className='d-flex justify-content-center'>
                          <img src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${otherButtons.rotator_right ? "blue/rotator_right_blue" : "white/rotator_right"}.svg`)}
                            alt="Rotator right"
                            style={{ width: '65px', transition: 'opacity 0.5s' }}
                            className={`cursor-pointer ${isTransitioning.rotator_right ? 'fade-out' : 'fade-in'}`}
                            onClick={handleRenewCanvas}
                            onMouseEnter={() => handleMouseEnter('rotator_right')}
                            onMouseLeave={() => handleMouseLeave('rotator_right')}
                          />
                        </div>
            </OverlayTrigger>

            <OverlayTrigger 
              overlay={<Tooltip className='tooltip-inverse shadow'style={{background:'#C6F711'}}> 
                          <div className='fs-7 champ-bold d-flex justify-content-start  text-primary'>Timer</div>
                      </Tooltip>} placement='left'>
                        <div className='d-flex justify-content-center'>
                          <img src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${toolsTimer ? "blue/timer_blue" : "white/timer_white"}.svg`)}
                            alt="Timer"
                            style={{ width: '65px', transition: 'opacity 0.5s' }}
                            className={`cursor-pointer ${isTransitioning.timer ? 'fade-out' : 'fade-in'}`}
                            onClick={() => handleToggleTimer()}
                          />
                        </div>     
            </OverlayTrigger>
          </div>
        </div>
        
        {/* Dispositivo movil , tablet  */}
        
        <div 
          className='d-flex d-lg-none'
          style={{
            position: 'fixed',
            top: 75,
            bottom: 0,
            left: 8,
            display: 'grid',
            gridTemplateRows: 'auto 1fr auto',
            transition: 'opacity 0.5s'
          }}
        > 
        <div className='d-flex justify-content-center align-items-center flex-column'>
          <div className="color-circle-container-for-drawing d-flex justify-content-center" style={{ backgroundColor: currentColor  }}>
            <input
              type="color"
              value={currentColor}
              onChange={(e)=>setCurrentColor(e.target.value)}
              className="custom-drawing-color-input"
            />
          </div>
          <div className='d-flex justify-content-center'
            onClick={() => {
              handleToggleText()
            }}
          >
            <img src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${toolsText ? "blue/text_blue": "white/text_white"}.svg`)}
              alt="Text"
              style={{width: '50px', transition: 'opacity 0.5s',background :'#d9d9d9', border: '1px solid' , borderColor:'#d9d9d9', borderRadius:'40px  40px 0px 0px',padding: '5px 5px',}}
              className={`cursor-pointer ${isTransitioning.text ? 'fade-out' : 'fade-in'}`}
            />
          </div>
          <div className='d-flex justify-content-center'>
            <img
              src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${isDrawing.pencil ? "blue/pencil_left_blue" : "white/pencil_left_white"}.svg`)}
              alt="Pencil"
              style={white_pencil_options}
              className={`cursor-pointer ${isTransitioning.pencil ? 'fade-out' : 'fade-in'}`}
              onClick={handleToggleDrawing}
            />
          </div>
          <div className='d-flex justify-content-center'>
            <img src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${isDrawing.marker ?"blue/marker_blue" :"white/marker_white"}.svg`)}
              alt="Marker"
            style={white_pencil_options}
              className={`cursor-pointer ${isTransitioning.marker ? 'fade-out' : 'fade-in'}`}
              onClick={handleToggleMarker}
            />
          </div>
          <div className='d-flex justify-content-center'>
            <img src={toAbsoluteUrl(`/media/svg/michigan-icons/${isDrawing.erasing ? "tools/blue/eraser_blue" : "elements/blue/draft"}.svg`)}
              alt="Draft"
              style={white_pencil_options}
              className={`cursor-pointer ${isTransitioning.eraser ? 'fade-out' : 'fade-in'}`}
              onClick={handleToggleErasing}
            />
          </div>
          <div className='d-flex justify-content-center'>
            <img src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${otherButtons.rotator_left? "blue/rotator_left_blue" : "white/rotator_left"}.svg`)}
              alt="Rotator left"
            style={white_pencil_options}
              className={`cursor-pointer ${isTransitioning.rotator_left ? 'fade-out' : 'fade-in'}`}
              onClick={handleEraseLastLine}
              onMouseEnter={()=>handleMouseEnter('rotator_left')}
              onMouseLeave={()=>handleMouseLeave('rotator_left')}
            />
          </div>
          <div className='d-flex justify-content-center'>
            <img src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${otherButtons.rotator_right ? "blue/rotator_right_blue":"white/rotator_right"}.svg`)}
              alt="Rotator right"
              style={white_pencil_options}
              className={`cursor-pointer ${isTransitioning.rotator_right ? 'fade-out' : 'fade-in'}`}
              onClick={handleRenewCanvas}
              onMouseEnter={()=>handleMouseEnter('rotator_right')}
              onMouseLeave={()=>handleMouseLeave('rotator_right')}
            />
          </div>
          <div className='d-flex justify-content-center'>
            <img src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${toolsTimer?"blue/timer_blue": "white/timer_white"}.svg`)}
              alt="Timer"
              style={{ width: '50px', transition: 'opacity 0.5s',background :'#d9d9d9', border: '1px solid' , borderColor:'#d9d9d9', borderRadius:'0px  0px 40px 40px',padding: '5px 5px',}}
              className={`cursor-pointer ${isTransitioning.timer ? 'fade-out' : 'fade-in'}`}
              onClick={() => handleToggleTimer()}
            />
          </div>
        </div>
        </div>
      </>
    }

    <div className='d-none d-lg-block'>
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',
          backgroundColor: '#3e94ff',
        }}>
        <div className='mx-6' style={{ marginTop: '65px' }}>
          <div className='d-flex justify-content-center mt-5'>
            <TopicsEbookDrawer
              colorCourse=''
              mode={mode}
            />
          </div>
        </div>

        <div className='d-flex justify-content-center align-items-center flex-column' style={{ overflowY: 'auto' }}>
          { typeUser !== 'STUDENT' &&
            <OverlayTrigger 
              overlay={
                <Tooltip className='tooltip-inverse'> 
                  <div className='fs-8 champ-bold d-flex justify-content-start  text-primary'>Dibujar sobre los libros</div>
                </Tooltip>
              } 
              placement='left'
            >
              <div className='d-flex justify-content-center' onClick={handleTogglePencil}>
                <img 
                  src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${toolsPencil ? "blue/pencil_blue" : "white/pencil_white"}.svg`)}
                  alt="Pencil"
                  style={{ width: '70px', transition: 'opacity 0.5s' }}
                  className={`cursor-pointer ${isTransitioning.draw ? 'fade-out' : 'fade-in'}`}
                />
              </div>
            </OverlayTrigger>
          }
          <OverlayTrigger 
            overlay={
              <Tooltip className='tooltip-inverse'> 
                <div className='fs-8 champ-bold d-flex justify-content-start text-primary'>{`Cambiar a ${statePage ? '1 página' : '2 páginas'}`}</div>
              </Tooltip>} placement='left'>
              <div className='d-flex justify-content-center mt-4'
                  onClick={handleToggleDocs}>
                    <img src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${!statePage ? "blue/docs_blue" : "white/docs_white"}.svg`)}
                      alt="Docs"
                      style={{ width: '70px', transition: 'opacity 0.5s' }}
                      className={`cursor-pointer ${isTransitioning.docs ? 'fade-out' : 'fade-in'}`}
                  />
              </div>
          </OverlayTrigger>

          {mode !== 'PUBLIC' &&
            <OverlayTrigger 
              overlay={
                <Tooltip className='tooltip-inverse'> 
                  <div className='fs-8 champ-bold d-flex justify-content-start  text-primary'>Ejercicios</div>
                </Tooltip>
              } 
              placement='left'
            >
              <div className='d-flex justify-content-center mt-4 p-0'
                onClick={handleToggleExercise}
              >
                <img
                  src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${toolsExercise ? "blue/exercises_blue" : "white/exercise_white"}.svg`)}
                  alt="Exercises"
                  style={{
                    width: '70px',
                    transition: 'opacity 0.5s',
                    borderRadius: "50%"
                  }}
                  className={`cursor-pointer ${isTransitioning.exercise ? 'fade-out' : 'fade-in'} ${(!allAnswered && !toolsExercise) ? 'breathing-white-effect' : ''}`}
                />
              </div>
            </OverlayTrigger>
          }
          
          <OverlayTrigger 
            overlay={
              <Tooltip className='tooltip-inverse'> 
                <div className='fs-8 champ-bold d-flex justify-content-start text-decoration-underline text-primary '>Pantalla completa</div>
                <div className='fst-italic text-primary lh-1 text-start'>Poner las paginas del libro en pantalla completa</div>
              </Tooltip>
            } 
            placement='left'>
            <div className='d-flex justify-content-center mt-4'
              onClick={() => {
                setToolsFullscreen(!toolsFullscreen)
                if(toolsFullscreen){
                  exitFullscreen()
                } else {
                  enterFullscreen()
                }
              }}
            >
              <img src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${toolsFullscreen ? "white/fullscreen_white"   : "blue/fullscreen_blue"}.svg`)}
                alt="Fullscreen"
                style={{ 
                  width: '68px',
                  height: '68px',
                  transition: 'opacity 0.5s',
                }}
                className={`cursor-pointer ${isTransitioning.exercise ? 'fade-out' : 'fade-in'}`}
              />
            </div>
          </OverlayTrigger>
          
          <OverlayTrigger 
            overlay={
              <Tooltip className='tooltip-inverse'> 
                <div className='fs-8 champ-bold d-flex justify-content-start text-decoration-underline text-primary '>Zoom</div>
                <div className='fst-italic text-primary lh-1 text-start'>
                  <ul>
                    <li>
                      ctrl + para hacer zoom
                    </li>
                    <li>
                      ctrl - para quitar zoom
                    </li>
                    <li>
                      ctrl + scroll para subir y bajar el zoom 
                    </li>
                    <li>
                      click o scroll presionado para moverse 
                    </li>
                    <li>
                      flechas para moverse 
                    </li>
                  </ul>
                </div>
              </Tooltip>} 
              placement='left'>
            <div className='d-flex justify-content-center mt-4'
              onClick={handleToggleZoom}>
              <img src={toAbsoluteUrl(`/media/svg/michigan-icons/${toolsZoom ? "tools/blue/search_blue" : "elements/search_white"}.svg`)}
                alt="Search"
                style={{ width: '70px', transition: 'opacity 0.5s' }}
                className={`cursor-pointer ${isTransitioning.zoom ? 'fade-out' : 'fade-in'}`}
              />
            </div>
          </OverlayTrigger>
        </div>
        <div style={{ textAlign: 'center' }}>
          <div className='d-flex justify-content-center align-items-center mb-5'>
            <button type='button' style={{ border: 'none', backgroundColor: 'transparent' }}>
              <i className="fa-solid fa-chevron-left text-white cursor-pointer"
                style={{ fontSize: '40px' }}
                onClick={() => setCurrentPage(statePage ? 0 : getPage('REST'))}
              />
            </button>
            <div className='d-flex justify-content-between'>
              <span className={`${currentPage === 1 && 'poppins-bold'} text-white mx-2`}>
                1
              </span>
              <span className='text-white'>
                -
              </span>
              <span className={`${currentPage === 2 && 'poppins-bold'} text-white mx-2`}>
                2
              </span>
            </div>
            <button
              type='button'
              style={{ border: 'none', backgroundColor: 'transparent', cursor: (mode !== 'PUBLIC' ? statePage ? !allAnswered : !allAnswered && currentPage === 2 : false) ? 'not-allowed' : 'pointer' }}
              onClick={() => setCurrentPage(statePage ? 3 : getPage('ADD'))}
              disabled={mode !== 'PUBLIC' ? statePage ? !allAnswered : !allAnswered && currentPage === 2 : false}
              className={`${(allAnswered && typeUser === 'STUDENT')? "rounded-circle breathing-white-effect": ""}`}
            >
              <i className={`fa-solid fa-angle-right text-${(statePage ? !allAnswered : !allAnswered && currentPage === 2) ? 'gray' : 'white'}`}
                style={{ fontSize: '48px' }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div className=' d-flex d-lg-none fixed-footer'>
      <div
        className='justify-content-center px-5'
        style={{
          position: 'fixed',
          right: 0,
          bottom: 0,
          width: '100%',
          height: '50px',
          display: 'flex',
          gridTemplateRows: 'auto 1fr auto',
          backgroundColor: 'rgb(62, 148, 255)',
        }}>

        <div className='d-flex justify-content-center  ' >
          {typeUser !== 'STUDENT'&&
            <div className='d-flex justify-content-center ' onClick={() => {
              setToolsPencil(!toolsPencil)
              setIsDrawing(prev => ({ ...prev, open: false }));
              setToolsZoom(false)
              setToolsText(false)
            }}>
              <img
                src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${toolsPencil ? "blue/pencil_blue" : "white/pencil_white"}.svg`)}
                alt="Pencil"
                style={{ 
                  width: '50px',
                  height: '50px',
                  transition: 'opacity 0.5s',
                  borderRadius: "50%"
                }}
                className={`cursor-pointer ${isTransitioning.draw ? 'fade-out' : 'fade-in'}`}
                />
            </div>
          }
          {mode !== 'PUBLIC' &&
            <div className='d-flex justify-content-center' onClick={() => setToolsExercise(!toolsExercise)}>
              <img 
                src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${toolsExercise ? "blue/exercises_blue" : "white/exercise_white"}.svg`)}
                alt="Exercises"
                style={{ 
                  width: '50px',
                  height: '50px',
                  borderRadius: "50%",
                  transition: 'opacity 0.5s',
                }}
                className={`cursor-pointer ${isTransitioning.exercise ? 'fade-out' : 'fade-in'}  ${(!allAnswered && !toolsExercise) ? 'breathing-white-effect' : ''}`}
              />
            </div>
          }
          {/* <div className='d-flex justify-content-center '
            onClick={() => {
              setToolsFullscreen(!toolsFullscreen)
              if(toolsFullscreen){
                exitFullscreen()
              } else {
                enterFullscreen()
              }
            }}
          >
            <img src={toAbsoluteUrl(`/media/svg/michigan-icons/tools/${toolsFullscreen ? "white/fullscreen_white"   : "blue/fullscreen_blue"}.svg`)}
              alt="Fullscreen"
              style={{ 
                width: '48px',
                height: '48px',
                transition: 'opacity 0.5s',
                marginTop: '1px'
              }}
              className={`cursor-pointer ${isTransitioning.exercise ? 'fade-out' : 'fade-in'}`}
              />
          </div> */}
      
        <div className='d-flex justify-content-center'
          onClick={() => {
            setToolsZoom(!toolsZoom)
            setToolsPencil(false)
            setToolsText(false)
          }}>
          <img  src={toAbsoluteUrl(`/media/svg/michigan-icons/${toolsZoom ? "tools/blue/search_blue" : "elements/search_white"}.svg`)}
            alt="Search"
            style={{ 
              width: '50px',
              height: '50px', 
              transition: 'opacity 0.5s' 
            }}
            className={`cursor-pointer ${isTransitioning.zoom ? 'fade-out' : 'fade-in'}`}
          />
        </div>  
        </div>
        <div className='mx-5'/>
        <div style={{ textAlign: 'center' }}>
          <div className='d-flex justify-content-center align-items-center mt-1  py-2'>
            <i className="fa-solid fa-chevron-left text-white cursor-pointer" style={{ fontSize: '30px' }} onClick={() => setCurrentPage(statePage ? 0 : getPage('REST'))}></i>
            <div className='d-flex justify-content-between'>
              <span className={`${currentPage === 1 && 'poppins-bold'} text-white mx-2`}>
                1
              </span>
              <span className='text-white'>
                -
              </span>
              <span className={`${currentPage === 2 && 'poppins-bold'} text-white mx-2`}>
                2
              </span>
            </div>
            <button
              type='button'
              style={{ border: 'none', backgroundColor: 'transparent', cursor: (statePage ? !allAnswered : !allAnswered && currentPage === 2) ? 'not-allowed' : 'pointer' }}
              onClick={() => setCurrentPage(statePage ? 3 : getPage('ADD'))}
              disabled={statePage ? !allAnswered : !allAnswered && currentPage === 2}
              className={`${(allAnswered && typeUser === 'STUDENT')? "rounded-circle breathing-white-effect": ""}`}
            >
              <i className={`fa-solid fa-angle-right text-${(mode !== 'PUBLIC' ? statePage ? !allAnswered : !allAnswered && currentPage === 2 : false) ? 'gray' : 'white'}`}
                style={{ fontSize: '33px' }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </>)
};

export { ToolbarBook };