/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import * as Yup from 'yup'
import styled from "styled-components";
import clsx from 'clsx'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import './Login.css';
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import useAuth from '../../../api/main';
import { logoAppSelector, logoAppSelectorUrl } from '../../../hooks/useAppSelectorAssetsMode'

const mode = process.env.REACT_APP_THEME_DEMO;

let url = logoAppSelectorUrl();
const loginSchema = Yup.object().shape({
  email: Yup.string()
    // .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Field is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: 'admin@demo.com',
  password: 'demo',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function LoginPlatform() {
  const { login, loading, setLoading } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();

  const [buttonStyles, setButtonStyles] = useState({
    backgroundColor: '#1bebd4',
    color: '#2d30e1',
  });

  const [buttonStylesRegister, setButtonStylesRegister] = useState({
    backgroundColor: '#FFFFFF',
    color: '#2d30e1',
  });

  const [buttonStylesInfo, setButtonStylesInfo] = useState({
    backgroundColor: '#1bebd4',
    color: '#2d30e1',
  })

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      // .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Field is required'),
    password: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
  })

  const initialValues = {
    email: '',
    password: '',
  }


  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        await login(values.email.toLowerCase(), values.password);
        // navigate('/dashboard')
        //login(auth)
        // const {data: user} = await getUserByToken(auth.api_token)
        //  setCurrentUser(user)
      } catch (error) {
        // console.error(error)
        //(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
      }
    },
  });

  const loginByUrl = React.useCallback(async() => {
    const searchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(searchParams.entries());
    if (params && params?.e && params?.p) {
      await login(params?.e.toLowerCase(), params?.p)
    }
  }, [location]);

  React.useEffect(() => {
    loginByUrl();
  }, [location]);

  React.useEffect(() => {
    window.addEventListener('beforeunload', () => setLoading(false));
    return () => {
      window.removeEventListener('beforeunload', () => setLoading(false));
    }
  }, []);

  return (
    <div className=' scroll-y overflow-y-auto d-flex justify-content-center align-items-center'
      style={{
        overflowY: 'hidden',
        minHeight: '100vh',
        height: '100%',
      }}>
      <img
        src={
          mode === 'SALES' ? 'https://dkumy02vmzh93.cloudfront.net/banner_min_2.jpg' : 
          toAbsoluteUrl('/media/svg/michigan-icons/image_login_min.png')
        }
        alt="Background"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: -1,
        }}
      />
      {/* PC */}

      <div className='d-none d-lg-flex justify-content-center' >

        <div className='col-lg-4 col-md-4 mt-10 '>
          <div className='mb-10 ms-2 col-12'>
            <img
              src={toAbsoluteUrl(logoAppSelector('LIGHT'))}
              alt=""
              className='img-fluid title'
              style={{ width: '22%' }}
            />
          </div>
          <div className='mb-10 col-12'>
            <img
              src={toAbsoluteUrl('/media/svg/card-logos/TITULO_blanco.svg')}
              alt=""
              className='img-fluid '
              style={{ width: '360px' }}
            />
          </div>
          <div className='ms-2 col-12' style={{ marginTop: '60px' }}>
            <a href={url.www} className='poppins-light text-decoration-none text-white'>
              {url.www}
            </a>
          </div>
        </div>

        <div className='col-lg-4 col-md-4 d-flex align-items-end ' style={{ marginBottom: '150px' }}>
          <div className=' ' >
          {mode === 'SALES' && 
            <a href={'auth/sign-in'} className='btn mb-2 ms-4'
              style={{
                borderRadius: 30,
                transition: 'background-color 0.3s, color 0.3s',
                ...buttonStylesRegister
              }}
              onMouseOver={() => {
                setButtonStylesRegister({
                  backgroundColor: '#2d30e1',
                  color: '#ffffff',
                });
              }}
              onMouseOut={() => {
                setButtonStylesRegister({
                  backgroundColor: '#FFFFFF',
                  color: '#2d30e1',
                });
              }}
            >
              <span className='p-0 py-0 fs-1 poppins-light d-flex justify-content-center align-items-center'>
                Regístrate
                <div className='symbol symbol-30px ms-2'>
                  <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/${buttonStylesRegister.color === '#ffffff' ? 'register_white' : 'register_blue'}.svg`)} alt="" />
                </div>
              </span>
            </a>}
            
            <a href={url.info} className='btn ms-4'
              style={{
                borderRadius: 30,
                transition: 'background-color 0.3s, color 0.3s',
                ...buttonStylesInfo
              }}
              onMouseOver={() => {
                setButtonStylesInfo({
                  backgroundColor: '#2d30e1',
                  color: '#ffffff',
                });
              }}
              onMouseOut={() => {
                setButtonStylesInfo({
                  backgroundColor: '#1bebd4',
                  color: '#2d30e1',
                });
              }}
            >
              <span className='p-2 py-0 fs-1 poppins-light d-flex justify-content-center align-items-center'>
                Más info
                <div className='symbol symbol-30px ms-2'>
                  <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/${buttonStylesInfo.color === '#ffffff' ? 'info_white' : 'info_blue'}.svg`)} alt="" />
                </div>
              </span>
            </a>
          </div>
        </div>

        <div className='col-lg-3 ml-10 ms-4 col-md-4 d-flex flex-column justify-content-center align-items-center'>
          <div className='col-12  justify-content-center align-items-center '>
            <form
              className='form w-100 row ml-10 justify-content-center align-items-center'
              onSubmit={formik.handleSubmit}
              style={{
                backgroundColor: '#1bebd4',
                borderRadius: '50px',
                background: 'linear-gradient(to bottom, #1bebd4 70%, rgba(27, 235, 212, 0.6))',
                margin: '20px 0',
                width: '300px',
                minHeight: '567px',
              }}
              noValidate
              id='kt_login_signin_form'
            >
              <div className='col-12  pt-10 '>
                <div className='d-flex justify-content-center align-items-center'>
                  <img
                    src={toAbsoluteUrl('/media/svg/michigan-icons/get_ready.svg')}
                    alt=""
                    className='img-fluid'
                    style={{ width: '70%' }}
                  />
                </div>
              </div>

              <div className='col-12  p-10 pt-0 '>
                <div className='col-12  form-controll mb-3'>
                  <input
                    required
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      ' bg-transparent',
                      { 'is-invalid': formik.touched.email && formik.errors.email },
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                    type='text'
                    name='email'
                    autoComplete='email'
                  />
                  <label>
                    <span className='poppins-bold' style={{ transitionDelay: '50ms' }}>Correo</span>
                  </label>
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  )}
                </div>

                <div className='col-12 form-controll mb-2'>
                  <input
                    required
                    type={showPassword ? 'text' : 'password'}
                    autoComplete='new-password'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      ' bg-transparent',
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  <label>
                    <span className='poppins-bold' style={{ transitionDelay: '50ms' }}>Password</span>
                  </label>
                  <button
                    type='button'
                    className='position-absolute end-0 top-50 translate-middle-y border-0 bg-transparent'
                    style={{ backgroundColor: 'transparent' }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <i className="fa-solid fa-eye-slash" style={{ color: '#2b22e5' }}></i> : <i className="fa-solid fa-eye " style={{ color: '#2b22e5' }}></i>}
                  </button>
                </div>

                {/* <div className="input-field">
                  <input required name="text" type="text" />
                  <label>Enter your email</label>
                  </div> */}

                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}


              </div>
              <div className='col-12 mb-5 d-flex justify-content-center align-items-center'>
                <button
                  type='submit'
                  id='kt_sign_in_submit'
                  className='btn w-100 mx-4'
                  disabled={formik.isSubmitting || !formik.isValid || loading}
                  style={{
                    borderRadius: 30,
                    border: '1px solid #2d30e1',
                    transition: 'background-color 0.3s, color 0.3s',
                    ...buttonStyles,
                  }}
                  onMouseOver={() => {
                    setButtonStyles({
                      backgroundColor: '#2d30e1',
                      color: '#ffffff',
                    });
                  }}
                  onMouseOut={() => {
                    setButtonStyles({
                      backgroundColor: '#1bebd4',
                      color: '#2d30e1',
                    });
                  }}
                >
                  {!loading && <span className='indicator-label poppins-light fs-1'>Entrar</span>}
                  {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              <div className='col-12 d-flex justify-content-center align-items-center pb-10'>
                <a href='/auth/forgot-password' className='text-decoration-none poppins-light' style={{ color: '#2d30e1' }}>Olvidé mi contraseña</a>
              </div>
            </form>
          </div>

          <div className='col-12  d-flex justify-content-center align-items-center'>
            <a href={url.facebook} className='text-decoration-none'>
              <i className="fa-brands fa-facebook-f text-white" style={{ fontSize: 32 }}></i>
            </a>
            <a href={url.instagram} className='text-decoration-none'>
              <i className="fa-brands fa-instagram text-white ms-9 cursor-pointer" style={{ fontSize: 32 }}></i>
            </a>
            <a href={url.youtube} className='text-decoration-none'>
              <i className="fa-brands fa-youtube text-white ms-9" style={{ fontSize: 32 }}></i>
            </a>
            <a href={url.in} className='text-decoration-none'>
              <i className="fa-brands fa-linkedin-in text-white ms-9" style={{ fontSize: 32 }}></i>
            </a>
            <a href={url.tiktok} className='text-decoration-none'>
              <i className="fa-brands fa-tiktok text-white ms-9" style={{ fontSize: 32 }}></i>
            </a>
          </div>
        </div>
      </div>


      {/* Movil */}
      <div className='d-lg-none d-flex flex-column container-sm'>
        {/* Logo movil */}
        <div className='d-md-none d-block d-sm-none d-flex justify-content-center align-items-center'>
          <img
            src={toAbsoluteUrl(logoAppSelector('LIGHT'))}
            alt=""
            className='img-fluid title'
            style={{ width: '25%' }}
          />
        </div>

        {/* logo tablet */}
        <div className='d-none d-sm-flex d-none .d-sm-block mt-17 d-flex justify-content-center align-items-center'>
          <img
            src={toAbsoluteUrl(logoAppSelector('LIGHT'))}
            alt=""
            className='img-fluid title'
            style={{ width: '90px' }}
          />
        </div>


        <div className='d-flex justify-content-center align-items-center'>
          <form
            className='form  row  justify-content-center align-items-center '
            onSubmit={formik.handleSubmit}
            style={{
              width: '300px',
              height: '385px',
              backgroundColor: '#1bebd4',
              borderRadius: '50px',
              background: 'linear-gradient(to bottom, #1bebd4 70%, rgba(27, 235, 212, 0.6))',
              margin: '20px 0',
            }}
            noValidate
            id='kt_login_signin_form'
          >
            <div className='col-12 mb-1 pt-10'>
              <div className='d-flex justify-content-center align-items-center'>
                <img
                  src={toAbsoluteUrl('/media/svg/michigan-icons/get_ready.svg')}
                  alt=""
                  className='img-fluid'
                  style={{ width: '70%' }}
                />
              </div>
            </div>

            <div className='col-12  px-6 '>
              <div className='col-12  form-controll mb-3'>
                <input
                  required
                  {...formik.getFieldProps('email')}
                  className={clsx(
                    ' bg-transparent ',
                    { 'is-invalid': formik.touched.email && formik.errors.email },
                    {
                      'is-valid': formik.touched.email && !formik.errors.email,
                    }
                  )}
                  type='text'
                  name='email'
                  autoComplete='email'
                />
                <label>
                  <span className='poppins-bold' style={{ transitionDelay: '0ms' }}>Correo</span>
                </label>
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <span role='alert'>{formik.errors.email}</span>
                  </div>
                )}
              </div>

              <div className='col-12 form-controll mb-2'>
                <input
                  required
                  type={showPassword ? 'text' : 'password'}
                  autoComplete='new-password'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    ' bg-transparent',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                <label >
                  <span className='poppins-bold' style={{ transitionDelay: '50ms' }}>Password</span>
                </label>

                <button
                  type='button'
                  className='position-absolute end-0 top-50 translate-middle-y border-0 bg-transparent'
                  style={{ backgroundColor: 'transparent' }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <i className="fa-solid fa-eye-slash" style={{ color: '#2b22e5' }}></i> : <i className="fa-solid fa-eye " style={{ color: '#2b22e5' }}></i>}
                </button>

              </div>
              {/* <div className="input-field">
                  <input required name="text" type="text" />
                  <label>Enter your email</label>
                  </div> */}

              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}


            </div>

            <div className='col-12 mt-4 mb-1 d-flex justify-content-center align-items-center'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn w-100 mx-4'
                disabled={formik.isSubmitting || !formik.isValid || loading}
                style={{
                  borderRadius: 30,
                  border: '1px solid #2d30e1',
                  transition: 'background-color 0.3s, color 0.3s',
                  ...buttonStyles,
                }}
                onMouseOver={() => {
                  setButtonStyles({
                    backgroundColor: '#2d30e1',
                    color: '#ffffff',
                  });
                }}
                onMouseOut={() => {
                  setButtonStyles({
                    backgroundColor: '#1bebd4',
                    color: '#2d30e1',
                  });
                }}
              >
                {!loading && <span className='indicator-label poppins-light fs-1 '>Entrar</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>

            <div className='col-12 mt-2 d-flex justify-content-center align-items-center pb-7'>
              <a href='/auth/forgot-password' className='text-decoration-none poppins-light' style={{ color: '#2d30e1' }}><p className="text-decoration-underline">Olvidé mi contraseña</p></a>
            </div>
          </form>
        </div>

        {/*boton movil  */}
        <div className='d-md-none d-flex align-items-center d-block d-sm-none justify-content-center  '>
        {mode === 'SALES' && 
          <a href='/auth/sign-in' className='btn p-6'
            style={{

              borderRadius: 30,
              transition: 'background-color 0.3s, color 0.3s',
              ...buttonStylesRegister
            }}
            onMouseOver={() => {
              setButtonStylesRegister({
                backgroundColor: '#2d30e1',
                color: '#ffffff',
              });
            }}
            onMouseOut={() => {
              setButtonStylesRegister({
                backgroundColor: '#FFFFFF',
                color: '#2d30e1',
              });
            }}
          >
            <span className=' fs-1 poppins-light'>
              Regístrate
              <i className='fa-regular fa-circle-right ms-2 fs-1' style={{ color: buttonStylesRegister.color }}></i>
            </span>
          </a>}

          <a href='#' className='btn ms-4 p-6'
            style={{
              borderRadius: 30,
              transition: 'background-color 0.3s, color 0.3s',
              ...buttonStylesInfo
            }}
            onMouseOver={() => {
              setButtonStylesInfo({
                backgroundColor: '#2d30e1',
                color: '#ffffff',
              });
            }}
            onMouseOut={() => {
              setButtonStylesInfo({
                backgroundColor: '#1bebd4',
                color: '#2d30e1',
              });
            }}
          >
            <span className=' fs-1 poppins-light'>
              Más info
              <i className='fa-solid fa-play ms-3 fs-1' style={{ color: buttonStylesInfo.color }}></i>
            </span>
          </a>
        </div>

        {/*boton tablet */}
        <div className='d-none d-sm-flex align-items-center d-none .d-sm-block justify-content-center mt-8'>
          {mode === 'SALES' && 
            <a href='/auth/sign-in' className='btn'
              style={{

                borderRadius: 30,
                transition: 'background-color 0.3s, color 0.3s',
                ...buttonStylesRegister
              }}
              onMouseOver={() => {
                setButtonStylesRegister({
                  backgroundColor: '#2d30e1',
                  color: '#ffffff',
                });
              }}
              onMouseOut={() => {
                setButtonStylesRegister({
                  backgroundColor: '#FFFFFF',
                  color: '#2d30e1',
                });
              }}
            >
              <span className='p-2 fs-1 poppins-light'>
                Regístrate
                <i className='fa-regular fa-circle-right ms-5 fs-1' style={{ color: buttonStylesRegister.color }}></i>
              </span>
            </a>
          }
          <a href='#' className='btn ms-4'
            style={{
              borderRadius: 30,
              transition: 'background-color 0.3s, color 0.3s',
              ...buttonStylesInfo
            }}
            onMouseOver={() => {
              setButtonStylesInfo({
                backgroundColor: '#2d30e1',
                color: '#ffffff',
              });
            }}
            onMouseOut={() => {
              setButtonStylesInfo({
                backgroundColor: '#1bebd4',
                color: '#2d30e1',
              });
            }}
          >
            <span className='p-2 fs-1 poppins-light'>
              Más info
              <i className='fa-solid fa-play ms-3 fs-1' style={{ color: buttonStylesInfo.color }}></i>
            </span>
          </a>
        </div>

        <div className='mt-7 d-flex justify-content-center align-items-center'>
          <a href="https://www.facebook.com/michiganmaster" className='text-decoration-none'>
            <i className="fa-brands fa-facebook-f text-white" style={{ fontSize: 20 }}></i>
          </a>
          <a href="https://www.instagram.com/michiganmastersas/" className='text-decoration-none'>
            <i className="fa-brands fa-instagram text-white ms-9 cursor-pointer" style={{ fontSize: 20 }}></i>
          </a>
          <a href="https://www.youtube.com/c/MichiganMasterColombia" className='text-decoration-none'>
            <i className="fa-brands fa-youtube text-white ms-9" style={{ fontSize: 20 }}></i>
          </a>
          <a href="#" className='text-decoration-none'>
            <i className="fa-brands fa-linkedin-in text-white ms-9" style={{ fontSize: 20 }}></i>
          </a>
          <a href="https://tiktok.com/@michiganmasterco" className='text-decoration-none'>
            <i className="fa-brands fa-tiktok text-white ms-9" style={{ fontSize: 20 }}></i>
          </a>
        </div>

        <div className='mt-10 d-flex justify-content-center align-items-center'>
          <a href='https://www.michiganmaster.edu.co/' className='poppins-light text-decoration-none text-white'>
            www.michiganmaster.edu.co
          </a>
        </div>
      </div>
    </div>

  )
}
