import React, { useEffect } from 'react';
import clsx from 'clsx';
import { LinkComponent } from '../LinkComponent';
import { LinkComponentTwo } from '../LinkComponent/index_two';
import { NexLessonModal } from './modal';
import useTrailTask from '../student/store';
import { UseMyRoadmapModule } from '../../../profile/components/my-road-step/store';
import { SequenceType } from '../../../abroad/road-map-step/interfaces';

type Props = {};

const btnClass = 'btn btn-icon btn-active-light-primary w-50px h-50px w-md-60px h-md-60px';

const FloatingButton: React.FC<Props> = () => {
  const { modal, setModal } = useTrailTask();
  const { settings } = UseMyRoadmapModule.getState();
  const [isMovil, setisMovil] = React.useState<boolean>(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setisMovil(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }

  }, []);

  return (
    <>
      {settings && settings.typeStep === SequenceType.STANDARD && <div
        className='d-block'
        style={{
          marginTop: isMovil ? 67 : 0,
          marginLeft: isMovil ? 80 : -2,
          width:  isMovil ? 60 : 85,
          height: isMovil ? 60 : 85,
          borderRadius: isMovil ? '100%' : '0%',
          overflow : isMovil ? 'hidden' : 'visible',/**desbordamiento no visible en  ordenadores */
        }}>
        {/* begin::Drawer toggle */}

        <LinkComponentTwo
          url='#'
          colorTitle='#2d30e1'
          title=' '
          background='#1cebcc'
          className=' container-link-component-item py-2'
          icon='/media/svg/michigan-icons/header/blue/roadmap_blue.svg'

        />

        {/* end::Drawer toggle */}
      </div>}

      <NexLessonModal show={modal} />
    </>
  );
};

export { FloatingButton };