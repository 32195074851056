import React, { useEffect, useState } from 'react';
import './index.css';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import HeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/HeaderCard';
import { QuestionTrueOptionsIE } from '../interfaces';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import { AddTrueOptionsResponse } from '../services';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { ButtonTryAgain } from '../../common/button-try-again/buttonTryAgain';
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise';
import { UseTrueOptionsModule } from '../store';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
import { UseBookContainerModule } from '../../../../book/store/main';


type Props = {
  userMode?: ModeTypeExercise
  responses?: Answer[];
  question: QuestionTrueOptionsIE
  instruction: string,
};
interface Answer {
  title: string;
  sentences: string;
  order: number
  letter: string;
}
const divStyle = {
  maxWidth: '525px',
  backgroundColor: '#3f8bff', borderRadius: '30px',
  borderColor: 'rgb(255, 255, 255)', borderStyle: 'solid',
  padding: '27px 20px 20px 20px'
};
const inputStyle: React.CSSProperties = {
  display: 'flex', width: '32px',
  height: '16px', marginRight: '10px', borderRadius: '40px',
  borderWidth: '0px', borderStyle: 'solid', borderColor: '#ffffff',
  textAlign: 'center', backgroundColor: '#d0ff00', outline: 'none', marginBottom: '0',
  marginTop: '1px'
};

const RightConnectorItem: React.FC<Props> = ({
  userMode,
  question,
  responses,
  instruction,
}: Props) => {

  const {
    lessonData,
    stepBookItem,
    currentTaskId,
    setStepBookItem,
    addCompletedExerciseId,
    onChangeTaskByAnswer,
    setIsLoadingSubmit
  } = UseBookStudentModule();

  const {exerciseId} = UseTrueOptionsModule();
  const {modeViewAnswer} = UseModeCourseModule();
  const {showButtons} = UseBookContainerModule();
  const [allAnswered, setAllAnswered] = useState<boolean>(false);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [percentageCorrect, setPercentageCorrect] = useState<number | null>(null);

  const handleSubmit = async () => {
    if (userMode === 'STUDENT' && currentTaskId && exerciseId) {
      try {
        setIsLoadingSubmit(true)
        const response = await AddTrueOptionsResponse({
          task: currentTaskId,
          exercises: exerciseId ?? '',
          percentage: percentageCorrect ? percentageCorrect : 0,
          isActive: true,
          answers: answers,
        });
        onChangeTaskByAnswer(response, 'true_option');
      } catch (e) {
        showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' });
      } finally {
        setIsLoadingSubmit(false)
      }
    }
    if (lessonData && lessonData.evaluation &&
    lessonData.evaluation.evaluation_item){
      addCompletedExerciseId(exerciseId ?? "")
      if(lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
        setStepBookItem(exerciseId??'')
      } else {
        setStepBookItem('FINISH_LESSON')
      }
    } 
  };
  const tryAgain = () => {
    setAllAnswered(false)
    const initialAnswers = question.options.map((x) => ({
      title: x.title,
      sentences: '',
      order: x.order,
      letter: '',
    }));
    setAnswers(initialAnswers);
  }
  useEffect(() => {
    if (responses && responses.length > 0) {
      setAnswers(responses);
    } else {
      // Inicializa las respuestas cuando el componente se monta o cuando las preguntas cambian
      const initialAnswers = question.options.map((x) => ({
        title: x.title,
        sentences: '',
        order: x.order,
        letter: modeViewAnswer ? x.letter : '',
      }));
      setAnswers(initialAnswers);
    }
  }, [question, responses, modeViewAnswer]); // Asegúrate de reaccionar a cambios en las preguntas

  const handleInputChange = (order: number, value: string, letter: string) => {
    // Actualiza las respuestas basándose en el input del usuario
    const updatedAnswers = answers.map((answer) =>
      answer.order === order ? { ...answer, sentences: value, letter: letter } : answer
    );
    setAnswers(updatedAnswers);
  };

  useEffect(() => {
    // Comprueba si todas las preguntas han sido respondidas
    const allAnsweredCheck = answers.every((answer) => answer.letter !== '');
    setAllAnswered(allAnsweredCheck);

    if (allAnsweredCheck) {
      // Si todas están respondidas, calcula el porcentaje de correctas
      let correctCount = 0;
      answers.forEach((answer, index) => {
        if (question.options[index].letter.trim().toLowerCase() === answer.letter.trim().toLocaleLowerCase() && question.options[index].order === answer.order) {
          correctCount += 1;
        }
      });
      setPercentageCorrect((correctCount / question.options.length) * 100);
    } else {
      // Si no, asegúrate de que el porcentaje de correctas esté en null
      setPercentageCorrect(null);
    }
  }, [answers]); // Reacciona a cambios en las respuestas


  return (
    <>

      <div className="container-fluid" style={{ display: 'flex' }}>
        <div style={divStyle}>
          <HeaderCard />
          <p className=" text-white  mb-0 poppins-light fs-9 " >
            {instruction}
          </p>
          <span className='poppins-bold mb-0 poppins-light fs-9 my-0 text-white'>Connectors: due to - hence - because - therefore</span>
          <div className="row px-2">
            <div className="col-6  ">
              <div className="col p-2 rounded-4 border border-2 border-white poppins-bold text-white fs-8">
                {question.options.map((x, index) => (
                  <p style={{ fontSize: 9 }} className='text-white  poppins-light mb-0 my-0'>
                    <span className='poppins-bold'>{question.sentences[index].letter}.</span> {x.title ?? ''}
                  </p>
                ))}
              </div>
              {/* <div className="row  p-2 ">
                {question.options.map((x, index) => ( 
                    <div className='col-2 fs-8 text-center text-white' style={{ width: '55px', height: '50px', marginTop: '13px' }} >
                        <div className="rounded-circle border border-1 border-white" style={{ width: '45px', height: '45px' }} >
                            <img src={x.title} className="rounded" style={{ width: '40px', height: '40px' }}  alt="Cinque Terre" />
                        </div>
                        <div className=''>
                            <span className=' align-text-bottom ' >{x.letter}</span>
                        </div>
                    </div>
                ))}
            </div> */}
            </div>
            <div className="col-6 p">
              <div className="col text-left rounded-4 bg-white border border-2 border-white poppins-bold text-white fs-8  p-2">
                {question.options.map((x, index) => (
                  <p style={{ fontSize: 9, color: '#3f8bff' }} className=' poppins-light mb-0 my-0'>
                    <i style={{ color: '#3f8bff', fontSize: 9 }} className="bi bi-dot"></i> {question.sentences[index].title ?? ''}
                    {/* <input  
                        style={{ width: '34px', borderRadius: '50px', border: '0px', outline: 'none', background: '#D0FF00' }} className='mb-0'
                        type="text"
                        value={answers[index]?.letter || ''}
                        onChange={(e) => handleInputChange(x.order,question.sentences[index].title??'',e.target.value)}
                    /> */}
                  </p>
                ))}
              </div>
              <div className="p-2">
              </div>
            </div>
            <div className="col-12 p">
              <div className="col text-left rounded-4 bg-white border border-2 border-white poppins-bold text-white fs-8  p-2">
                {question.options.map((x, index) => (
                  <p style={{ fontSize: 9, color: '#3f8bff' }} className=' poppins-light mb-0 my-0'>
                    <i style={{ color: '#3f8bff', fontSize: 9 }} className="bi bi-dot"></i> {question.sentences[index].letter ?? ''}
                    <select
                      style={{ color: '#3f8bff', fontSize: 9, border: 0 }}

                      value={answers[index]?.letter || ''}
                      onChange={(e) => handleInputChange(x.order, question.sentences[index].title ?? '', e.target.value)}
                      className="">
                      <option value={''}> </option>
                      {question.sentences.map((x) => (<option value={x.letter}>{x.title}</option>))}

                    </select>

                  </p>
                ))}
              </div>
              <div className="p-2">
              </div>
            </div>
          </div>
          {allAnswered && showButtons && 
          <>
            <div className='d-flex justify-content-between align-items-center'>
            <ButtonTryAgain onAction={tryAgain} />
            <div>
              <h3 className='text-center' style={{ fontSize: 10 }}> <span className=" text-white  mb-0 poppins-light">Porcentaje correcto: {percentageCorrect && percentageCorrect.toFixed(2)}%</span></h3>
            </div>
            <ButtonSaveExercise 
              isChecked={allAnswered}
              onNext={()=>{}}
              onSubmit={handleSubmit}
              exerciseId={exerciseId}
              />
          </div>
            </>
          }
        </div>

      </div>
    </>
  );
};

export { RightConnectorItem };