/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../../../_metronic/helpers'
import useTrailTask, { NextLesson } from '../student/store'
import { useThemeMode } from '../../../../../_metronic/partials'
import { t } from 'i18next'
import { UseMyRoadmapModule } from '../../../profile/components/my-road-step/store'
import CustomButton from '../../../../components/common/custom-button'
import Swal from 'sweetalert2'
import { LessonAdjustmentAdd } from '../../../admin/lesson-adjustment/services'


type Props = {
  show: boolean
  handleClose?: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const NexLessonModal = ({ show, handleClose }: Props) => {
  const { mode: _mode } = useThemeMode();
  const { settings } = UseMyRoadmapModule();
  const { modal, setModal, data, nextLesson, appointment, studentSetting, mode } = useTrailTask();
  const [isMovil, setisMovil] = React.useState<boolean>(window.innerWidth < 768);
  const submit = () => {
    window.location.reload()
  }

  const sendAdjustment = () => {
    Swal.fire({
      title: '¿Deseas enviar solicitud?',
      html: "<p class='poppins-light'>El estudiante debe aceptar</p>",
      icon: 'warning',
      showCancelButton: true,
      iconColor: '#2d30e1',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "<span class='poppins-light fs-4'>Sí!</span>",
      cancelButtonText: "<span class='poppins-light fs-4'>No</span>",
      customClass: {
        title: 'champ-light',
        confirmButton: 'confirm-button-sweet-alert',
        cancelButton: 'cancel-button-sweet-alert',
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          LessonAdjustmentAdd({
            appointment: appointment,
            lesson: nextLesson?.lesson.id,
            studentSetting: studentSetting
          })
        } catch (e) {

        }
      } else {

      }
    })
  }

  useEffect(() => {
    const handleResize = () => {
      setisMovil(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }

  }, [])

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px '
      show={show}
      style={{ borderRadius: 0 }}
      // onHide={handleClose}
      //   onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header' style={{ height: 15, borderRadius: 0 }}>
        <div className='btn btn-sm btn-icon btn-active-color-primary ms-n5' style={{ marginTop: -0 }} onClick={() => {
          handleClose ? handleClose() : setModal(false);
        }}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        <div className='modal-title   ps-0 pb-5 poppins-bold fs-5'  >

          <img style={{ width: 150, marginTop: 15 }} src='/media/logos/abroad-color.png'></img>
        </div>
      </div>
      <div className='card-body p-6 '>
        <span className='align-middle poppins-light' style={{ marginLeft: '0px', color: '#2d30e1', fontSize: 15 }}>Nivel Actual :<strong style={{ fontSize: 15, color: '#2d30e1' }} className='champ-bold'>{(settings?.level)}</strong></span>

        <div className='table-responsive '>
          <table className='table align-middle gs-0 gy-4 table-hover'>
            <thead>
              <tr className='fw-bold poppins-light fs-5' style={{ color: _mode === 'light' ? '#2d30e1' : '#E6E6FA', borderBottom: `1px solid ${_mode === 'light' ? '#2d30e1' : '#E6E6FA'}` }}>
                <th className='min-w-120px ps-0 pb-0'>Lección</th>
                <th className='min-w-120px ps-0 pb-0'>Estado del ejercicío</th>
                <th className='min-w-120px ps-0 pb-0'>Estado de la reserva</th>
              </tr>
            </thead>
            <tbody className='mt-2'>
              {data && data?.lessons?.slice(0, 7).map((x: NextLesson, index) => (

                <tr key={x.lesson.id} className={`poppins-light table-striped ${index < 1 ? 'table-primary' : ''}`} style={{ color: _mode === 'light' ? '#2d30e1' : '#E6E6FA' }}>
                  <td className='p-0'>
                    <span className='d-block fs-5 poppins-bold'>
                      {t(x.lesson.name)}
                    </span>
                  </td>
                  <td className='p-0'>
                    <span className='d-block fs-5'>
                      {x.status == 'IN_REVIEW' && <i style={{ fontSize: 16, color: 'green' }} className="bi bi-eye">Completado</i>}
                      {x.status == 'PENDING' && <i style={{ fontSize: 16, color: 'blue' }} className="bi bi-journal-bookmark">Pendiente</i>}
                      {x.status == 'NOT_TASK' && <i style={{ fontSize: 16, color: 'grey' }} className="bi bi-journal-x">Sin resolver</i>}
                    </span>
                  </td>
                  <td className='p-0'>
                    <span className='d-block fs-5'>
                      {x.trailClass.id == 'NOT_TRAIL' ?
                        <i style={{ fontSize: 16, color: 'grey' }} className="bi bi-calendar-x">Sin reserva</i> :
                        <i style={{ fontSize: 16, color: 'green' }} className="bi bi-calendar-week-fill">Reservado</i>
                      }

                    </span>
                  </td>


                </tr>
              ))}
            </tbody>
          </table>
          {/* app: {appointment ?? ''}
          <br />
          lesso:{nextLesson?.lesson.id}
          <br />
          setting:{studentSetting} */}
        </div>

        <div className='d-flex justify-content-center w-100'>
          {mode === 'TEACHER' && nextLesson && nextLesson?.status === 'IN_REVIEW' && <CustomButton
            title={`Adelantar ${nextLesson?.lesson.name}`}
            type='xl'
            color={_mode === 'dark' ? 'SECONDARY' : 'PRIMARY'}
            handleClick={() => sendAdjustment()}
          />}

        </div>
      </div>



    </Modal>,
    modalsRoot
  )
}

export { NexLessonModal }
