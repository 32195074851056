import React, { useEffect, useState } from 'react'
import { BooleanCell } from '../../../../../components/common/BooleanCell'
import GenericPaginator from '../../../../../components/common/Paginator'
import { CardHeaderModule } from '../../../../../components/common/widgets/statistics/cardHeaderModule'
import { useThemeMode } from '../../../../../../_metronic/partials'
import { UseQualifyPlacementModule } from '../../../../admin/qualify/modules/placement-test/store/main'
import { TaskType } from '../../../../admin/tasks/interfaces'
import { setTypeToSpanish } from '../../../../admin/user/utils/setTypeToSpanish'
import moment from 'moment'
import { UseQualifyPlacementAllPaginate } from '../../../../admin/qualify/modules/placement-test/services'
import { useTranslation } from 'react-i18next'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import Swal from 'sweetalert2'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import { AddPlacementRequestByStudent, ApprovedPlacementTestRequest, FindRequestPlacementTest } from '../../../../admin/placement-test/services'
import { PlacementTestRequestStatus } from '../../../../admin/placement-test/interfaces'
import { UseMyRoadmapModule } from '../../../../profile/components/my-road-step/store'
import { CreateModal } from '../modal'
import { EvaluationType } from '../../../../admin/evaluation/interfaces'
import { ViewAnswersModal } from '../../../../admin/qualify/modules/placement-test/components/modal/viewAnswersModal'
import { SettingPlacementTestStatus } from '../../../../abroad/road-map-step/interfaces'

type Props = {}
const MyTestsTableList: React.FC<Props> = ({ }) => {

  const { mode } = useThemeMode()
  const { t } = useTranslation()
  const {
    paginator,
    modal,
    limit,
    itemSelected,
    modalQualify,
    setCurrentPage,
    setLimit,
    setSelectedToView
  } = UseQualifyPlacementModule()

  const { settings, settingChecked } = UseMyRoadmapModule()

  const [taskType, setTaskType] = useState<TaskType>(TaskType.WELCOME_DAY)
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState<boolean>(true)
  const [evaluationType, setEvaluationType] = useState<EvaluationType>(EvaluationType.WELCOME_DAY)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const result = await UseQualifyPlacementAllPaginate(taskType)
        setData(result)
        if (result.result && result.result[0]) {
          setEvaluationType(result.result[0].type)
        }

        setLoading(false)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [taskType])

  const changePage = (number: number) => {
    setCurrentPage(number)
  }
  const changeLimit = (number: number) => {
    setLimit(number)
  }

  const placementRequest = React.useCallback(async () => {
    Swal.fire({
      title: 'Are you sure?',
      html: "<p class='poppins-light'>You won't be able to revert this!</p>",
      icon: 'warning',
      iconColor: '#2d30e1',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: "<span class='poppins-light fs-4'>Yes!</span>",
      cancelButtonText: "<span class='poppins-light fs-4'>No</span>",
      customClass: {
        title: 'champ-light',
        confirmButton: 'confirm-button-sweet-alert',
        cancelButton: 'cancel-button-sweet-alert',
      }
    }).then(async (res) => {
      if (res.isConfirmed) {
        const responseSetting = typeof settings === 'undefined' ? await settingChecked() : settings;
        if (responseSetting.placementTest === SettingPlacementTestStatus.PENDING) {
          return showSimpleAlert({message: 'No puedes hacer una solicitud de Placement Test, ya que actualmente tienes uno pendiente.', icon: 'error'});  
        };

        await AddPlacementRequestByStudent(responseSetting.id);
        const responsePlacement = await FindRequestPlacementTest(responseSetting.id);
        await ApprovedPlacementTestRequest(responsePlacement.id, { status: PlacementTestRequestStatus.IN_PROCESS });
        await settingChecked();
        showSimpleAlert({ message: 'Ok!', icon: 'success' })
      }
    })
  }, []);

  // const test = async () => {
  //   supabase
  // .channel('any')
  // .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .subscribe()
  // }
  // useEffect(()=>{
  //   test()
  // },[])

  return (
    <>
      <CardHeaderModule
        title={'My Tests'}
        leftIcon={`/media/svg/michigan-icons/header/${mode === 'light' ? 'white/back_white' : 'dark-modules/back_purple'
          }.svg`}
        svgIcon={`media/svg/michigan-icons/sidebar/white/tests.svg`}
        background={'#4bb544'}
        colorTitle={'#FFFFFF'}
      />
      <div className='mt-5'>
        <div className='d-flex justify-content-end'>
          <button className='btn champ-light mb-5 py-2'
            type='button'
            onClick={placementRequest}
            style={{
              color: 'white',
              background: '#4bb543'
            }}
          >
            Solicitar Placement Test
          </button>
        </div>
        <div
          className='card content-support'
          style={{ border: mode === 'light' ? '' : '1px solid #FFFFFF' }}
        >
          <div className='card-body'>
            <div className='table-responsive'>
              <table className='table'>
                <thead className=''>
                  <tr className={`fs-5 fw-bold bg-light poppins-bold`}>
                    <th className='poppins-bold fs-5 text-center'>
                      <select
                        className='text-white form-select-sm cursor-border border-primary'
                        value={taskType}
                        onChange={(e) => {
                          setTaskType(e.target.value as TaskType)
                        }}
                        style={{
                          border: 'none',
                          backgroundColor: '#4BB543',
                          borderColor: 'transparent',
                          outline: 'none'
                        }}
                      >
                        <option value={TaskType.WELCOME_DAY}>{t('WELCOME_DAY')}</option>
                        <option value={TaskType.PLACEMENT_TEST}>{t('PLACEMENT_TEST')}</option>
                        <option value={TaskType.FINAL_PRESENTATION}>{t('FINAL_PRESENTATION')}</option>
                        <option value={TaskType.QUIZ}>{t('QUIZ')}</option>
                      </select>
                    </th>
                    <th className='text-center'>Estado</th>
                    <th className='text-center'>Fecha</th>
                    <th className='text-center'>Calificado</th>
                  </tr>
                </thead>
                <tbody className=''>
                  {loading ? (
                    <tr>
                      <td colSpan={6} className='text-center'>
                        <div className='d-flex justify-content-center align-items-center'>
                          <img
                            className='rounded mx-auto'
                            width={'30%'}
                            src={toAbsoluteUrl('/media/svg/icons/loading.svg')}
                            alt='Loading'
                          />
                        </div>
                      </td>
                    </tr>
                  ) : data?.result?.map((x: any) => (
                    <tr
                      key={x.id}
                      className={`mt-3 cursor-pointer `}
                      onClick={() => setSelectedToView(x)}
                    >
                      <td className='text-center'>{t(x.type)}</td>
                      <td
                        className={`text-center align-middle 
                          text-${x?.status === 'PENDING'
                            ? 'primary'
                            : x?.status === 'QUALIFIED'
                              ? 'warning'
                              : x?.status === 'APPROVED'
                                ? 'success'
                                : x?.status === 'REPROVED'
                                  ? 'danger'
                                  : x?.status === 'IN_REVIEW'
                                    ? 'primary'
                                    : 'dark'
                          }`
                        }
                      >
                        {x?.status === 'PENDING'
                          ? 'Asignado'
                          : x?.status === 'QUALIFIED'
                            ? 'Calificado'
                            : x?.status === 'APPROVED'
                              ? 'Aprobado'
                              : x?.status === 'REPROVED'
                                ? 'Reprobado'
                                : x?.status === 'IN_REVIEW'
                                  ? 'Pendiente por revisar'
                                  : 'Cancelado'
                        }
                      </td>
                      <td className='text-center'>
                        <span className='poppins-light fw-bold'>
                          {moment(x?.createdAt).format('DD/MM/YYYY')}
                        </span>
                      </td>
                      <td className='text-center'>
                        <span className='poppins-light fw-bold'>
                          <BooleanCell value={x?.status === 'QUALIFIED' || x?.status === 'APPROVED' || x?.status === 'REPROVED'} />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <GenericPaginator
                limit={limit}
                currentPage={paginator.current_page}
                items={paginator.result.length}
                total={paginator.total}
                totalPage={paginator.total_page}
                changePage={changePage}
                changeLimit={changeLimit}
              />
            </div>
          </div>
        </div>
      </div>
      <CreateModal show={modal} evaluationType={evaluationType} />
      {(itemSelected) && (
        <ViewAnswersModal show={modalQualify} />
      )}
    </>
  )
}

export { MyTestsTableList }
