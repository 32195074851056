import {create} from 'zustand';
import {LeadResume, Pagination } from '../interfaces';
import moment from 'moment'

type DateRange ={
    before:Date
    after:Date
}

interface UseLeadResumeModule {
    itemSelected?: LeadResume 
    mode:'ADD'|'EDIT'|'VIEW'
    modal:boolean,
    isActive:boolean,
    before:Date,
    after:Date
    paginator:Pagination
    where:string
    teacher?:string
    executive?:string
    country?:string
    source?:string
    level?:string
    currentPage:number
    limit:number
    total:number
    setIsActive:(mode:boolean)=>void;
    setModal:(mode:boolean)=>void;
    setTeacher:(mode:string)=>void;
    setCountry:(mode:string)=>void;
    setExecutive:(mode:string)=>void;
    setSource:(mode:string)=>void;
    setSelectedToEdit:(item:LeadResume)=>void;
    setSelectedToView:(item:LeadResume)=>void;
    resetData:()=>void,
    setPaginator:(paginator:Pagination)=>void,
    setCurrentPage:(value:number)=>void,
    setLimit:(value:number)=>void,
    setWhere:(value:string)=>void,
    setLevel:(value:string)=>void,
    setToAdd:()=>void,
    setDate:(value:DateRange)=>void,
}

export const UseLeadResumeModule = create<UseLeadResumeModule>( (set) => ({
    mode:'ADD',
    modal:false,
    currentPage:1,
    where:'',
    limit:10,
    isActive:true,
    after:moment().add(9,'d').toDate(),
    before:moment().subtract(3,'d').toDate(),
    total:0,
    level:"",
    country:"",
    teacher:"",
    executive:"",
    source:"",
    paginator:{
        result:[],
        total:0,
        stats:undefined
    },
    setIsActive:(value:boolean)=>{
        set({isActive:value})
    },
    setModal:(mode)=>{
        set({modal:mode})
    },
    setToAdd:()=>{
        set({mode:'ADD',modal:true,itemSelected:undefined})
    },
    setSelectedToEdit:(item)=>{
        set({itemSelected:item,mode:'EDIT',modal:true})
    },
    setSelectedToView:(item)=>{
        set({itemSelected:item,mode:'VIEW',modal:true})
    },
    resetData:()=>{
        set({itemSelected:undefined,mode:'ADD',modal:false})
    },
    setPaginator:(pagination)=>{
        //console.log(pagination)
        set({paginator:{
            result:pagination.result,
            total:pagination.total,
            stats:pagination.stats
        }})
    },
    setTeacher:(value)=>{
        set({teacher:value})
    },
    setCurrentPage:(value)=>{
        set({currentPage:value})
    },
    setLimit:(value)=>{
        set({limit:value})
    },
    setWhere:(value)=>{
        set({where:value})
    },
    setLevel:(value:string)=>{
        set({level:value})
    },
    setExecutive:(value:string)=>{
        set({executive:value})
    },
    setCountry:(value:string)=>{
        set({country:value})
    },
    setSource:(value:string)=>{
        set({source:value})
    },
    setDate:(value:DateRange)=>{
        set({
            after:value.after,
            before:value.before
        })
    },
}));