import React, { useEffect } from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { useNavigate } from 'react-router-dom';
import './welcome-style.css'
import { useThemeMode } from '../../../../../_metronic/partials';
import { t } from 'i18next';
import { logoAppSelectorUrl } from '../../../../hooks/useAppSelectorAssetsMode';
import { FloatingButton } from '../../../../modules/dashboard/components/next-lesssons/float-button';
type Props = {
    title: string;
    leftIcon?: string;
    svgIcon: string;
    background: string;
    colorTitle?: string;
    routeBack?: string;
};

const WelcomeHeaderModule: React.FC<Props> = ({ svgIcon, leftIcon, title, background, routeBack, colorTitle }) => {
    const navigate = useNavigate();
    const { mode } = useThemeMode()
    const handleClick = () => {
        if (routeBack) {
            navigate(routeBack);
        } else {
            navigate('/dashboard');
        }
    };
    let url = logoAppSelectorUrl();

    const [isMovil, setisMovil] = React.useState<boolean>(window.innerWidth < 768);


    useEffect(() => {
        const handleResize = () => {
            setisMovil(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }

    }, [])

    return (
        <div className={`card welcome-michigan-title-card my-3`}
            style={{
                paddingTop: isMovil ? '2px' : '0px',
                paddingBottom: isMovil ? '53px' : '0px',
                ...(mode === 'light'
                    ? { background: background, position: 'relative' }
                    : { position: 'relative', border: `1px solid`, borderColor: colorTitle })
            }}
        >
            <h3 className='champ-light welcome-michigan-title-top ' style={{ color: 'white' }}>{url.name}</h3>
            <div className="card-header d-flex  justify-content-between align-items-center  welcome-text-champ-welcome" style={{ border: 'none', fontSize: isMovil ? '15px' : '25px' }}>
                <div className={`champ-light`} style={mode === 'light' ? { color: 'white' } : { color: '#d30e1' }}>
                    {t('WELCOME_BACK')},
                </div>
                <div>
                    <div className='welcome-right-icon-road-card-title cursor-pointer' style={{ position: 'absolute' }}  >
                        <FloatingButton />

                    </div>
                    <div className='welcome-right-icon-card-title cursor-pointer' style={{ position: 'absolute' }} onClick={() => handleClick()}>
                        <img className='right-icon-card' src={toAbsoluteUrl(svgIcon)} alt='' />
                    </div>
                </div>
            </div>
            <div className='card-body pb-0 py-1'>
                <div className={`champ-bold fw-semibold pb-0 welcome-text-champ-title-name `}
                    style={{ minWidth: 20, color: 'white' }}>
                    {title}
                </div>
            </div>
        </div>
    );
};

export { WelcomeHeaderModule };
