import Swal from 'sweetalert2'
import { LessonAdjustmentUpdateByStudent } from '../../admin/lesson-adjustment/services'
import { LessonAdjustmentType } from '../../admin/lesson-adjustment/interfaces';
import { showSimpleAlert } from '../../../commonHooks/alert';

export const actionsFromWs = (data: any) => {
  if (data.type === 'LESSON_ADJUSTMENT') {

    Swal.fire({
      title: '¿Deseas adelantar tu clase?',
      html: `<p class='poppins-light'>${data.lesson}</p>`,
      icon: 'warning',
      iconColor: '#2d30e1',
      showCancelButton: true,
      confirmButtonText: "<span class='poppins-light fs-4'>Sí!</span>",
      cancelButtonText: "<span class='poppins-light fs-4'>No</span>",
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      customClass: {
        title: 'champ-light',
        confirmButton: 'confirm-button-sweet-alert',
        cancelButton: 'cancel-button-sweet-alert',
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        await LessonAdjustmentUpdateByStudent(
          data.idToAction, LessonAdjustmentType.ACCEPTED
        );
      } else {
        await LessonAdjustmentUpdateByStudent(
          data.idToAction, LessonAdjustmentType.REFUSED
        );
      }
    })
  }
}