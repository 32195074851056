export interface DataPaginateAppointmentResponse {
    status:  string;
    message: string;
    data:    DataAppointment;
}

export interface DataAllAppointmentResponse {
    status:  string;
    message: string;
    data:    Appointment[];
}

export interface DataAppointment {
    id: string
    description: string 
    code?: string;
    classroom: any 
    // hour_start: string
    // hour_finish: string
    start: string 
    // finish: string
    isActive: boolean 
    // createdAt: string
    // updatedAt: string
    status_appointment: AppointmentStatus; 
    teacher: Teacher 
    responsible: Responsible 
    activity?: any; 
    campus?: any; 
    blockHour: any[]; 
    trail_class?: any[]; 
    // level?: Level
    // client: string
    // user: User
    // event: Event
}

export interface Appointment  {
    id?: string;
    description: string
    classroom: any
    start?: string
    isActive: boolean
    status_appointment: AppointmentStatus;
    teacher?: any
    activity?: any; 
    responsible?: any
    blockHour: number[]; 
    createdAt?:string;
    updatedAt?:string;
    campus?: string;
    trail_class?: any[]; 
    // level?: any
    // client?: string
    // user?: any
    // event?: any
    // lead?:any[]
    // finish?: string
    // hour_start?: string
    // hour_finish?: string
}

export interface Pagination {
    result: Appointment[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}


export interface Teacher {
  id: string
  email: string
  phoneNumber: string
  fullName: string
  isActive: boolean
  roles: string[]
  type: string
  profile?: Profile
}

export interface Profile {
  id: string
  country: string
  firstName: string
  lastName: string
  documentNumber: string
  gender: string
  zipCode: string
  city: string
  address: string
  state: string
  birthDay: string
  personalWeb: string
  btcWallet: string
  tronWallet: string
  phoneNumber: string
  aboutMe: string
  photo?: Photo
}

export interface Photo {
  id: string
  location: string
  key: string
  extension: string
}

export interface Responsible {
  id?: string
  fullName?: string
}

export interface Level {
  id: string
  order: number
  name: string
  description: string
  video: string
  isActive: boolean
  createdAt: string
  updatedAt: string
  country?: Country
}

export interface Country {
  id: string
  description: string
  name: string
  code: string
  isActive: boolean
  createdAt: string
  updatedAt: string
}

export interface User {
  id: string
  firstName?: string
  lastName?: string
  email: string
  avatar?: string
  documentNumber?: string
}

export interface Event {
  title: string
  with: string
  time?: Time
  teacher?:string
  room?:string
  color: string
  isEditable: boolean
  id: string
  description: string
}

export interface Time {
  start: string
  end: string
}

export enum TypeTrailClass {
  VIRTUAL = 'VIRTUAL',
  FACE_TO_FACE = 'FACE_TO_FACE',
  WORKSHOP = 'WORKSHOP',
  PLACEMENT_TEST = 'PLACEMENT_TEST',
  ADJUSTMENT = 'ADJUSTMENT',
}

export interface relatedTrailClasses {
  id: string,
  type: TypeTrailClass,
  appointment: { id: string },
  lesson: {
    id: string,
    name: string
  }
}

export interface Booking {
  title: string;
  with: string;
  time: Time;
  color: string;
  isEditable: boolean;
  isSuspense: boolean;
  isSuspended: boolean;
  id: string;
  description: string;
  teacher: string;
  video: string;
  start:string;
  url: string;
  appointment:string;
  assistantStudent?: boolean;
  assistantTeacher?: boolean;
  stars?: number;
  code?: string;
  hour?: any;
  room?: string;
  updateUser?: string;
  lessonName: string;
  type: TypeTrailClass;
  relatedTrailClasses: relatedTrailClasses[];
}

export enum AppointmentStatus {

  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  IN_PROCESS = 'IN_PROCESS',
  FINISHED = 'FINISHED',
  TO_BE_CLOSED='TO_BE_CLOSED',
    EMPTY_CLASS='EMPTY_CLASS'
}

export interface BlochHourByClassroomTeacher {
  start: string;
  teacher: string;
  classroom: string;
};

export interface TransferTeacherAppointment {
  teacher: string;
  appointment: string[];
};

export interface LevelObserver {
  student: string;
}

export interface ClassValueData {
  id?: string;
  value: number;
  appointment: string;
  created_at?: string;
  updated_at?: string;
  isActive?: boolean;
}