
import { persist } from 'zustand/middleware';
import {create} from 'zustand';
export type ResumeNextLesson ={
    lessons:NextLesson[]
    totalTaskInReview: number
    totalTrailClass: number
} 

export interface NextLesson {
  status: string
  trailClass: TrailClass
  lesson: Lesson
}

export interface TrailClass {
  id: string
  date: string
}

export interface Lesson {
  id: string
  name: string
}
  interface TrailTaskState {
    modal:boolean,
    mode:'TEACHER'|'STUDENT',
    appointment?:string,
    studentSetting?:string,
    data?: ResumeNextLesson
    currentLesson?: NextLesson;
    nextLesson?: NextLesson;
    setNextLessonData: (data: ResumeNextLesson) => void;
    setModeModal: (value: 'TEACHER'|'STUDENT') => void;
    setCurrentAppointment: (value:string) => void;
    setStudentSetting: (value:string) => void;
    setModal:(mode:boolean)=>void;    setCurrentLesson: (value: NextLesson | undefined) => void
}

const useTrailTask = create(persist<TrailTaskState>(
    (set, get) =>  ({
        modal:false,
        data:undefined,
        nextLesson: undefined, 
        appointment:undefined,
        mode:'STUDENT',
        studentSetting:undefined,
        setStudentSetting: (value) => {
          set({studentSetting: value});
        },
        setNextLessonData: (data: ResumeNextLesson) => {
          const nextLesson = data.lessons.length > 1 ? data.lessons[0] : undefined;
          set({
            data: data,
            nextLesson: nextLesson, // Asignamos la siguiente lección a la propiedad
          });
        },
        setCurrentAppointment: (value) => {
          set({appointment: value});
        },
        setModeModal: (value) => {
          set({mode: value});
        },
        setCurrentLesson: (value) => {
          set({currentLesson: value});
        },
        setModal: (mode) => {
            set({
              modal: mode
            });
        },
      }),
      {
        name:"trail_stack_state",
        // getStorage: () => localStorage
      }
));

export default useTrailTask;