import React, { useEffect, useState } from 'react';
import '../ExerciseTwelve.css';
import { GroupWordResponse, UseGroupWordsModules } from '../main/main'
import Swal from 'sweetalert2';
import HeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/HeaderCard';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { AddGroupWordsResponse } from './services';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { ButtonTryAgain } from '../../common/button-try-again/buttonTryAgain';
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
import { UseBookContainerModule } from '../../../../book/store/main';


type Props = {
  responses?: GroupWordResponse[];
  userMode?: ModeTypeExercise
}

const GroupWordsItem: React.FC<Props> = ({ userMode, responses }) => {
  const [groups, setGroups] = useState<GroupWordResponse[]>([]);
  const [clicked, setClicked] = useState(false);
  const [percentage, setPercentage] = React.useState<number | null>(null);
  const { questions, instruction, exerciseId } = UseGroupWordsModules();
  const {
    lessonData,
    stepBookItem,
    currentTaskId,
    setStepBookItem,
    addCompletedExerciseId,
    onChangeTaskByAnswer,
    setIsLoadingSubmit,
  } = UseBookStudentModule();

  const { modeViewAnswer } = UseModeCourseModule();

  useEffect(() => {
    if (responses) {
      setGroups(responses);
    } else {
      setGroups(questions.map((x) => ({
        ...x,
        isTrue: false,
        values: x.values.map(() => ({value: '', isTrue: false})),
      })))
    }
  }, [questions, responses]);

  const handleCheck = () => {
    let correctAnswers = 0;
    let totalAnswers = 0;
    let emptyFields = false;

    groups.forEach(group => {
      group.values.forEach(item => {
        if (item.value.trim() === '') {
          emptyFields = true;
        }
      });
    });

    if (emptyFields) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: '¡Debe ingresar todos los campos!',
      });
      return;
    }

    const updatedGroups = groups.map((group, groupIndex) => {
      const correctValues = [...questions[groupIndex].values].map(v => v.toLowerCase());
      let groupIsTrue = true;

      const updatedValues = group.values.map((value) => {
        const correctIndex = correctValues.indexOf(value.value.toLowerCase());
        const isCorrect = correctIndex !== -1;
        if (isCorrect) {
          correctValues.splice(correctIndex, 1);
        } else {
          groupIsTrue = false;
        }
        totalAnswers++;
        if (isCorrect) correctAnswers++;
        return { value: value.value, isTrue: isCorrect };
      });

      return {
        ...group,
        values: updatedValues,
        isTrue: groupIsTrue,
      };
    });
    setGroups(updatedGroups);

    const percentage = (correctAnswers / totalAnswers) * 100;
    setPercentage(percentage);
    setClicked(true);
  };

  const handleInputChange = (groupIndex: number, valueIndex: number, newValue: string) => {
    const updatedGroups = [...groups];
    updatedGroups[groupIndex].values[valueIndex].value = newValue;
    setGroups(updatedGroups);
  };

  React.useEffect(() => {
    if (responses) {
      setClicked(true);
    }
  }, [responses]);


  const divStyle = {
    backgroundColor: '#3f8bff',
    maxWidth: '525px',
    minWidth: '500px',
    borderRadius: '30px',
    borderColor: 'rgb(255, 255, 255)',
    borderStyle: 'solid',
    padding: '27px 20px 20px 20px'
  };

  const handleSubmit = async () => {
    if (userMode === 'STUDENT' && currentTaskId && exerciseId) {
      try {
        setIsLoadingSubmit(true)
        const response = await AddGroupWordsResponse({
          isActive: true,
          exercises: exerciseId ?? '',
          percentage: percentage ? percentage : 0,
          task: currentTaskId ?? '',
          answers: groups
        });
        onChangeTaskByAnswer(response, 'group_word');
      } catch (e) {
        showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' })
      } finally {
        setIsLoadingSubmit(false)
      }
    }
    if (lessonData && lessonData.evaluation &&
      lessonData.evaluation.evaluation_item) {
      addCompletedExerciseId(exerciseId ?? "")
      if (lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
        setStepBookItem(exerciseId ?? '')
      } else {
        setStepBookItem('FINISH_LESSON')
      }
    }
  }

  const resetExercise = () => {
    setPercentage(0);
    setClicked(false);
  }

  const { showButtons, setOffDraggable } = UseBookContainerModule()

  return (<>
    <div className="container " style={{ }}>
      <div style={divStyle}>
        <HeaderCard />
        <p className="scroll-y overflow-y-auto text-white  poppins-light fs-8 " style={{ marginLeft: '8px', maxHeight:'60px'}}>{instruction}</p>
        <table className="table table-responsive table-rounded-table table-sm">
          <thead>
            <tr className="poppins-bold">
              {groups.map((group, i) => (
                <th key={i} className='text-center bg-light table-rounded-table-th-one fs-8' style={{ width: '200px', color: '#3F8BFF' }}>
                  {group.name ?? ''}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-center text-white poppins-light fs-9'>
          <tr>
              {groups.map((group, groupIndex) => (
                <td key={groupIndex} className='border border-top-0' style={{ borderRadius: '0px 0px 0px 13px', border: '2px solid #ffffff', paddingTop: '6px' }}>
                  {group.values.map((item, valueIndex) => (
                    <input
                      key={valueIndex}
                      type="text"
                      style={{
                        color: 'rgb(0, 0, 168)',
                        width: '68px',
                        borderRadius: '50px',
                        border: '0px',
                        outline: 'none',
                        background: clicked ? item.isTrue ? '#D0FF00' : '#d65353' :'#D0FF00',
                        marginRight: '3px'
                      }}
                      className='mb-1 text-center fs-9 poppins-bold'
                      value={modeViewAnswer ? item.value : group.values[valueIndex].value}
                      onChange={(e) => handleInputChange(groupIndex, valueIndex, e.target.value)}
                      onMouseEnter={()=> setOffDraggable(true)}
                      onMouseLeave={()=> setOffDraggable(false)}
                      disabled={clicked}
                    />
                  ))}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
        <div className='mb-4'>
          <span className={`animate__animated text-white fs-8 poppins-light  ${percentage ? 'animate__bounce' : 'd-none'}`}>Porcentaje de aciertos: {percentage !== null ? `${percentage.toFixed(1)}%` : 'Calculando...'}</span><br />
          {/* <span style={{ marginBottom: '40px' }} className={`animate__animated text-white fs-8 poppins-light  ${percentage ? 'animate__bounce' : 'd-none'}`}>{`Acertaste ${correctTotal}/${total}`}</span> */}
        </div>
        { showButtons &&
          <div className={`d-flex justify-content-${clicked? "between":"end"} align-items-end`}>
            {clicked &&
              <ButtonTryAgain onAction={resetExercise} />
            }
            <ButtonSaveExercise
              isChecked={clicked}
              onNext={handleCheck}
              onSubmit={handleSubmit}
              exerciseId={exerciseId}
              />
          </div>  
        }
      </div>
    </div>
  </>)
};
export { GroupWordsItem }