import React from 'react';
import './index.css';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { ButtonIntroEvaluationItem } from '../../common/button-intro-evaluation-item/buttonIntroEvaluationItem';
import { UseEvaluationCourseModule } from '../../../screen/store/main';
import { UseModeCourseModule } from '../../../components/front-page/store/main';

type Props = {
    title:string
    instructions:string
    startQuiz:(boolean)=>void
};

const IntroBasicVocabulary: React.FC<Props> = ({ title,instructions,startQuiz }: Props) => {
    const { mode } = useThemeMode();
    const {color} = UseModeCourseModule();
    const { evaluationLesson, questionsEvaluation} = UseEvaluationCourseModule();
    return (
        <>

            <div className="card-true-false"
                style={
                    mode !== 'dark' ? {
                        height: 420,
                        background: 'white',
                        position: 'relative'
                    } : {
                        height: 420,
                        background: '#1e1e2d',
                        position: 'relative'
                    }
                }>

                <h2 className='champ-light title-true-false-resume text mb-5D'
                    style={{
                        color: evaluationLesson?.colorPrimary??'#2d30e1',

                    }}>
                    {title}
                </h2>
                <h3 className='poppins-light subtitle-true-false text'
                    style={{
                        color: evaluationLesson?.colorPrimary??'#2d30e1',
                    }}

                >
                    <strong style={{ fontWeight: 'bold' }}>{instructions}</strong>
                </h3>

                <div 
                
                    style={{
                        display:'flex', justifyContent: 'end', marginTop: 'auto'
                    }}
                >
                    <ButtonIntroEvaluationItem onNext={startQuiz} color={color} exerciseId={questionsEvaluation?.id}/>
                </div>
            </div>
        </>
    );
};

export { IntroBasicVocabulary };