import React, { useEffect } from 'react';
import './index.css';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { Answer, QuestionBasicVocabularyCompletion } from '../interfaces';
import useSound from 'use-sound';
import { UseEvaluationCourseModule } from '../../../screen/store/main';
import useIsImportantEvaluation from '../../../hooks/useIsImportantEvaluation';
import { ButtonNextQuestion } from '../../common/button-next-question/buttonNextQuestion';
import { UseModeCourseModule } from '../../../components/front-page/store/main';

type Props = {
    setStep:(number)=>void
    question: QuestionBasicVocabularyCompletion
    modeQuiz:'RESUME'|'ANSWER'|'THROW_ERROR'|'TITLE'
    handleSendAnswer: (x:Answer) => void
    totalQuestions:number
    description: string

};

const BasicVocabularyExerciseCompletionItem: React.FC<Props> = ({ setStep, question, modeQuiz, handleSendAnswer, totalQuestions, description,}: Props) => {
    const { mode } = useThemeMode();
    const {color} = UseModeCourseModule();
    const {evaluationLesson, questionsEvaluation } = UseEvaluationCourseModule();
    const isImportantQuiz = useIsImportantEvaluation();
    const [selected, setSelected] = React.useState<Answer>();
    const [playbackRate] = React.useState(0.75);
    const [play] = useSound(toAbsoluteUrl(`/media/audio/click.mp3`), {
        playbackRate,
        volume: 0.1,
    });
    const handleClick = () => {
        play();
    };
    const handleSend = () => {
        if(selected){
            handleSendAnswer(selected)
            setSelected(undefined)
        }
    };
    const filterTrueAnswers = (answersArray:Answer[]):string[] =>{
        let resume = answersArray.find((x)=>x.isTrue === true);
        return resume?.title??[]
    }
    
    const filterStringCut = (paragraph: string): string => {
        let wordsToRemove: string[] = filterTrueAnswers(question.answers);
        
        const normalizeText = (text: string): string => {
            return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        };
        
        const cleanedParagraph = normalizeText(paragraph);
        
        let normalizedWordsToRemove = wordsToRemove.map(word => normalizeText(word.trim()));
        let regexPattern = new RegExp(normalizedWordsToRemove.map(word => `\\b${word}\\b`).join('|'), 'gi');
        
        const total = cleanedParagraph.replace(regexPattern, match => "_".repeat(match.length));
        
        return total;
    };


    useEffect(() => {
        setSelected(undefined)
    }, [question]);

    return (
        <div className="card-basic-vocabulary-completion"
            style={
                mode !== 'dark' ? {
                    height: 420,
                    background: 'white',
                    position: 'relative'
                } : {
                    height: 420,
                    background: '#1e1e2d',
                    border: `1px solid`,
                    borderColor: '#3e94ff',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column'
                }
            }
        >

            <h3 className='poppins-light subtitle-basic-vocabulary-completion text'
                style={{
                    color: evaluationLesson?.colorPrimary??'#3e94ff',
                }}
            >
                <strong style={{ fontWeight: 'bold' }}>Instrucciones: </strong>{description}
            </h3>
            <h2 className='champ-light title-basic-vocabulary-completion text'
                style={{
                    color: evaluationLesson?.colorPrimary??'#3e94ff',
                }}>
                #{question.order}/{totalQuestions}
            </h2>

            <h2 className='scroll-y overflow-y-auto champ-light title-basic-vocabulary-completion text mb-2'
                style={{
                    color: evaluationLesson?.colorPrimary??'#3e94ff',
                    maxHeight:'80px'
                }}>
                {selected? question.name : filterStringCut(question.name)}
            </h2>

            <div className='scroll-y overflow-y-auto'style={{flexGrow: 1, maxHeight:'210px'}}>
                    { modeQuiz === 'ANSWER' && !selected && question.answers.map((x, i) => (
                        <h3
                            key={i}
                            onClick={()=>{
                                        handleClick();
                                        setSelected({...x, feedback: question.description})
                                    }}
                            className='d-flex poppins-light-italic option-basic-vocabulary-completion'
                        >
                            <i className="bi bi-check icon-option-basic-vocabulary-completion" style={{ color: 'transparent'}}/>
                            <span className='cursor-pointer custom-hover badge rounded-pill px-1 my-1'
                                style={{
                                    color: evaluationLesson?.colorPrimary??'#3e94ff',
                                    fontSize: 18
                                }}
                            > {i+1}. {x.title.map((y,index)=>(<>{y}{x.title.length===index+1?'':'/'}</>))} </span>
                        </h3>
                    ))}

                    {selected && question.answers.map((x,i) => (
                        <h3
                            className='poppins-light-italic option-basic-vocabulary-completion d-flex'
                            style={{ color: evaluationLesson?.colorPrimary??'#3e94ff'}}
                        >
                            <>
                                {x.isTrue ?
                                    <i className="bi bi-check icon-option-basic-vocabulary-completion mb-1" style={{ color: isImportantQuiz ? 'transparent' : 'green'}}/>
                                    :
                                    <i className="bi bi-x icon-option-basic-vocabulary-completion" style={{ color: isImportantQuiz? 'transparent':'red'}}/>
                                }
                            </>
                            {selected.order === x.order ?
                                <span className="badge rounded-pill my-1 px-1" style={{background:'#bdbeff', color:evaluationLesson?.colorPrimary??'#3e94ff',fontSize:18}}>
                                    {i+1}. {x.title.map((y,index)=>(<>{y}{x.title.length===index+1?'':'/'}</>))}
                                </span>
                                :
                                <span className={`badge rounded-pill`} style={{fontSize:18, color:evaluationLesson?.colorPrimary??'#3e94ff'}}>
                                    {i+1}. {x.title.map((y,index)=>(
                                        <>{y}{x.title.length===index+1?'':'/'}</>
                                    ))}
                                </span>
                            }
                        </h3>
                    ))}
            </div>

            {modeQuiz === 'THROW_ERROR' && <>
                {question.order > 1 && <div
                    className='  arrow-left-basic-vocabulary-completion cursor-pointer'
                    style={{ position: 'absolute' }}
                    onClick={() => setStep(-1)}
                >
                    <a href='#' className='poppins-light text-cursor-basic-vocabulary-completion-left' style={{ position: 'absolute' }}> back</a>
                    <img className='' src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/left_arrow.svg`)} alt='' />
                </div>}
                <div
                    className='arrow-right-basic-vocabulary-completion cursor-pointer'
                    style={{ position: 'absolute' }}
                    onClick={() => setStep(1)}
                    >
                        <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_active.svg`)} alt='' />
                </div>
            </>}
            {modeQuiz === 'ANSWER' && selected &&
                <div className='d-flex justify-content-end mt-auto'
                >
                    <ButtonNextQuestion onNextlesson={handleSend} color={color} exerciseId={questionsEvaluation?.id} typeButton='next'/>
                    {/* <button
                        type='button'
                        className='cursor-pointer border border-0 bg-transparent' 
                        onClick={() => handleSend()}
                        >
                            <img height={30} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_active.svg`)} alt='' />
                    </button> */}
                </div>
            }
        </div>
    );
};

export { BasicVocabularyExerciseCompletionItem };