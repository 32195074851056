/* eslint-disable jsx-a11y/anchor-is-valid */
import { useTranslation } from 'react-i18next';
import { UseBookingDashboard } from '../../../store';
import { LeadsModal } from '../../modal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import React, { useEffect } from 'react';
import { UpdateTrailClassMassive } from '../../../../abroad/trail/services';
import { useParams } from 'react-router-dom';
import { UseGetOneAppointment } from '../../../../admin/appointment/services';
import { SequenceType } from '../../../../abroad/road-map-step/interfaces';
import { TrailClassDashboard } from '../../../interfaces';

interface UserAction {
  id: string;
  isAssistant: boolean;
  sequence: SequenceType;
}
const DashboardLeads: React.FC = () => {
  let { code } = useParams();
  const { t } = useTranslation();
  const [usersToActions, setUsersToActions] = React.useState<UserAction[]>([]);
  const { itemSelected, modal, showButton, setLead, setTrialClass, setItem} = UseBookingDashboard();
  
  let findBooking = async (status?: string) => {
    if (code) {
      try {
        let data = await UseGetOneAppointment(code);
        setItem(data)
      } catch (e) {
      }
    }
  }
  
  const checkIsSelected = (id: string) => {
    const user = usersToActions.find((x) => x.id === id);
    return user ? user.isAssistant : false;
  };
  
  const toggleUserAssistantStatus = (id: string) => {
    setUsersToActions((prevUsers) =>
      prevUsers.map((user) =>
        user.id === id ? { ...user, isAssistant: !user.isAssistant } : user
      )
    );
  };
  
  // const addUser = (user: any) => {
  //   const userExists = usersToActions.some((u) => u.id === user.id);
  //   if (!userExists) {
  //     const newUser: UserAction = { id: user.id, isAssistant: user.assistantStudent };
  //     setUsersToActions((prevUsers) => [...prevUsers, newUser]);
  //   } else {
  //     toggleUserAssistantStatus(user.id);
  //   }
  // };
  
  const setListStudentCheckbox = () => {
    const sequence = itemSelected ? (itemSelected?.activity && itemSelected.activity.roadMap && itemSelected.activity.roadMap) ?
    itemSelected.activity.roadMap.sequence : SequenceType.RANDOM : SequenceType.RANDOM;
    const list: UserAction[] = itemSelected?.trail_class.map((x) => ({
      id: x.id,
      isAssistant: x.assistantTeacher,
      sequence: sequence,
    })) || [];
    setUsersToActions(list);
  };
  
  const loadMassiveAssistant = async () => {
    let response = await UpdateTrailClassMassive(usersToActions);
    findBooking()
  };
  
  useEffect(() => {
    setListStudentCheckbox();
  }, [itemSelected?.trail_class]);
  
  const getAllLessonsText = (studentSettingId : string)=>{
    const myTrailClasses = itemSelected?.trail_class.filter(x=>x.student_setting.id ===studentSettingId).map((x)=>({
      text: x.lesson?.name ?? "Lección no encontrada"
    })) ?? [{text: "Leccion no encontrada"}]
    const textToShow = myTrailClasses.sort((a,b)=>a.text .localeCompare(b.text)).map(lesson => lesson.text).join(', ');
    return textToShow
  }
  
  return (
    <div className='mb-10 card p-10'>
      <div className='fs-6 fw-bold mb-2 champ-light'>
        Students
      </div>
      <button
        disabled={!showButton} 
        onClick={loadMassiveAssistant}
        type="button" 
        className="btn btn-success btn-sm"
      >
        Cargar asistencia
      </button>
      <LeadsModal show={modal} handleClose={() => alert("sd")} />
      
      <div className='mh-600px scroll-y me-n7 pe-7'>
        {itemSelected?.trail_class.map((x, i) => (
          <>
            { (x.type !== 'PLACEMENT_TEST' && x.type !== 'ADJUSTMENT') &&
              <div
                className='d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed'
                key={i}
              >
                <div className='d-flex align-items-center'>
                  <OverlayTrigger overlay={<Tooltip>Add to actions</Tooltip>} placement='bottom'>
                    <button 
                      disabled={!showButton}
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      onClick={() => toggleUserAssistantStatus(x.id)}
                    >
                      <i 
                        className={`bi ${checkIsSelected(x.id) ? "bi-check2-square" : "bi-square"}`}
                        style={{ color: checkIsSelected(x.id) ? "grey" : "green", fontSize: "17px" }}
                      />
                    </button>
                  </OverlayTrigger>
                  
                  <div className='ms-2 w-27px poppins-light'>
                    <div className='symbol symbol-35px symbol-circle'>
                      <span
                        style={{
                          background: (x.assistantTeacher || x.assistantStudent) ? 'green' : 'red',
                          color: 'white'
                        }}
                        className={`symbol-label text-fw-bold`}
                      >
                        {(x.assistantTeacher || x.assistantStudent) ?
                          <i className="bi bi-camera-video" style={{ fontSize: 20, color: 'white' }}></i>
                          :
                          <i className="bi bi-camera-video-off" style={{ fontSize: 20, color: 'white' }}></i>
                        }
                      </span>
                    </div>
                  </div>
                  
                  <div className='ms-2 w-27px poppins-light'>
                    <div className='symbol symbol-35px symbol-circle'>
                      <span
                        style={{
                          background: (!x.assistantStudent || !x.assistantTeacher  || x.lateArrival) ? 'red' : 'green',
                          color: 'white'
                        }}
                        className={`symbol-label text-fw-bold`}
                      >
                        {x.lateArrival ?
                          <i className="bi bi-clock" style={{ fontSize: 20, color: 'white' }}></i>
                          :
                          <i className="bi bi-clock-history" style={{ fontSize: 20, color: 'white' }}></i>
                        }
                      </span>
                    </div>
                  </div>
                  
                  <div className='ms-2 w-60px fs-6 fw-bold mb-2 champ-light'>
                    {x.student_setting && t(x.student_setting.level)}
                  </div>
                  
                  <div className='ms-5'>
                    <a
                      href='#'
                      className='fw-bold mb-2 champ-light text-muted text-hover-primary mb-2'
                    >
                      {getAllLessonsText(x.student_setting.id)}
                    </a>
                    <div className='fw-bold champ-light'>
                      {x.student_setting.user.fullName}
                    </div>
                  </div>
                </div>
                
                <div className='ms-2 w-150px'>
                  {x && <button
                    onClick={() => setTrialClass(x)}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <i style={{ color: 'green', fontSize: 17 }} className="fas fa-list"></i>
                  </button>}
                </div>
              </div>
            } 
          </>
        ))}
      </div>
    </div>
  );
};

export { DashboardLeads };