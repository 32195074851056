import { MeReport, DataAllMeReportResponse, DataPaginateMeReportResponse } from '../interfaces/index'
import { useStore } from 'zustand'
import { UseMeBookingActiveModule } from '../store/main'
import { api } from '../../auth/services/auth.services'
import { showSimpleAlert } from '../../../commonHooks/alert'
import useAuth from '../../../api/main'
import { catchServerError } from '../../../hooks/useCatchError'
import { UseMyRoadmapModule } from '../../profile/components/my-road-step/store'
import { BookingFind } from '../../me-booking/services/trail-class'
import useTrailTask, { NextLesson } from '../../dashboard/components/student/store'

const { token, logout, authChecked } = useAuth.getState()
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}
export const useMeReportAll = async (): Promise<any> => {
  try {
    const response = await api.get(`/me-report/simple?type_category=all`,
      {
        headers: {
          Authorization: 'Bearer YOUR_ACCESS_TOKEN_HERE',
        },
      })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}
export const UseMeReportAllPaginate = async ({ id, before, after, blockHour, orderBy }: BookingFind): Promise<any> => {
  const { settings, settingChecked } = UseMyRoadmapModule.getState()
  try {
    // await authChecked()
    const { setPaginator, limit, currentPage, where, isActive } = await UseMeBookingActiveModule.getState()
    if (id) {
      let url = `/trail-class/paginated?before=${before}&after=${after}&root=${id}&isActive=${isActive ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}&isSuspense=${0}`;
      if (blockHour) {
        url += `&blockHour=${blockHour}`
      };
      if (orderBy) {
        url += `&orderBy=${orderBy}`;
      }

      const response: any = await api.get(
        `${url}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      setPaginator(response.data)
      return response.data
    } else {
      let responseSettings = settings ? settings : await settingChecked();
      let url = `/trail-class/paginated?before=${before}&after=${after}&root=${responseSettings ? responseSettings.id : settings ? settings.id : ''}&isActive=${isActive ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}&isSuspense=${0}`;
      if (blockHour) {
        url += `&blockHour=${blockHour}`
      }
      const response: any = await api.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      setPaginator(response.data)
      return response.data

    }
  } catch (e: any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.error(e.response)
      e.response.data.message.map((x) => {
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const MeReportAdd = async (data: MeReport): Promise<any> => {
  try {
    const response: any = await api.post(
      '/me-report',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e: any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.error(e.response)
      e.response.data.message.map((x) => {
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const MeReportUpdate = async (data: MeReport): Promise<any> => {
  try {
    const { itemSelected } = UseMeBookingActiveModule.getState()
    const response: any = await api.patch(
      `/me-report/${itemSelected?.id}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e: any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.error(e.response)
      e.response.data.message.map((x) => {
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const MeReportDelete = async (data: string): Promise<any> => {
  try {
    const response: any = await api.delete(`/me-report/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e: any) {

    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      console.error(e.response)
      e.response.data.message.map((x) => {
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UseFindPaginatedTrailClass = async ({ before, after }: BookingFind): Promise<any> => {
  const { settings, settingChecked } = UseMyRoadmapModule.getState()
  const {setNextLessonData, setCurrentLesson} =  useTrailTask.getState()
  try {
    let responseSettings = settings ? settings : await settingChecked()

    const response: any = await api.get(
      `/trail-class/paginated?before=${before}&after=${after}&root=${responseSettings ? responseSettings.id : settings ? settings.id : ''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    if(response && response.data && response.data.nextLessons){
      setNextLessonData(response.data.nextLessons);
      if (response.data.nextLessons && response.data.nextLessons.lessons) {
        const currentLesson = response.data.nextLessons.lessons.find((lesson: NextLesson) => 
          lesson.status === 'IN_REVIEW' && lesson.trailClass.id === 'NOT_TRAIL'
        );
        setCurrentLesson(currentLesson);
      }
    }
    return response.data.result

  } catch (e) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}