import React, {useEffect, useState} from 'react'
import {UseExerciseTimerModule} from '../store/main'

type Props = {
    onNextlesson: (v: any) => any
    exerciseId: string | undefined
    color: string
    typeButton: "back" | "next"
}

const ButtonNextQuestion: React.FC<Props> = ({onNextlesson, exerciseId, color, typeButton }) => {
    const {timeLeft} = UseExerciseTimerModule()
    const currentTimer = timeLeft.find((element) => element.exerciseId === exerciseId)
    const [nextLesson, setnextLesson] = useState<boolean>(true)
    useEffect(() => {
        if (currentTimer) {
            setnextLesson(currentTimer.time > 0)
        }
    }, [timeLeft])
    return (
    <div className=' cursor-pointer' style={{ display: 'flex', alignItems: 'center' }}>
        <button
            type='button'
            className='cursor-pointer border border-0 bg-transparent'
            disabled={nextLesson}
            onClick={() => onNextlesson(true)}
            style={{
                color: color,
                background:'transparent',
                border: 'none',
                display:'flex',
                alignItems:'center',
            }}>
            {typeButton === 'next'?
            <>
            <span style={{ marginRight:'10px', verticalAlign:'middle', fontSize: '23px' }}> next  </span>
            <i className="bi bi-chevron-right" style={{ fontSize:'28px', verticalAlign: 'middle', color: color}} ></i>
            </> :
            <>
            <i className="bi bi-chevron-left mx-0" style={{ fontSize:'27px', verticalAlign: 'middle', color: 'inherit' }} ></i>
            <span style={{ marginLeft:'8px', verticalAlign:'middle', fontSize: '23px' }}> back  </span>
            
            </>
            }
        </button>
    </div>
    )
}
export {ButtonNextQuestion};
