import React, { useEffect } from 'react';
import './index.css';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { Answer, QuestionBasicVocabulary, QuestionAndAnswer } from '../interfaces';
import useSound from 'use-sound';
import { UseEvaluationCourseModule } from '../../../screen/store/main';
import useIsImportantEvaluation from '../../../hooks/useIsImportantEvaluation';
import { ButtonIntroEvaluationItem } from '../../common/button-intro-evaluation-item/buttonIntroEvaluationItem';
import { UseModeCourseModule } from '../../../components/front-page/store/main';

type Props = {
    question: QuestionBasicVocabulary
    handleNext: (x:number) => void
    handleSendAnswer: (x:Answer) => void
    modeQuiz:'RESUME'|'ANSWER'|'THROW_ERROR'|'TITLE'
    falseAnswer:QuestionAndAnswer
    setStatus:(value:string)=>void
    setStep:(value:number)=>void
    totalQuestions:number
    description: string
};

const BasicVocabularyExerciseItem: React.FC<Props> = ({
        setStep,
        question,
        modeQuiz,
        handleSendAnswer,
        totalQuestions,
        description,
}: Props) => {
    const { mode } = useThemeMode();
    const {color} = UseModeCourseModule();
    const {evaluationLesson, questionsEvaluation} = UseEvaluationCourseModule();
    const isImportantQuiz = useIsImportantEvaluation();
    const [selected, setSelected] = React.useState<Answer>();
    const [playbackRate] = React.useState(0.75);
    const [play] = useSound(toAbsoluteUrl(`/media/audio/click.mp3`), {
        playbackRate,
        volume: 0.1,
    });
    const handleClick = () => {
        play();
    };
    const handleSend = () => {
        if(selected){
            handleSendAnswer(selected)
            setSelected(undefined)
        }
    };

    useEffect(() => {
        setSelected(undefined)
    }, [question]);

    return (
        <div className=" card-basic-vocabulary"
            style={
                mode !== 'dark' ? {
                    height: 420,
                    background: 'white',
                } : {
                    height: 420,
                    background: '#1e1e2d',
                    border: `1px solid`,
                    borderColor: '#2d30e1',
                }
            }
        >
            <h3 className='poppins-light subtitle-basic-vocabulary text'
                style={{
                    color: evaluationLesson?.colorPrimary??'#2d30e1',
                }}
            >
                <strong style={{ fontWeight: 'bold' }}>Instrucciones: </strong>{description}
            </h3>

            <h2 className='champ-light title-basic-vocabulary text'
                style={{
                    color: evaluationLesson?.colorPrimary??'#2d30e1',
                }}>
                #{question.order}/{totalQuestions}
            </h2>

            <h2 className='scroll-y overflow-y-auto champ-light title-basic-vocabulary text mb-2'
                style={{
                    color: evaluationLesson?.colorPrimary??'#2d30e1',
                    maxHeight:'80px'
                }}>
                {question.name}
            </h2>

            <div className='scroll-y overflow-y-auto'style={{overflowX: 'auto', maxHeight:'235px',width:'100%', whiteSpace: 'nowrap'  }}>
                    {modeQuiz === 'ANSWER' && question.mode==='WORD' && !selected && question.answers.map((x,i) => (<h2 
                            onClick={()=>{
                                handleClick();
                                setSelected({...x, feedback: question.description, order: question.order})
                            }}
                            className='d-flex  poppins-light-italic option-basic-vocabulary'
                            style={{ color: evaluationLesson?.colorPrimary??'#2d30e1', display: 'inline-block' }}
                            >
                                <i className="bi bi-x icon-option-basic-vocabulary" style={{ color: 'transparent'}}/>
                                <span className='cursor-pointer custom-hover badge rounded-pill px-1 my-1'
                                    style={{fontSize: 18, 
                                        color: evaluationLesson?.colorPrimary??'#2d30e1',
                                        textOverflow: 'ellipsis', }}
                                >
                                    {i+1}. {x.title} 
                                </span>
                        </h2>))}

                    { modeQuiz === 'ANSWER' && question.mode==='IMG' && !selected && question.answers.map((x) => (
                        <div  
                            onClick={()=>{
                                handleClick();
                                setSelected({...x, feedback: question.description, order: question.order})
                            }}
                            className='col d-flex'
                            style={{ color: evaluationLesson?.colorPrimary??'#2d30e1'}}
                        >
                            <img src={x.title} width={100} height={100} className="rounded-circle cursor-pointer" alt="Cinque Terre" />
                        </div>
                        ))}
                    { modeQuiz === 'ANSWER' && question.mode==='IMG' && selected && question.answers.map((x) => (
                        <div  
                            onClick={()=>{
                                handleClick();
                                setSelected({...x, feedback: question.description, order: question.order})
                            }}
                            className='col d-flex'
                            style={{ color: evaluationLesson?.colorPrimary??'#2d30e1'}}
                        >
                            <img src={x.title} width={100} height={100} style={x.isTrue?{ boxShadow: '0px 4px 16px 4px green'}:{ boxShadow: '0px 4px 16px 4px red'}} className="rounded-circle cursor-pointer" alt="Cinque Terre" />
                        </div>
                    ))}
        
                    {selected && question.mode==='WORD'&& question.answers.map((x, i) => (
                            <h2
                                key={i}
                                className='d-flex poppins-light-italic  '
                                style={{ color: evaluationLesson?.colorPrimary??'#2d30e1'}}
                            >
                                {x.isTrue ?
                                    <i className="bi bi-check icon-option-basic-vocabulary " style={{ color: isImportantQuiz? 'transparent' : 'green' , marginTop:'4px'}}/>
                                    :
                                    <i className="bi bi-x icon-option-basic-vocabulary" style={{ color: isImportantQuiz? 'transparent': 'red', marginTop:'4px'}}/>
                                }
                                {selected.title === x.title ? 
                                    <span className="badge rounded-pill " 
                                    style={{background:'#9294e5', color:'white',fontSize:18}}>
                                            {i+1}. {x.title}
                                    </span>: 
                                    <span className={`badge rounded-pill`}
                                        style={{fontSize: 18, 
                                            color: evaluationLesson?.colorPrimary??'#2d30e1'}}
                                    >
                                        {i+1}. {x.title}
                                    </span>
                                }
                            </h2>
                    ))}
            </div>

            <div className='scroll-y overflow-auto'style={{maxHeight:'235px',width:'100%'}}>
                {modeQuiz === 'THROW_ERROR' && <>
                    {question.order > 1 && 
                        <button
                            className='border border-0 bg-transparent cursor-pointer'
                            style={{ position: 'absolute' }}
                            onClick={() => setStep(-1)}
                        >
                            <a href='#' className='poppins-light text-cursor-basic-vocabulary-left' style={{ position: 'absolute' }}> back</a>
                            <img className='' src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/left_arrow.svg`)} alt='' />
                        </button>
                    }
                    <div
                        className='arrow-right-basic-vocabulary cursor-pointer'
                        style={{ position: 'absolute' }}
                        onClick={() => setStep(1)}
                    >
                        <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_active.svg`)} alt='' />
                    </div>
                </>}       
            </div>

            {modeQuiz === 'ANSWER' && selected &&
                <div className='d-flex justify-content-end mt-auto'>
                    <ButtonIntroEvaluationItem onNext={handleSend} color={color} exerciseId={questionsEvaluation?.id}/>
                    {/* <button
                        className='cursor-pointer border border-0 bg-transparent'
                        onClick={() => handleSend()}
                    >
                        <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_active.svg`)} alt='' />
                    </button> */}
                </div>
            }    
        </div>
    );
};

export { BasicVocabularyExerciseItem };