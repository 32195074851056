import React from 'react';
import { CellCrossword, ClueCrossword, ClueResponse, UseModuleExerciseCrossword } from './store/main';
import { AddCrossworodResponse } from './service';
import { ModeTypeExercise } from '../../../book/components/RenderExerciseBook';
import { showSimpleAlert } from '../../../../commonHooks/alert';
import HeaderCard from '../../../../components/abroad/e-book/beyong-the-reading/ui/HeaderCard';
import { UseBookStudentModule } from '../../../book/modules/screen/store/main';
import { UseModeCourseModule } from '../../components/front-page/store/main';
import { ButtonTryAgain } from '../common/button-try-again/buttonTryAgain';
import { ButtonSaveExercise } from '../common/button-save-exercise/buttonSaveExercise';
import { UseBookContainerModule } from '../../../book/store/main';

type Props = {
  userMode?: ModeTypeExercise
};

const CrosswordExercise: React.FC<Props> = ({userMode}) => {
  const {
    instruction, 
    initialClues, 
    initialGrid, 
    hasResponse,
    resetData,
    answer,
    exerciseId,
    setAnswer,
    setHasResponse,
  } = UseModuleExerciseCrossword();

  const {
    lessonData, 
    stepBookItem, 
    currentTaskId,
    setStepBookItem,
    addCompletedExerciseId,
    onChangeTaskByAnswer,
    setIsLoadingSubmit
  } = UseBookStudentModule.getState();

  const {modeViewAnswer} = UseModeCourseModule();
  const [grid, setGrid] = React.useState<CellCrossword[][]>(initialGrid);
  const [clues] = React.useState<ClueCrossword[]>(initialClues);
  const [percentage, setPercentage] = React.useState<number>(0)
  const {showButtons} = UseBookContainerModule()
  
  const handleSubmit = async () => {
    if(userMode === 'STUDENT' && currentTaskId && exerciseId){
      try {
        setIsLoadingSubmit(true)
        const response = await AddCrossworodResponse({
          description: '',
          isActive: true,
          answer: answer,
          percentage: percentage,
          exercises: exerciseId??'',
          task: currentTaskId
        });
        onChangeTaskByAnswer(response, 'crossword')
      } catch (e) {
        showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' });
      } finally {
        setIsLoadingSubmit(false)
      }
    }
    if (lessonData && lessonData.evaluation && 
    lessonData.evaluation.evaluation_item){
      addCompletedExerciseId(exerciseId??"")
      if(lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
        setStepBookItem(exerciseId??'')
      } else {
        setStepBookItem('FINISH_LESSON')
      }
    }
};

  const divStyle = {
    backgroundColor: '#3f8bff',
    maxWidth: '700px',
    minWidth: '650px',
    borderRadius: '30px',
    borderColor: 'rgb(255, 255, 255)',
    borderStyle: 'solid',
    padding: '27px 20px 25px 20px',
  };

  const handleInputChange = (rowIndex: number, colIndex: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedGrid = [...grid];
    updatedGrid[rowIndex][colIndex].value = event.target.value.toUpperCase();
    setGrid(updatedGrid);
  };

  const resetExercise = () => {
    resetData();
    setPercentage(0);
    setGrid(initialGrid);
  };

  const validateGrid = () => {    
    const validationResults: ClueResponse[]= [];
    const percentageStep = 100/clues.length
    let percentageAux = 0
    for (const clue of clues) {
      const word = getWordInGrid(clue.startRow, clue.startCol, clue.direction);
      const correct = word.toLowerCase() === clue.answer.toLowerCase();
      validationResults.push({ 
        clueNumber: clue.clueNumber, 
        correct, 
        value: word,
        direction: clue.direction
      });
      if(correct) percentageAux += percentageStep
    }
  
    const allCorrect = validationResults.every(result => result.correct);
    if (allCorrect) {
      console.log('El crucigrama esta resuelto');
    } else {
      console.log('Algunas pistas no están resueltas correctamente:');
      validationResults.forEach(result => {
        if (!result.correct) {
          console.log(`Pista ${result.clueNumber}: Incorrecta`);
        }
      });
    }
    setPercentage(percentageAux)
    setAnswer(validationResults);
    setHasResponse(true);
    return validationResults;
  };

  const getWordInGrid = (startRow: number, startCol: number, direction: 'across' | 'down') => {
    let word = '';
    let currentRow = startRow;
    let currentCol = startCol;

    while (
      grid[currentRow] &&
      grid[currentRow][currentCol] &&
      !grid[currentRow][currentCol].isBlack
    ) {
      word += grid[currentRow][currentCol].value || '';
      if (direction === 'across') {
        currentCol++;
      } else {
        currentRow++;
      }
    }

    return word;
  };
  
  const updateGridWithClues = (
    initialGrid: CellCrossword[][], 
    initialClues: ClueCrossword[],
  ) => {
    const updatedGrid = JSON.parse(JSON.stringify(initialGrid)) as CellCrossword[][];
      
    initialClues.forEach(clue => {
      const { direction, startRow, startCol, answer: value, clueNumber } = clue;
      let row = startRow;
      let col = startCol;
      
      if (direction === 'down' && startRow > 0) {
        updatedGrid[startRow - 1][startCol].value = clueNumber;
        updatedGrid[startRow - 1][startCol].isBlack = false;
      } else if (direction === 'across' && startCol > 0) {
        updatedGrid[startRow][startCol - 1].value = clueNumber;
        updatedGrid[startRow][startCol - 1].isBlack = false;
      }
      
      for (let i = 0; i < value.length; i++) {
        if (hasResponse) {
          const response = answer.find((x) => x.clueNumber === clueNumber);
          if (response) {
            if (direction === 'across' && col + i < updatedGrid[row].length) {
              updatedGrid[row][col + i].isBlack = false;
              updatedGrid[row][col + i].value = response.value[i]??null;
            } else if (direction === 'down' && row + i < updatedGrid.length) {
              updatedGrid[row + i][col].isBlack = false;
              updatedGrid[row + i][col].value = response.value[i]??null;
            }
          }
        } else {
          if (direction === 'across' && col + i < updatedGrid[row].length) {
            updatedGrid[row][col + i].isBlack = false;
            if (modeViewAnswer) {
              updatedGrid[row][col + i].value = value[i].toUpperCase()??null;
            }
          } else if (direction === 'down' && row + i < updatedGrid.length) {
            updatedGrid[row + i][col].isBlack = false;
            if (modeViewAnswer) {
              updatedGrid[row + i][col].value = value[i].toUpperCase()??null;
            }
          }
        }
      }
    });
    
    setGrid(updatedGrid);
    return updatedGrid;
  };
  
  React.useEffect(() => {
    updateGridWithClues(initialGrid, initialClues);
  }, [initialGrid, initialClues, answer]);
  
  return(
    <div className="container-fluid " style={{ display: 'flex' }}>
      <div style={divStyle}>
        <HeaderCard />
        <div className='scroll-y overflow-y-auto' style={{maxHeight:'100px'}}>
            <span
              className="text-white cuestion mb-0 poppins-light"
              style={{
                marginLeft: '0px',
                fontSize: 13
              }}
            >
              {instruction}
            </span>
        </div>
        <div className='row mt-4 mb-5'>
          <div className='scroll-y overflow-y-auto col-6 d-flex flex-column mb-3' style={{maxHeight:'160px'}}>
            {initialClues.map(x => (
              <li key={x.clueNumber}
                className='poppins-light text-white'
                style={{
                  fontSize: 13
                }}
              >{x.clueText}</li>
            ))}
          </div>
          <div className='col-6 d-flex justify-content-center' style={{ overflowX: 'auto', overflowY: 'auto' }}>
            <div style={{ overflow: 'auto', maxHeight: '215px', maxWidth: '260px' }}>
              <table className=''>
                <tbody>
                  {grid.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, colIndex) => (
                        <td key={colIndex} style={{ borderRadius: '50%', backgroundColor: cell.isBlack ? 'transparent' : 'transparent', color: 'black' }}>
                          {cell.isBlack ? '' : typeof cell.value === 'number' ? 
                            <label 
                              className='poppins-light text-white'
                              style={{ width: 24, height: 24, textAlign: 'center', border: 'none', borderRadius: '50%' }}
                            >{cell.value}</label> : (
                            <input
                              type="text"
                              maxLength={1}
                              style={{ width: 24, height: 24, textAlign: 'center', border: 'none', borderRadius: '50%', backgroundColor: '#d0ff00' }}
                              value={cell.value || ''}
                              onChange={(event) => handleInputChange(rowIndex, colIndex, event)}
                            />
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {showButtons &&
          <div className={`d-flex justify-content-${hasResponse? "between" : "end"}`}>
            {hasResponse &&
              <ButtonTryAgain onAction={resetExercise} />
            }
            <ButtonSaveExercise 
              isChecked={hasResponse}
              onNext={validateGrid}
              onSubmit={handleSubmit}
              exerciseId={exerciseId}
            />
          </div>
        }
      </div>
    </div>
  )
};

export {CrosswordExercise};