import React, { useEffect } from 'react';

import './index.css';
import { useThemeMode } from '../../../../../../_metronic/partials';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { UseEvaluationTestModule } from '../../../../admin/evaluation/modules/test/store/main';
import { UseModeCourseModule } from '../../../components/front-page/store/main';
import { UseEvaluationCourseModule } from '../../../screen/store/main';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { Answer, QuestionAndAnswer } from '../interfaces';
import { useNavigate } from 'react-router-dom';
import { UseMyRoadmapModule } from '../../../../profile/components/my-road-step/store';
import { ResetStepByExercises } from '../../utils';
import { OneTaskByFindOneTask } from '../../basic-vocabulary/services';
import { ButtonIntroEvaluationItem } from '../../common/button-intro-evaluation-item/buttonIntroEvaluationItem';
import { color } from 'framer-motion';
import { ButtonTryAgain } from '../../common/button-try-again/buttonTryAgain';


type Props = {
    total:number
    correct:number
    incorrect:number
    answer: Answer[],
    setStatus:(string)=>void
};

const BasicVocabularyExerciseResume: React.FC<Props> = ({answer, total,correct,incorrect,setStatus }: Props) => {
    const { mode } = useThemeMode();
    const {color} = UseModeCourseModule();
    const navigate = useNavigate();
    const {evaluationTest, stepEvaluationTest, setStepEvaluationTest} = UseEvaluationTestModule();
    const {
        evaluationLesson,
        questionsEvaluation,
        stepEvaluation,
        lessonCourse,
        currentTaskId, 
        lessonsId,
        isLoading,
        setStepEvaluation, 
        setShowEndEvaluation,
    } = UseEvaluationCourseModule();

    const {modeCourse, modeTest} = UseModeCourseModule();
    const [url, setUrl] = React.useState<string>(`/media/svg/michigan-icons/face-resume/blue/100.svg`);
    const [message, setMessage] = React.useState<string>('');
    const [percentageExercise, setPercentageExercise] = React.useState<number>(0);
    const calculatePercentageAndShowLevel = () =>{
        if (total <= 0) {
            throw new Error("The total number of questions must be greater than 0.");
        }
        const percentage = (correct / total) * 100;
        const roundedPercentage = parseFloat(percentage.toFixed(2));
        
        let level: number;
        if (roundedPercentage >= 100) {
            level = 5;
            setMessage( `You did perfect!`);
            setUrl(`/media/svg/michigan-icons/face-resume/blue/100.svg`)
        } else if (roundedPercentage >= 80) {
            setUrl(`/media/svg/michigan-icons/face-resume/blue/80.svg`)
            setMessage( `You did great!`);
            level = 4;
        } else if (roundedPercentage >= 50) {
            setUrl(`/media/svg/michigan-icons/face-resume/blue/50.svg`)
            setMessage( `You can do better!`);
            level = 3;
        } else if (roundedPercentage >= 40) {
            setUrl(`/media/svg/michigan-icons/face-resume/blue/40.svg`)
            setMessage( `You did okay, c´mon!`);
            level = 2;
        } else if (roundedPercentage >= 20) {
            setMessage( `You did okay!`);
            setUrl(`/media/svg/michigan-icons/face-resume/blue/20.svg`)
            level = 1;
        } else {
            setUrl(`/media/svg/michigan-icons/face-resume/blue/20.svg`)
            setMessage( `you can do better`);
            level = 0;
        }
        return roundedPercentage;
    }

    useEffect(() => {
        setPercentageExercise(calculatePercentageAndShowLevel());
    }, []);

    return (
        <div className=" card-true-false"
            style={
                mode !== 'dark' ? {
                    height: 420,
                    background: 'white',
                    position: 'relative'
                } : {
                    height: 420,
                    background: '#1e1e2d',
                    border: `1px solid`,
                    borderColor: '#2d30e1',
                    position: 'relative'
                }
            }>

            <img className='image-true-false-resume' src={toAbsoluteUrl(url)} alt='' />
            <h2 className='champ-light title-true-false-resume text mb-5D'
                style={{
                    color: '#2d30e1',
                }}>
                {message}
            </h2>
            <h2 className='champ-light title-true-false-resume-number text'
                style={{
                    color: '#2d30e1',
                }}>
                {correct}/{total}
            </h2>
            <ButtonTryAgain onAction={()=> {setStatus('ANSWER')}}/>
            {/* <button 
                type='button'
                disabled={isLoading}
                className='  arrow-left-true-false cursor-pointer'
                style={{ position: 'absolute', border: 'none', backgroundColor: 'transparent' }}
                onClick={()=>setStatus('ANSWER')}
            >
                <img className='' height={30} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/try_again.svg`)} alt='' />
            </button> */}
            <div
                // className='arrow-right-next-sessions cursor-pointer'
                // style={{ position: 'absolute' }}
                // onClick={async(e) => {
                //     e.preventDefault()
                //     if (modeTest && evaluationTest && evaluationTest.evaluation_item) {
                //         if (evaluationTest?.evaluation_item?.length > stepEvaluationTest + 1) {
                //             setStepEvaluationTest(stepEvaluationTest + 1)
                //         } else {
                //             alert('acabo')
                //         }
                //     }
                //     if (modeCourse && evaluationLesson && lessonCourse
                //         && evaluationLesson.evaluation_item) {
                        
                //         if (evaluationLesson?.evaluation_item?.length > stepEvaluation + 1) {
                //             setStepEvaluation(stepEvaluation + 1)
                //         } else if (lessonCourse) {
                //             await OneTaskByFindOneTask(currentTaskId??'xx');
                //             setShowEndEvaluation(true);
                //             const nextLessonId = lessonsId?.find(x => lessonCourse.order < x.order)?.id;
                //             if (nextLessonId) {
                //                 navigate(`/courses/container/${nextLessonId}`);
                //             } else {
                //                 navigate('/dashboard');
                //             } 
                //         } else {
                //             setShowEndEvaluation(true);
                //         }
                //     }
                // }}
            >
                <ButtonIntroEvaluationItem onNext={setStatus} color={color} exerciseId={questionsEvaluation?.id}/>
                {/* <img height={30} src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/next_session.svg`)} alt='' /> */}
            </div>
        </div>
    );
};

export { BasicVocabularyExerciseResume };