// i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { AvatarTranslateEn } from '../modules/admin/avatar/i18n/en';
import { AvatarTranslateEs } from '../modules/admin/avatar/i18n/es';
import { LearningPathTranslateEn } from '../modules/abroad/learning-path/i18n/en';
import { ChapterTranslateEn } from '../modules/abroad/learning-path/modules/chapter/i18n/en';
import { LearningPathTranslateEs } from '../modules/abroad/learning-path/i18n/es';
import { ChapterTranslateEs } from '../modules/abroad/learning-path/modules/chapter/i18n/es';
import { LessonTranslateEs } from '../modules/abroad/learning-path/modules/lessons/i18n/es';
import { LessonTranslateEn } from '../modules/abroad/learning-path/modules/lessons/i18n/en';
import { TopicsTranslateEn } from '../modules/abroad/learning-path/modules/topics/i18n/en';
import { TopicsTranslateEs } from '../modules/abroad/learning-path/modules/topics/i18n/es';
import { DashboardTranslateEs } from '../modules/dashboard/i18n/es';
import { DashboardTranslateEn } from '../modules/dashboard/i18n/en';
import { SidebarTranslateEn } from '../components/common/sidebar/i18n/en';
import { SidebarTranslateEs } from '../components/common/sidebar/i18n/es';
import { FaqTranslateEn } from '../modules/abroad/faq/i18n/en';
import { FaqTranslateEs } from '../modules/abroad/faq/i18n/es';
import { StrikesTranslateEn } from '../modules/me-report/modules/strikes/i18n/en';
import { StrikesTranslateEs } from '../modules/me-report/modules/strikes/i18n/es';
import { NotificationsTranslateEn } from '../modules/notifications/i18n/en';
import { NotificationsTranslateEs } from '../modules/notifications/i18n/es';
import { MyCoursesTranslateEn } from '../modules/student/my-courses/i18n/en';
import { MyCoursesTranslateEs } from '../modules/student/my-courses/i18n/es';
import { MyPaymentsEn } from '../modules/student/my-payments/i18n/en';
import { MyPaymentsEs } from '../modules/student/my-payments/i18n/es';
import { TrueOrFalseEn } from '../modules/admin/exercises/templates-exercise/true-or-false/i18n/en';
import { TrueOrFalseEs } from '../modules/admin/exercises/templates-exercise/true-or-false/i18n/es';
import { SettingsEs } from '../modules/abroad/settings/i18n/es';
import { SettingsEn } from '../modules/abroad/settings/i18n/en';
import { ExercisesEn } from '../modules/admin/exercises/i18n/en';
import { ExercisesEs } from '../modules/admin/exercises/i18n/es';
import { MyTestsEn } from '../modules/student/my-tests/i18n/en';
import { MyTestsEs } from '../modules/student/my-tests/i18n/es';
import { TasksEs } from '../modules/admin/tasks/i18n/es';
import { TasksEn } from '../modules/admin/tasks/i18n/en';

const resources = {
  en: {
    translation: {
      ...AvatarTranslateEn,
      ...LearningPathTranslateEn,
      ...ChapterTranslateEn,
      ...LessonTranslateEn,
      ...TopicsTranslateEn,
      AVATAR:'Avatar',
      LEVEL:'Level',
      HOURS:'Hours',
      TARGET:'Target',
      CLASS:'Class',
      URL_BUILDER:'Url Builder',
      PROFILE:'Profile',
      MY_ROAD_MAP:'Road Map',
      UPGRADE_PROFILE:'Upgrade',
      LOGOUT:'Logout',
      MESSAGE_FULL_BOOKING:'You have reached the limit of classes allowed per week.',
      ...AvatarTranslateEn,
      ...DashboardTranslateEn,
      ...SidebarTranslateEn,
      ...FaqTranslateEn,
      ...StrikesTranslateEn,
      ...NotificationsTranslateEn,
      ...MyCoursesTranslateEn,
      ...MyPaymentsEn,
      ...TrueOrFalseEn,
      ...SettingsEn,
      ...ExercisesEn,
      ...MyTestsEn,
      ...TasksEn,
      WELCOME_DAY:'Welcome Day',
      PLACEMENT_TEST:'Placement Test',
      PENDING : 'Pendiente',
      IN_REVIEW : 'En revisión',
      APPROVED : 'Aprobado',
      CANCELED : 'Cancelado',
      REPROVED : 'Reprobado',
      EMPTY_CLASS:'Clase cerrada y vacía',
      TO_BE_CLOSED:'Esperando por cierre de prof.',
      IN_PROCESS : 'En proceso',
      FINISHED : 'Finalizado',
      NO_APPLY : 'No aplíca',
      LESSON_ADJUSTMENT : 'Solicitud de nueva lección'
    },
  },

 
  es: {
    translation: {
      ...AvatarTranslateEs,
      ...LearningPathTranslateEs,
      ...ChapterTranslateEs,
      ...LessonTranslateEs,
      ...TopicsTranslateEs,
      ...SettingsEs,
      PENDING : 'Pendiente',
      IN_REVIEW : 'En revisión',
      APPROVED : 'Aprobado',
      CANCELED : 'Cancelado',
      REPROVED : 'Reprobado',
      IN_PROCESS : 'En proceso',
      FINISHED : 'Finalizado',
      NO_APPLY : 'No aplíca',
      EMPTY_CLASS:'Clase cerrada y vacía',
      TO_BE_CLOSED:'Esperando por cierre de prof.',
     ...AvatarTranslateEs,
     ...LearningPathTranslateEs,
     ...ChapterTranslateEs,
     ...LessonTranslateEs,
     ...TopicsTranslateEs,
     ...SettingsEs,
      AVATAR:'Avatar',
      LEVEL:'Nivel',
      HOURS:'Horas',
      TARGET:'Target',
      CLASS:'Clases',
      URL_BUILDER:'Url Builder',
      PROFILE:'Perfíl',
      MY_ROAD_MAP:'Ruta De Aprendizaje',
      UPGRADE_PROFILE:'Acenso',
      LOGOUT:'Salir',
      MESSAGE_FULL_BOOKING: 'Has alcanzado el límite de clases permitidas por semana.',
      ... AvatarTranslateEs,
      ...DashboardTranslateEs,
      ...SidebarTranslateEs,
      ...FaqTranslateEs,
      ...StrikesTranslateEs,
      ...NotificationsTranslateEs,
      ...MyCoursesTranslateEs,
      ...MyPaymentsEs,
      ...TrueOrFalseEs,
      ...ExercisesEs,
      ...MyTestsEs,
      ...TasksEs,
      LESSON_ADJUSTMENT : 'Solicitud de nueva lección'
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // idioma inicial
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // no necesario para React
  },
});

export default i18n;