import { calculatePercentages, Statistics } from "./utils";
import TableHourResume, { DayHourData } from "./table-hour-resume";

type Props = {
    statistics: Statistics
    length: number
    data: DayHourData[];
}
const WidgetTrailClassResume = ({ statistics, length, data }: Props) => {

    return (
        <>
            {statistics && <div
                style={{
                    overflowY: 'scroll',
                    width: '100%',
                    float: 'left',
                    height: '500px',
                    position: 'relative'
                }}
                className='card p-5'>
                <h2 className="champs-light">Estadísticas</h2>
                <h5>Total de estudiantes únicos: <strong>{statistics.totalStudents}</strong> </h5>
                <TableHourResume data={data} />
                
                <div className="row">
                    <div className="col-3">
                        <h5>Tipos de salones:</h5>
                        <ul>
                            {Object.entries(statistics.classroomTypeCount).map(([x, count]) => (
                                <li className="my-3" key={x}>{x}:<strong> {count}</strong></li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-3">
                        <h5>Cancelaciones:</h5>
                        <p className="pt-0 mt-0">SI: <strong> {statistics.isSuspenseCount.true} </strong> ({calculatePercentages(length, statistics.isSuspenseCount.true).toFixed(2)}%)</p>
                        <p className="pt-0 mt-0">No: <strong> {statistics.isSuspenseCount.false}</strong>  ({calculatePercentages(length, statistics.isSuspenseCount.false).toFixed(2)}%)</p>
                    </div>
                    <div className="col-3">
                        <h5>Asistencia del profesor:</h5>
                        <p>SI: {statistics.assistantStudentCount.true} ({calculatePercentages(length, statistics.assistantStudentCount.true).toFixed(2)}%)</p>
                        <p>No: {statistics.assistantStudentCount.false} ({calculatePercentages(length, statistics.assistantStudentCount.false).toFixed(2)}%)</p>
                    </div>
                    <div className="col-3">
                        <h5>Citas por campus:</h5>
                        <ul>
                            {Object.entries(statistics.campusCount).map(([campus, count]) => (
                                <li className="my-3" key={campus}>{campus}: <strong> {count}</strong></li>
                            ))}
                        </ul>
                    </div>
                    <hr></hr>
                    <div className="col-6">
                        <h5>Citas por level de los estudiantes:</h5>
                        <ul>
                            {Object.entries(statistics.levelCount).map(([level, count]) => (
                                <li key={level}>{level}: <strong> {count}</strong></li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-3">
                        <h5>Citas por dias:</h5>
                        <ul>
                            {Object.entries(statistics.dayOfWeekCount).map(([level, count]) => (
                                <li key={level}>{level}: <strong> {count}</strong></li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>}
        </>
    )
}

export { WidgetTrailClassResume }

