import React, { useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, useFormik } from 'formik'
import { TrueOrFalse, TrueOrFalseEnum, TrueOrFalseOption } from '../../interfaces'

import { TrueOrFalseAdd, TrueOrFalseUpdate } from '../../services'
import { UseTrueOrFalseModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { TraditionalLevelEnum } from '../../../../../../abroad/road-map-step/interfaces'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BooleanCell } from '../../../../../../../components/common/BooleanCell'
import { showSimpleAlert } from '../../../../../../../commonHooks/alert'

export type FormFields = {
  name: string
  isActive: boolean
  description?: string
  level: TraditionalLevelEnum;
  answer: TrueOrFalseOption[];
}

const entitySchema = Yup.object().shape({
  // name: Yup.string().required('First name is required'),
  // type_category: Yup.string(),
})

type Props = {
  mode: 'ADD' | 'EDIT' | 'VIEW'
}

 export interface AlertInterface {
  title: string;
  message: string;
  type: 'success' | 'error' | 'info';
  icon: 'error' | 'info' | 'warning' | 'success';
}


const TrueOrFalseForm: React.FC<Props> = (props: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient()
  const { itemSelected, mode, resetData } = UseTrueOrFalseModule();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [dataAnswer, setDataAnswer] = React.useState<TrueOrFalseOption>({
    title: '',
    order: 1,
    answer: TrueOrFalseEnum.TRUE
  });

  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } =
    useFormik<FormFields>({
      initialValues: {
        name: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.name ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.isActive ?? true : true,
        description: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.description ?? '' : '',
        level: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.level ?? TraditionalLevelEnum.PRE_A1_1 : TraditionalLevelEnum.PRE_A1_1,
        answer: (mode === 'EDIT' || mode === 'VIEW') ? itemSelected?.answer.map(x => JSON.parse(x)) ?? [] : [],
      },
      validationSchema: entitySchema,

      onSubmit: async (formData) => {
        if (props.mode === 'ADD') {
          try {
            setLoading(true);
            await TrueOrFalseAdd({
              ...formData,
              exercises: id ?? 'xx'
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['true-or-false'] })

          } catch (e) {
            setLoading(false);
          }

        }
        else {
          try {
            setLoading(true);
            await TrueOrFalseUpdate({
              ...formData,
              exercises: id ?? 'xx'
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['true-or-false'] })

          } catch (e) {
            setLoading(false);
          }
        }
      },
    });

  const addAnswer = () => {
    const isOrderRepeated = values.answer.some((x) => x.order === dataAnswer.order);
    if (isOrderRepeated) {
      const alertData: AlertInterface = {
        title: 'Error',
        message: 'El orden no se puede repetir',
        type: 'error',
        icon: 'warning',
      }
      showSimpleAlert(alertData);

      return;
    }

    setFieldValue('answer', [...values.answer, dataAnswer]);
    setDataAnswer({
      title: '',
      order: 1,
      answer: TrueOrFalseEnum.TRUE
    });
  };

  const deleteAnswer = (order: number) => {
    setFieldValue('answer', values.answer.filter(x => x.order !== order));
  }

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive ? 1 : 0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          {/* <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div> */}

          <div className='mb-10 col-6'>
            <label className='form-label mb-3 fw-bold'>Questions</label>
            <div className='mb-2 col-12'>
              <label className='form-label mb-2'>Sentence</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                value={dataAnswer.title}
                onChange={(e) => setDataAnswer(prev => ({ ...prev, title: e.target.value }))}
              />
            </div>
            <div className='mb-2 col-12'>
              <label className='mb-2 form-label'>Order</label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                value={dataAnswer.order}
                onChange={(e) => setDataAnswer(prev => ({ ...prev, order: parseInt(e.target.value) }))}
              />
            </div>
            <div className='mb-2 col-12'>
              <label className='mb-2 form-label'>Value</label>
              <select
                className='form-select'
                aria-label='Default select example'
                value={dataAnswer.answer}
                disabled={mode === 'VIEW'}
                onChange={(e) => setDataAnswer(prev => ({ ...prev, answer: e.target.value as TrueOrFalseEnum }))}
              >
                {Object.keys(TrueOrFalseEnum).map((x) => (
                  <option key={x} value={x}>{t(x)}</option>
                ))}
              </select>
            </div>

            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='button' onClick={addAnswer} className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
                {!loading && 'Save'}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
          <div className='col-6'>
            <table className='table table-sm table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th>Sentences</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {values.answer.sort((a, b) => a.order - b.order).map((x) => (
                  <tr>
                    <td className=''>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                            {x.title}
                          </a>
                          <span className='text-muted fw-semibold d-block fs-7'>
                            {t(x.answer)}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <button
                          disabled={loading || mode === 'VIEW'}
                          type='button'
                          onClick={() => deleteAnswer(x.order)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <i className="fas fa-trash-alt" style={{ color: 'red', fontSize: 15 }}></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading || mode === 'VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export { TrueOrFalseForm }
