import { create } from 'zustand';
import { UserSettings } from './interfaces';
import { persist } from 'zustand/middleware';
import { UseGetMyRoadMap } from './services';
import Swal from 'sweetalert2';
import { LevelUpgradeUseCase } from '../../../admin/appointment/services';
import { TraditionalLevelEnum } from '../../../abroad/road-map-step/interfaces';


interface MyRoadmapModule {
    isLoading: boolean
    settings?: UserSettings;
    courtesy?: any//tipar cuando mezcle con Leonardo
    setSettings: (mode: UserSettings) => void;
    settingChecked: () => Promise<UserSettings>;
    resetSetting: () => void;
    setIsLoading: (value: boolean) => void;
    setCourtesy: (value: any) => void;
}




export const UseMyRoadmapModule = create(persist<MyRoadmapModule>(
    (set) => ({
        isLoading: false,
        settings: undefined,
        setIsLoading: (value: boolean) => {
            set({ isLoading: value })
        },
        setSettings: (settings: UserSettings) => {
            set({ settings: settings })
        },
        setCourtesy: (value) => {
            set({ courtesy: value });
        },
        settingChecked: async () => {
            try {
                set({ isLoading: true })
                let response = await UseGetMyRoadMap()
                if (response.typeStep === 'RANDOM' && response.finishedStep) {
                    const currentStep = response.roadMap.step.find(x => x.isCurrentStep);
                    const indexLevel = Object.keys(TraditionalLevelEnum).findIndex((x) => x === response.level);
                    const orderLevel = currentStep ? currentStep.order : indexLevel;
                    const nextStep = response.roadMap.step.find(x => x.order > orderLevel);

                    const firstModalPromotionLevel = () => {
                        Swal.fire({
                            title: '¡Felicitaciones!',
                            html: `
                            <p class='poppins-light'>
                                Has completado las horas de ${currentStep?.name ?? response.level},
                                puedes avanzar al siguiente nivel o puedes quedarte por más tiempo en ${currentStep?.name ?? response.level}.
                            </p>`,
                            icon: 'success',
                            iconColor: '#2d30e1',
                            showCancelButton: true,
                            allowOutsideClick: false,
                            confirmButtonColor: '#ffcc00',
                            reverseButtons: true,
                            width: 450,
                            confirmButtonText: "<span class='poppins-light fs-4'>Avanzar de nivel</span>",
                            cancelButtonText: "<span class='poppins-light fs-4'>Seguir en el nivel actual</span>",
                            customClass: {
                                title: 'champ-light',
                                confirmButton: 'confirm-button-warning-sweet-alert btn btn-warning',
                                cancelButton: 'cancel-button-sweet-alert btn btn-success',
                            }
                        }).then(async (result) => {
                            confirmAction(result.isConfirmed);
                        });
                    };

                    const confirmAction = (responsePromotion: boolean) => {
                        Swal.fire({
                            title: '¿Estas seguro?',
                            html: `
                            <p class='poppins-light'>
                                Dando clic en aceptar
                                ${responsePromotion ? 
                                    ` avanzaras al ${nextStep ? `nivel ${nextStep.name}` : 'siguiente nivel.' }` : 
                                    ` te quedarás una semana más es este nivel.`
                                }
                            </p>`,
                            icon: 'info',
                            iconColor: '#2d30e1',
                            showCancelButton: true,
                            allowOutsideClick: false,
                            reverseButtons: true,
                            confirmButtonText: "<span class='poppins-light fs-4'>Sí!</span>",
                            cancelButtonText: "<span class='poppins-light fs-4'>No</span>",
                            customClass: {
                                title: 'champ-light',
                                confirmButton: 'confirm-button-sweet-alert',
                                cancelButton: 'cancel-button-sweet-alert',
                            }
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                if (responsePromotion) {
                                    await LevelUpgradeUseCase({
                                        student: response.id ?? '',
                                    });
                                    return;
                                }
                                return;
                                // window.location.reload();
                            } else {

                                firstModalPromotionLevel();
                                return;
                            }
                        });
                    };

                    firstModalPromotionLevel();
                };

                set({
                    settings: response
                })
                return response;
            } catch {
                set({
                    settings: undefined
                })
            } finally {
                set({ isLoading: false })
            }
        },
        resetSetting: () => {
            set({ settings: undefined })
        },
    }),
    {
        name: "setting-student",

    }
));